import { useEffect } from "react";

export function useAfterMidnightRefresh() {
  useEffect(() => {
    const timeoutId = setTimeout(
      () => window.location.reload(),
      millisecTillAfterMidnight()
    );
    return () => clearTimeout(timeoutId);
  }, []);
}

function millisecTillAfterMidnight() {
  return new Date().setHours(24, 30, 0, 0) - Date.now();
}
