import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { StationId } from "@/domain/station";
import {
  CycleCountByStation,
  LineOutputPerStationStatistics,
} from "@/domain/statistics";
import { ChartErrorStatus } from "@/view/components";
import { useSelectedStationIds } from "@/view/pages/line-id/use-selected-station-ids";
import { useApiClient } from "@/view/providers/api-client-provider";

import { OutputByStationChart } from "./charts/output-by-station-chart";
import { LineReportingChartsLoading } from "./line-statistics";
import { useLineStatisticsParams } from "./line-statistics-provider";

function toOutputByStationChartData(stationIds: Array<StationId>) {
  return ({ data }: LineOutputPerStationStatistics) => {
    const byStationId = data.reduce<Record<StationId, CycleCountByStation>>(
      (acc, curr) => {
        acc[curr.stationId] = curr;
        return acc;
      },
      {}
    );

    const result = [];
    for (const stationId of stationIds) {
      const dataByStation = byStationId[stationId];
      if (dataByStation) {
        result.push(dataByStation);
      }
    }
    return result;
  };
}

export function LineOutputByStationChart({
  height,
  onStationSelect,
}: {
  height: number;
  onStationSelect: (stationId: StationId) => void;
}) {
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange, productIds] =
    useLineStatisticsParams();
  const stationIds = useSelectedStationIds();

  const toChartData = useMemo(
    () => toOutputByStationChartData(stationIds),
    [stationIds]
  );

  const query = useQuery({
    queryKey: [
      "line-output-by-station-statistics",
      line.id,
      shiftIds,
      tagIds,
      dateRange,
      productIds,
    ],
    queryFn: () =>
      apiClient.getLineOutputPerStationStatistics({
        lineId: line.id,
        shiftIds,
        tagIds,
        dateRange,
        productIds,
      }),

    select: toChartData,
  });

  if (query.isLoading) {
    return <LineReportingChartsLoading />;
  }

  if (query.isSuccess) {
    return (
      <OutputByStationChart
        height={height}
        data={query.data}
        onBarClick={onStationSelect}
      />
    );
  }

  return <ChartErrorStatus size="medium" className="min-h-[540px]" />;
}
