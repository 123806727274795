import { useLingui } from "@lingui/react";

import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/view/components/tooltip";
import { paths } from "@/view/routes";

import { useSelectedLine } from "./use-selected-line";

export function TrainingModeBadge() {
  const { i18n } = useLingui();
  const line = useSelectedLine();

  const reportingPath = paths.lineReportingPath.split("/").at(-1) ?? "";
  const isReportingPage = location.pathname.endsWith(reportingPath);
  const showTrainingModeBadge = line.inTraining && isReportingPage;

  if (!showTrainingModeBadge) return null;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className="flex items-center gap-1.5 mx-2 bg-brand-gray-2 px-3 py-1.5 rounded-md">
          <span className="h-2 w-2 rounded-full bg-brand-blue-1" />
          <span className="text-brand-black text-sm">
            {i18n.t("trainingMode")}
          </span>
        </TooltipTrigger>
        <TooltipContent sideOffset={12} className="max-w-xs px-4 py-3">
          <TooltipArrow />
          <h3 className="font-semibold mb-1 text-base">
            {i18n.t("trainingModeTooltipTitle")}
          </h3>
          <p className="text-sm">{i18n.t("trainingModeTooltipDescription")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
