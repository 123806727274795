import { useLingui } from "@lingui/react";

import { cn } from "@/view/utils";

import { useTags } from "../../line-id/use-tags";
import { ChartType } from "./chart-type-select";
import { DataType, getColor, getLegendName } from "./helpers";

export function ChartLegend({
  dataType,
  chartType,
  legends,
  hiddenLegends,
  onLegendClick,
}: {
  dataType: DataType;
  chartType: ChartType;
  legends: Array<string>;
  hiddenLegends: Array<string>;
  onLegendClick: (legend: string) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  return (
    <ul className="flex gap-2">
      {legends.map((legend) => (
        <li key={legend}>
          <button
            type="button"
            className={cn(
              "flex gap-2 items-center px-2 py-1",
              "border border-brand-gray-2 rounded-md",
              "text-sm text-brand-black bg-brand-gray-1",
              {
                "text-brand-gray-3 bg-brand-white":
                  hiddenLegends.includes(legend),
              }
            )}
            onClick={() => onLegendClick(legend)}
          >
            <div
              className="w-2 h-2 rounded-full"
              style={{
                backgroundColor: getColor(
                  legend,
                  chartType,
                  dataType,
                  tagsStore
                ),
              }}
            />
            {getLegendName(legend, chartType, dataType, tagsStore, i18n)}
          </button>
        </li>
      ))}
    </ul>
  );
}
