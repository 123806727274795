import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";

import { BaseError } from "@/domain/common/errors";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineWithStations } from "@/domain/levels";
import { getSelectedShifts, ShiftId } from "@/domain/shifts";
import { StationId } from "@/domain/station";
import { StationStatistics } from "@/domain/statistics";
import { useApiClient } from "@/view/providers/api-client-provider";

export function useStationStatisticsQuery<TData = StationStatistics>(
  {
    line,
    stationId,
    shiftIds,
    dateRange,
  }: {
    line: LineWithStations;
    stationId: StationId;
    shiftIds: Array<ShiftId>;
    dateRange: DateRangeFilter;
  },
  options?: Omit<
    UseQueryOptions<StationStatistics, BaseError, TData>,
    "queryKey" | "queryFn"
  >
) {
  const apiClient = useApiClient();
  return useQuery<StationStatistics, BaseError, TData>({
    queryKey: ["station-statistics", stationId, dateRange, shiftIds],
    queryFn: ({ signal }) => {
      const shifts = getSelectedShifts(line.shifts, shiftIds);
      return apiClient.getStationStatistics(
        { stationId, dateRange, shifts },
        { signal }
      );
    },
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
    placeholderData: keepPreviousData,
    ...options,
  });
}
