import { Amplify } from "aws-amplify";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import { defaultStorage, sessionStorage } from "aws-amplify/utils";

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: import.meta.env.VITE_COGNITO_IDENTITYPOOL_ID,
      userPoolId: import.meta.env.VITE_COGNITO_USERPOOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_WEBCLIENT_ID,
    },
  },
});

// Use sessionStorage in production and localStorage in development
if (import.meta.env.PROD) {
  cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);
} else {
  cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);
}
