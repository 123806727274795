import { useSuspenseQuery } from "@tanstack/react-query";

import { LineId } from "@/domain/line";
import { useApiClient } from "@/view/providers/api-client-provider";

export function getVirtualSensorImageByLineQueryKey(lineId: LineId) {
  return ["virtual-sensor-image", lineId];
}

export function useVirtualSensorImageQuery(lineId: LineId) {
  const apiClient = useApiClient();
  return useSuspenseQuery({
    queryKey: getVirtualSensorImageByLineQueryKey(lineId),
    queryFn: () => apiClient.getVirtualSensorImageByLineId({ lineId }),
  });
}
