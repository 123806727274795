import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";

import {
  ApiError,
  BadRequestError,
  BaseError,
  NetworkError,
  ResponseParsingError,
  UnauthorizedError,
} from "@/domain/common/errors";

export function ErrorMessage({ error }: { error: BaseError }) {
  const { i18n } = useLingui();
  const { title, message } = getErrorMessages(error, i18n);

  return (
    <div className="mb-2 p-2 rounded-md text-sm bg-brand-warninig-shade text-brand-warning">
      <h4 className="font-bold">{title}</h4>
      <p>{message}</p>
    </div>
  );
}

function getErrorMessages(error: BaseError, i18n: I18n) {
  switch (true) {
    case error instanceof UnauthorizedError:
    case error instanceof NetworkError:
      return {
        title: i18n.t("An error occurred when communicating with the server."),
        message: [
          i18n.t(
            "Please check your internet connection and make sure you're allowed to perform this action."
          ),
          i18n.t(
            "In case the problem persists, please contact our support at support@deltia.ai"
          ),
        ].join(" "),
      };
    case error instanceof ApiError:
      return {
        title: i18n.t("Server responded with an error."),
        message: error.message,
      };
    case error instanceof BadRequestError:
      return {
        title: i18n.t("errorBadRequestTitle"),
        message: i18n.t("errorBadRequestMessage"),
      };
    case error instanceof ResponseParsingError:
      return {
        title: i18n.t("errorParsingResponseTitle"),
        message: i18n.t("errorParsingResponseMessage"),
      };
    default:
      return {
        title: i18n.t("errorUnknownTitle"),
        message: i18n.t("Please try again later."),
      };
  }
}
