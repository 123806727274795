import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";

import { LineAccumulatedByTimeStatistics } from "@/domain/statistics";
import { ChartErrorStatus } from "@/view/components";
import { OutputByTimeChart } from "@/view/components/charts";
import { useApiClient } from "@/view/providers/api-client-provider";
import { formatDateForTimeAxis } from "@/view/utils";

import { LineReportingChartsLoading } from "./line-statistics";
import { useLineStatisticsParams } from "./line-statistics-provider";

function toOutputByTimeChartData({
  data,
  timeGranularity,
  workingHours,
}: LineAccumulatedByTimeStatistics) {
  return {
    timeGranularity,
    data: data.map((it) => ({
      label: it.datetime,
      value: {
        target: it.aggregationTarget,
        value: it.aggregationCount,
      },
    })),
    workingHours,
  };
}

export function LineOutputByTimeChart({
  width,
  height,
}: {
  width: number;
  height: number;
}) {
  const { i18n } = useLingui();
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange] = useLineStatisticsParams();
  const query = useQuery({
    queryKey: [
      "line-accumulated-by-time-statistics",
      line.id,
      shiftIds,
      tagIds,
      dateRange,
    ],
    queryFn: () =>
      apiClient.getLineStatisticsAccumulatedByTime({
        lineId: line.id,
        shiftIds,
        tagIds,
        dateRange,
      }),
    select: toOutputByTimeChartData,
  });

  if (query.isLoading) {
    return <LineReportingChartsLoading />;
  }

  if (query.data && query.data.data.length > 0) {
    return (
      <OutputByTimeChart
        xAxisTitle=""
        yAxisTitle={i18n.t("lineOverviewChartTabOutputByTime")}
        dimensions={{ width, height }}
        data={query.data.data}
        workingHours={query.data.workingHours}
        xLabelFormatter={formatDateForTimeAxis(query.data.timeGranularity)}
        granularity={query.data.timeGranularity}
      />
    );
  }

  return <ChartErrorStatus size="medium" className="min-h-[540px]" />;
}
