import { contourDensity } from "d3-contour";
import { geoPath } from "d3-geo";
import { interpolateTurbo } from "d3-scale-chromatic";
import { memo, useMemo } from "react";

import type { CameraId, Heatmap } from "@/domain/areas-of-interests";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";

import { useHeatmapDataQuery } from "./use-heatmap-data-query";

const getXValue = (d: Heatmap[number]) => d[0];
const getYValue = (d: Heatmap[number]) => d[1];
const getIntensityValue = (d: Heatmap[number]) => d[2];

export const WalkingRoutesHeatmap = memo(function WalkingRoutesHeatmap({
  height,
  width,
  opacity,
  filters,
}: {
  height: number;
  width: number;
  opacity: number;
  filters: {
    dateRange: DateRangeFilter;
    selectedShifts: ShiftId[];
    cameraId: CameraId;
  };
}) {
  const { data } = useHeatmapDataQuery(filters);

  const heatmapData = useMemo(() => {
    if (!data) return null;

    const densityData = contourDensity<Heatmap[number]>()
      .x(getXValue)
      .y(getYValue)
      .weight(getIntensityValue)
      .size([width, height])
      .bandwidth(10)
      .cellSize(20)
      .thresholds(80)(data);

    return densityData.map((d) => ({
      path: geoPath()(d)!,
      color: interpolateTurbo(d.value),
    }));
  }, [data, width, height]);

  if (!heatmapData) return null;

  return (
    <g>
      {heatmapData.map((d, i) => (
        <path key={i} d={d.path} fill={d.color} fillOpacity={opacity} />
      ))}
    </g>
  );
});
