import { SettingsIcon } from "lucide-react";
import { PropsWithChildren } from "react";
import { generatePath, Link, useLocation } from "react-router-dom";

import { Container, DeltiaIcon } from "@/view/components";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

import { LlmChat } from "./llm-chat";
import { MainNav } from "./main-nav";
import { NotificationsDrawer } from "./notifications";
import { ProfileNav } from "./profile-nav";
import { TrainingModeBadge } from "./training-mode-badge";
import { useSelectedLine } from "./use-selected-line";

export function RootLayout({
  hasLoaded = false,
  children,
}: PropsWithChildren<{
  hasLoaded?: boolean;
}>) {
  return (
    <RootLayoutWrapper>
      <header className="py-4 sticky z-50 top-0 shadow-sm bg-white">
        <Container className="flex justify-between items-center">
          <div className="flex gap-12 items-center">
            <div className="flex items-center gap-2">
              <Logo />
              {hasLoaded && <TrainingModeBadge />}
            </div>
            <MainNav />
          </div>
          <div className="flex items-center gap-2">
            {hasLoaded && <SettingsButton />}
            {hasLoaded && <NotificationsDrawer />}
            {hasLoaded && <LlmChat />}
            <ProfileNav />
          </div>
        </Container>
      </header>
      <main className="flex-grow">{children}</main>
    </RootLayoutWrapper>
  );
}

function RootLayoutWrapper({ children }: PropsWithChildren) {
  const location = useLocation();
  const trendsPath = paths.lineTrendsPath.split("/").at(-1) ?? "";
  const isTrendsPage = location.pathname.endsWith(trendsPath);

  return (
    <div
      className={cn(
        "min-w-[720px]",
        "overflow-x-clip",
        "flex flex-col",
        "bg-brand-gray-1",
        {
          "h-screen min-h-[560px]": isTrendsPage,
          "min-h-screen": !isTrendsPage,
        }
      )}
    >
      {children}
    </div>
  );
}

function Logo() {
  const analytics = useAnalytics();
  return (
    <Link
      to="/"
      className={cn("block rounded-sm text-brand-blue-1", "focus:outline-none")}
      onClick={() => analytics.collect("logo_clicked")}
    >
      <DeltiaIcon />
    </Link>
  );
}

function SettingsButton() {
  const line = useSelectedLine();
  const path = generatePath(paths.lineSettingsPath, { lineId: line.id });
  return (
    <Link
      to={path}
      className={cn(
        "flex items-center justify-center w-10 h-10",
        "text-brand-blue-1 hover:bg-brand-gray-1 focus:outline-none rounded-full"
      )}
    >
      <SettingsIcon className="h-5 w-5" />
    </Link>
  );
}
