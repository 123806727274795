import { useLingui } from "@lingui/react";
import { PropsWithChildren } from "react";

export function ShiftsLayout({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  return (
    <>
      <header className="py-6">
        <h2 className="text-2xl font-semibold text-brand-black">
          {i18n.t("Shifts")}
        </h2>
        <p className="text-sm">
          {i18n.t(
            "Change the variants of your shift to fit your targets & schedule."
          )}
        </p>
      </header>
      <div className="flex flex-col gap-6 my-6">{children}</div>
    </>
  );
}
