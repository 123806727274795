import { LoadingShimmer } from "@/view/components/loading-shimmer";

import { ShiftsLayout } from "./shifts-layout";

export function ShiftVariantsQueryLoadingFallback() {
  return (
    <ShiftsLayout>
      <LoadingShimmer className="min-h-6 w-96" />
      <LoadingShimmer />
      <LoadingShimmer className="min-h-6 w-96" />
      <LoadingShimmer />
    </ShiftsLayout>
  );
}
