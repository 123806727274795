import { createContext, PropsWithChildren, useContext } from "react";

import { TracingClient } from "@/domain/tracing";

const TracingContext = createContext<TracingClient | null>(null);

export function TracingProvider({
  client,
  children,
}: PropsWithChildren<{ client: TracingClient }>) {
  return (
    <TracingContext.Provider value={client}>{children}</TracingContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useTracing() {
  const context = useContext(TracingContext);
  if (!context) {
    throw new Error("useTracing must be used within a TracingProvider");
  }
  return context;
}
