import { createContext, useContext } from "react";

import { getLimitedValidTagIds, TagId } from "@/domain/tag";

export const SelectedTagIdsContext = createContext<Array<TagId>>([]);

export function useSelectedTagIds() {
  const ctx = useContext(SelectedTagIdsContext);
  if (!ctx) {
    throw new Error(
      "useSelectedTagIds must be used inside SelectedTagIdsProvider"
    );
  }
  return ctx;
}

const searchParamKey = "tagIds";

export function createTagIdValidator(allowedTagIds: Set<TagId>) {
  const limitValidTagIds = getLimitedValidTagIds(allowedTagIds);
  return (tagIds: Array<string>) => limitValidTagIds(tagIds);
}

export function parseTagIdsFromSearchParams(
  searchParams: URLSearchParams,
  validateTagIds: (tagIds: Array<string>) => Array<TagId>
): Array<TagId> {
  const maybeTagIds = searchParams.get(searchParamKey);
  const tagIds = decodeTagIds(maybeTagIds) ?? [];
  return validateTagIds(tagIds);
}

export function encodeTagIdsToSearchParams(
  searchParams: URLSearchParams,
  tagIds: Array<TagId>
) {
  searchParams.set(searchParamKey, encodeTagIds(tagIds));
  return searchParams;
}

function encodeTagIds(arr: Array<TagId>) {
  return [...new Set(arr)].join(".");
}

function decodeTagIds(
  arr: string | null | undefined
): Array<string> | undefined {
  return arr?.split(".").filter(Boolean);
}
