import { useLingui } from "@lingui/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Button } from "@/view/components";
import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";

import { getVirtualSensorImageByLineQueryKey } from "./use-virtual-sensor-image-query";

export function VirtualSensorErrorBoundary({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  const selectedLine = useSelectedLine();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          resetKeys={getVirtualSensorImageByLineQueryKey(selectedLine.id)}
          onReset={reset}
          fallbackRender={(props) => (
            <div className="flex items-center justify-center h-full min-h-96">
              <div className="p-12 flex flex-col gap-6">
                <p>
                  {i18n.t(
                    "Something went wrong when fetching areas of interests."
                  )}
                </p>
                <Button
                  analyticsEvent="error_retry_clicked"
                  className="font-semibold text-brand-blue-1 bg-transparent hover:bg-brand-gray-2"
                  onClick={props.resetErrorBoundary}
                >
                  {i18n.t("Try again")}
                </Button>
              </div>
            </div>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
