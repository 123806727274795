import { I18nProvider as LinguiProvider } from "@lingui/react";
import { useEffect } from "react";

import { detectLocale, i18n, lazyLoadLocale } from "@/i18n";

export function LocaleProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    lazyLoadLocale(detectLocale());
  }, []);
  return <LinguiProvider i18n={i18n}>{children}</LinguiProvider>;
}
