import { QueryClient } from "@tanstack/react-query";
import { createBrowserRouter, RouteObject } from "react-router-dom";

import { createGraphQlApiClient } from "@/infra/graphql-api-client";
import {
  createSentryTracingClient,
  routerTracingWrapper,
} from "@/infra/sentry-tracing";
import { LineBirdEyeViewAreaDetails } from "@/view/pages/line-id-area-id";
import { LineBirdEyeViewStationDetails } from "@/view/pages/line-id-bev-area-id-station-id";
import { LineBirdEyeViewStationDetailsOverview } from "@/view/pages/line-id-bev-area-id-station-id-overview";
import { StationVideos } from "@/view/pages/line-id-reporting-station-id/station-videos";

import { ErrorPage, LineCatchAllPage, LineRootPage } from "./pages/line-id";
import { LineBirdEyeViewPage } from "./pages/line-id-bev";
import { LineReportingPage } from "./pages/line-id-reporting";
import { StationDetailsPage } from "./pages/line-id-reporting-station-id";
import {
  LineSettingsIndexPage,
  LineSettingsRootPage,
} from "./pages/line-id-settings";
import { LineNotificationSettingsPage } from "./pages/line-id-settings-notifications";
import { LineSettingsShiftsPage } from "./pages/line-id-settings-shifts";
import { LineTrendsPage } from "./pages/line-id-trends";
import { LineVideoLibraryPage } from "./pages/line-id-video-library";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: true,
      retry: false,
    },
  },
});

export const tracingClient = createSentryTracingClient();
export const apiClient = createGraphQlApiClient(tracingClient);

export const paths = {
  root: "/",
  linePath: "/line/:lineId",
  lineReportingPath: "/line/:lineId/reporting",
  lineReportingStationDetailsPath: "/line/:lineId/reporting/station/:stationId",

  lineBirdEyeViewPath: "/line/:lineId/bev",
  lineBirdEyeViewAreaDetailsPath: "/line/:lineId/bev/area/:areaId",
  lineBirdEyeViewStationDetailsPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId",
  lineBirdEyeViewStationDetailsOverviewPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId/overview",
  lineBirdEyeViewStationDetailsVideosPath:
    "/line/:lineId/bev/area/:areaId/station/:stationId/videos",

  lineTrendsPath: "/line/:lineId/trends",
  lineVideoLibraryPath: "/line/:lineId/video-library",
  lineSettingsPath: "/line/:lineId/settings",
  lineSettingsShiftsPath: "/line/:lineId/settings/shifts",
  lineSettingsNotificationsPath: "/line/:lineId/settings/notifications",
} as const;

const routes: Array<RouteObject> = [
  {
    element: <LineRootPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: paths.lineReportingPath,
        element: <LineReportingPage />,
        children: [
          {
            path: paths.lineReportingStationDetailsPath,
            element: <StationDetailsPage />,
          },
        ],
      },
      {
        path: paths.lineBirdEyeViewPath,
        element: <LineBirdEyeViewPage />,
        children: [
          {
            path: paths.lineBirdEyeViewAreaDetailsPath,
            element: <LineBirdEyeViewAreaDetails />,
          },
          {
            path: paths.lineBirdEyeViewStationDetailsPath,
            element: <LineBirdEyeViewStationDetails />,
            children: [
              {
                path: paths.lineBirdEyeViewStationDetailsOverviewPath,
                element: <LineBirdEyeViewStationDetailsOverview />,
              },
              {
                path: paths.lineBirdEyeViewStationDetailsVideosPath,
                element: <StationVideos />,
              },
            ],
          },
        ],
      },
      {
        path: paths.lineTrendsPath,
        element: <LineTrendsPage />,
      },
      {
        path: paths.lineVideoLibraryPath,
        element: <LineVideoLibraryPage />,
      },
      {
        path: paths.lineSettingsPath,
        element: <LineSettingsRootPage />,
        children: [
          {
            index: true,
            element: <LineSettingsIndexPage />,
          },
          {
            path: paths.lineSettingsShiftsPath,
            element: <LineSettingsShiftsPage />,
          },
          {
            path: paths.lineSettingsNotificationsPath,
            element: <LineNotificationSettingsPage />,
          },
        ],
      },
      {
        path: "*", // Catch all
        element: <LineCatchAllPage />,
      },
    ],
  },
];

const createRouter = routerTracingWrapper(createBrowserRouter);
export const router = createRouter(routes);
