import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { PropsWithChildren, ReactNode } from "react";

import { cn } from "@/view/utils";

type GroupType = "line_output" | "system" | "maintenance" | "custom";

export function SettingsSection({
  className,
  children,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <section className={cn("flex items-start gap-6 py-6 border-b", className)}>
      {children}
    </section>
  );
}

export function SettingsSectionAside({
  className,
  group,
}: {
  group: GroupType;
  className?: string;
}) {
  const { i18n } = useLingui();
  const { title, description } = getSectionInfo(group, i18n);
  return (
    <aside className={cn("basis-1/4", className)}>
      <h3 className="font-semibold">{title}</h3>
      {description && <p className="text-sm">{description}</p>}
    </aside>
  );
}

function getSectionInfo(group: GroupType, i18n: I18n) {
  switch (group) {
    case "line_output":
      return {
        title: i18n.t("Line output"),
      };
    case "system":
      return {
        title: i18n.t("System Notifications"),
      };
    case "maintenance":
      return {
        title: i18n.t("Maintenance"),
      };
    case "custom":
      return {
        title: i18n.t("Custom Notifications"),
        description: i18n.t(
          "Customize new notifications for specific stations or events."
        ),
      };
  }
}

export function SettingsSectionContent({
  className,
  children,
}: PropsWithChildren<{
  className?: string;
}>) {
  return (
    <div
      className={cn("grow flex flex-col basis-3/4 -mt-6 divide-y", className)}
    >
      {children}
    </div>
  );
}

export function SettingsSectionContentRow({
  delivery,
  children,
}: PropsWithChildren<{
  delivery: ReactNode;
}>) {
  return (
    <div className="flex gap-6 py-6 justify-between items-center">
      <div className="grow">{children}</div>
      <div>{delivery}</div>
    </div>
  );
}
