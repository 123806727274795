import * as Sentry from "@sentry/react";
import React from "react";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { TracingClient } from "@/domain/tracing";

// import.meta.env.PROD is `true` for the bundled version of JavaScript,
// meaning the code that is shippable to any of the deployment environments.
// It will be `false` when the code is running in local development or testing.
const isProductionBuild = import.meta.env.PROD;
const isWindowDefined = typeof window !== "undefined";
const tracingUrl = import.meta.env.VITE_TRACING_URL as string | undefined;
const enableTracing = !!tracingUrl && isProductionBuild && isWindowDefined;

const environment = (() => {
  switch (window.location.hostname) {
    case "app.deltia.ai":
    case "v2.app.deltia.ai":
      return "production";
    case "staging.deltia.dev":
      return "staging";
    default:
      return "development";
  }
})();

export function createSentryTracingClient(): TracingClient {
  if (enableTracing) {
    Sentry.init({
      dsn: tracingUrl,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          // Prevent usage of WebWorker because of CSP issues
          // https://github.com/getsentry/sentry-javascript/issues/6739
          useCompression: false,
        }),
      ],
      environment,
      // Performance Monitoring
      tracesSampleRate: 0.5,
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }

  function captureException(error: Error) {
    if (!enableTracing) return;
    Sentry.captureException(error);
  }

  function captureMessage(message: string) {
    if (!enableTracing) return;
    Sentry.captureMessage(message);
  }

  return Object.freeze({
    captureException,
    captureMessage,
  });
}

export function routerTracingWrapper(createRouter: typeof createBrowserRouter) {
  if (!enableTracing) return createRouter;
  return Sentry.wrapCreateBrowserRouter(createRouter);
}
