import { PropsWithChildren, ReactNode } from "react";

export function ChartWrapper({
  height,
  controls,
  children,
}: PropsWithChildren<{ controls: ReactNode; height: number }>) {
  return (
    <div>
      <header className="px-6 py-6 flex gap-6 justify-between items-center">
        {controls}
      </header>
      <div className="overflow-visible" style={{ height }}>
        {children}
      </div>
    </div>
  );
}
