import { addMinutes, endOfDay, startOfDay } from "date-fns";
import { z } from "zod";

import { dateTimeRangeSchema, defaultStartOfWeek } from "./common/time-filter";
import { lineIdSchema } from "./line";

export const shiftIdSchema = z.string().brand<"ShiftId">();
export const shiftSchema = z.object({
  id: shiftIdSchema,
  name: z.string(),
  start: z.date(),
  end: z.date(),
});
export const shiftVariantTargetsSchema = z.object({
  output: z.number().int().nonnegative().catch(0),
  persons: z.number().int().nonnegative().catch(0),
  cycleTime: z.number().int().nonnegative().catch(0),
});
const shiftVariantIdSchema = z.string().brand<"ShiftVariantId">();
export const shiftVariantSchema = z.object({
  id: shiftVariantIdSchema,
  shiftId: shiftIdSchema,
  name: z.string(),
  enabled: z.boolean().default(true),
  start: z.date(),
  end: z.date(),
  breaks: z.array(dateTimeRangeSchema),
  repeatStart: z.date(),
  repeatEvery: z.number().positive().default(1),
  repeatInterval: z
    .union([z.literal("week"), z.literal("month")])
    .default("week"),
  weekdaysWithTargets: z.array(shiftVariantTargetsSchema.nullable()).length(7),
});
const shiftVariantOverrideSchema = z.object({
  id: shiftVariantIdSchema,
  date: z.date(),
  targets: shiftVariantTargetsSchema,
});
const shiftWithVariantsSchema = shiftSchema.extend({
  variants: z.array(shiftVariantSchema),
});
const shiftsWithVariantsFiltersSchema = z.object({
  lineId: lineIdSchema,
  applicableDate: z.date().nullable(),
});

export type ShiftId = z.infer<typeof shiftIdSchema>;
export type Shift = z.infer<typeof shiftSchema>;
export type ShiftVariantOverride = z.infer<typeof shiftVariantOverrideSchema>;
export type ShiftVariantId = z.infer<typeof shiftVariantIdSchema>;
export type ShiftVariant = z.infer<typeof shiftVariantSchema>;
export type ShiftWithVariants = z.infer<typeof shiftWithVariantsSchema>;
export type ShiftsWithVariantsFilters = z.infer<
  typeof shiftsWithVariantsFiltersSchema
>;

export type ShiftVariantTarget = z.infer<typeof shiftVariantTargetsSchema>;
export type ShiftVariantTargetType = keyof ShiftVariantTarget;

/**
 * Returns an array of selected shifts based on the provided shift array and selected shift IDs.
 *
 * @param shifts - The array of shifts to filter.
 * @param selectedShiftIds - The array of selected shift IDs.
 * @returns An array of selected shifts.
 */
export function getSelectedShifts(
  shifts: Array<Shift>,
  selectedShiftIds: Array<ShiftId>
): Array<Shift> {
  return shifts.filter((shift) => selectedShiftIds.includes(shift.id));
}

export const defaultShiftVariantId = "default-variant" as ShiftVariantId;

export function createDefaultVariantForShift(
  shift: ShiftWithVariants
): ShiftVariant {
  const weekdaysWithTargets: ShiftVariant["weekdaysWithTargets"] = [];
  for (let i = 0; i < 7; i++) {
    const targets = shiftVariantTargetsSchema.parse({});
    weekdaysWithTargets[i] = i < 5 ? targets : null;
  }
  const variant: ShiftVariant = {
    id: defaultShiftVariantId,
    shiftId: shift.id,
    name: `Variant ${shift.variants.length + 1}. for ${shift.name}`,
    enabled: true,
    start: shift.start,
    end: shift.end,
    breaks: [],
    repeatStart: defaultStartOfWeek,
    repeatEvery: 1,
    repeatInterval: "week",
    weekdaysWithTargets,
  };
  return shiftVariantSchema.parse(variant);
}

export function addBreakToVariant(variant: ShiftVariant): ShiftVariant {
  const lastBreak = variant.breaks.at(-1);
  const newBreak = {
    start: addMinutes(lastBreak?.end ?? variant.start, 15),
    end: addMinutes(lastBreak?.end ?? variant.start, 30),
  };
  const breaks = [...variant.breaks, newBreak];
  return { ...variant, breaks };
}

export function unflattenArray<T>(arr: Array<T>): Array<[T, T]> {
  const result: Array<[T, T]> = [];
  for (let i = 0; i < arr.length; i += 2) {
    const pair: [T, T] = [arr[i], arr[i + 1]];
    result.push(pair);
  }
  return result;
}

export function isShiftVariantValid(variant: ShiftVariant): boolean {
  return (
    isVariantNameValid(variant) &&
    isVariantWorkingTimesValid(variant) &&
    isVariantWeekdaysTargetsValid(variant)
  );
}

export function isVariantNameValid(variant: ShiftVariant): boolean {
  return variant.name.trim().length > 3;
}

export function isVariantWorkingTimesValid(variant: ShiftVariant): boolean {
  const workingTimes = unflattenArray([
    variant.start,
    ...variant.breaks.flatMap((time) => [time.start, time.end]),
    variant.end,
  ]);

  let times: Array<[Date, Date]> = [];

  //  Split the time spans that span over midnight
  for (const [start, end] of workingTimes) {
    if (start === end) {
      return false;
    } else if (start < end) {
      times.push([start, end]);
    } else {
      times.push([start, endOfDay(start)]);
      times.push([startOfDay(end), end]);
    }
  }

  // Sort the list by the start time
  times = times.sort(
    ([aStart], [bStart]) => aStart.getTime() - bStart.getTime()
  );

  // Initialize the end time of the previous time span
  let prevEndTime = startOfDay(variant.start);

  //  Iterate over the time spans
  for (const [start, end] of times) {
    // If the start time of the current time span is less than the end time of the previous time span
    if (start < prevEndTime) {
      return false;
    }

    // Update the end time of the previous time span
    prevEndTime = end;
  }
  // If there are no overlaps
  return true;
}

function isVariantWeekdaysTargetsValid(variant: ShiftVariant): boolean {
  if (variant.weekdaysWithTargets.length !== 7) {
    return false;
  }
  if (variant.weekdaysWithTargets.every((it) => it === null)) {
    return false;
  }
  return variant.weekdaysWithTargets.every((dayTargets) => {
    if (!dayTargets) {
      return true;
    }
    return (
      dayTargets.output >= 0 &&
      dayTargets.cycleTime >= 0 &&
      dayTargets.persons >= 0
    );
  });
}
