import { useSuspenseQuery } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext, useMemo } from "react";

import {
  getDefaultLine,
  operatinalLevelsByIds,
  OperationalLevels,
} from "@/domain/levels";
import { useApiClient } from "@/view/providers/api-client-provider";
import { useTracing } from "@/view/providers/tracing-provider";

function createLevelsCtxValue(levelsData: OperationalLevels) {
  return {
    levelsData,
    defaultLine: getDefaultLine(levelsData),
    ...operatinalLevelsByIds(levelsData),
  };
}

export type LevelsCtxValue = ReturnType<typeof createLevelsCtxValue>;

const LevelsContext = createContext<LevelsCtxValue | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useLevels() {
  const levels = useContext(LevelsContext);
  if (!levels) {
    throw new Error("useLevels must be used within a LevelsProvider");
  }
  return levels;
}

/**
 * Provides operational levels (factories, lines, stations)
 * down the component tree.
 */
export function LevelsProvider({ children }: PropsWithChildren) {
  const apiClient = useApiClient();
  const tracing = useTracing();
  const query = useSuspenseQuery({
    queryKey: ["operational-level"] as const,
    queryFn: ({ signal }) => apiClient.getOperationalLevels({ signal }),
    staleTime: Infinity,
  });
  const data = useMemo(() => {
    const data = createLevelsCtxValue(query.data);
    if (!data.defaultLine) {
      tracing.captureMessage(
        "Default line could not be found. Are lines available in the system?"
      );
    }
    return data;
  }, [query.data, tracing]);
  return (
    <LevelsContext.Provider value={data}>{children}</LevelsContext.Provider>
  );
}
