import { createContext, useContext } from "react";
import { z } from "zod";

export const chartTypes = [
  "output_by_time",
  "output",
  "avg_cycle_time",
  "activity",
] as const;

const chartTypeSchema = z.enum(chartTypes);
export type ChartType = z.infer<typeof chartTypeSchema>;

export const SelectedChartTypeContext = createContext<ChartType>(chartTypes[0]);

export function useSelectedChartType() {
  const chartType = useContext(SelectedChartTypeContext);
  if (!chartType) {
    throw new Error(
      "useSelectedChartType must be used inside SelectedChartTypeProvider"
    );
  }
  return chartType;
}

const searchParamKey = "tab";

export function parseChartTypeFromSearchParams(
  searchParams: URLSearchParams
): ChartType {
  const maybeChartType = chartTypeSchema.safeParse(
    searchParams.get(searchParamKey)
  );
  return maybeChartType.success ? maybeChartType.data : chartTypes[0];
}

export function encodeChartTypeToSearchParams(
  searchParams: URLSearchParams,
  chartType: ChartType
) {
  searchParams.set(searchParamKey, chartType);
  return searchParams;
}
