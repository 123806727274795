import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { Pagination } from "@/domain/common/pagination";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { getOrderedStationIds, LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";
import { StationId } from "@/domain/station";
import { getAllowedTagIds, getTagsForTypes, TagId } from "@/domain/tag";
import { FiltersSelect } from "@/view/pages/line-id/filters-select";
import {
  createTagIdValidator,
  encodeTagIdsToSearchParams,
  parseTagIdsFromSearchParams,
  SelectedTagIdsContext,
} from "@/view/pages/line-id/use-selected-tag-ids";
import { useTags } from "@/view/pages/line-id/use-tags";
import { useAnalytics } from "@/view/providers/analytics-provider";

import { DateRangeSelect } from "../line-id/date-range-select";
import { ShiftSelect } from "../line-id/shift-select";
import {
  encodeDateRangeToSearchParams,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  SelectedLineContext,
  useSelectedLine,
} from "../line-id/use-selected-line";
import {
  encodeShiftIdsToSearchParams,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";
import {
  encodeStationIdsToSearchParams,
  parseStationIdsFromSearchParams,
  SelectedStationIdsContext,
} from "../line-id/use-selected-station-ids";
import {
  encodePaginationToSearchParams,
  PaginationContext,
  parsePaginationFromSearchParams,
} from "./use-pagination";

type VideoLibraryFilters = {
  line: LineWithStations;
  shiftIds: Array<ShiftId>;
  stationsIds: Array<StationId>;
  tagIds: Array<TagId>;
  dateRange: DateRangeFilter;
  pagination: Pagination;
};

type VideoLibraryFilterActions = {
  selectShifts: (shiftIds: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
  selectStations: (stationsIds: Array<StationId>) => void;
  selectTags: (tagsIds: Array<TagId>) => void;
  selectPage: (page: number) => void;
};

const VideoLibraryFilterActionsContext =
  createContext<VideoLibraryFilterActions | null>(null);

const getAllowedTags = getTagsForTypes(["System", "Product", "User"]);

export function VideoLibraryFiltersProvider({ children }: PropsWithChildren) {
  const line = useSelectedLine();
  const tagStore = useTags();
  const analytics = useAnalytics();
  const validateTagIds = useMemo(
    () => createTagIdValidator(getAllowedTagIds(getAllowedTags(tagStore))),
    [tagStore]
  );
  const [state, setState] = useState<VideoLibraryFilters>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      line,
      shiftIds: parseShiftIdsFromSearchParams(searchParams, line)!,
      dateRange: parseDateRangeFromSearchParams(searchParams),
      stationsIds: parseStationIdsFromSearchParams(searchParams, line),
      pagination: parsePaginationFromSearchParams(searchParams),
      tagIds: parseTagIdsFromSearchParams(searchParams, validateTagIds),
    };
  });

  useEffect(() => {
    // synchronise with lineId from the URL
    if (state.line.id === line.id) return;
    setState((prev) => ({
      line,
      dateRange: prev.dateRange,
      shiftIds: line.shifts.map((s) => s.id),
      stationsIds: line.stations.map((s) => s.id),
      tagIds: validateTagIds(prev.tagIds),
      pagination: {
        ...prev.pagination,
        offset: 0,
      },
    }));
  }, [line, state.line.id, validateTagIds]);

  // TODO: turn this into a generic "useSynchronizeUrl" custom hook
  // when the "useEffectEvent" hook is released in a stable version of React
  // => https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
  useEffect(() => {
    // synchronise with URL
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, state.shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, state.dateRange);
    searchParams = encodeStationIdsToSearchParams(
      searchParams,
      state.stationsIds
    );
    searchParams = encodeTagIdsToSearchParams(
      searchParams,
      validateTagIds(state.tagIds)
    );
    searchParams = encodePaginationToSearchParams(
      searchParams,
      state.pagination
    );

    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${searchParams}`
    );

    // send analytics event
    analytics.collect("filters_changed");
  }, [state, analytics, validateTagIds]);

  const selectedLine = useMemo(() => state.line, [state.line]);
  const selectedShiftIds = useMemo(() => state.shiftIds, [state.shiftIds]);
  const selectedDateRange = useMemo(() => state.dateRange, [state.dateRange]);
  const stationsIds = useMemo(() => state.stationsIds, [state.stationsIds]);
  const tagIds = useMemo(() => state.tagIds, [state.tagIds]);
  const pagination = useMemo(() => state.pagination, [state.pagination]);
  const actions = useMemo<VideoLibraryFilterActions>(() => {
    return {
      selectShifts: (shiftIds: Array<ShiftId>) => {
        setState((prev) => ({
          ...prev,
          shiftIds,
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectDateRange: (dateRange: DateRangeFilter) => {
        setState((prev) => ({
          ...prev,
          dateRange,
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectStations: (stationsIds: Array<StationId>) => {
        setState((prev) => ({
          ...prev,
          stationsIds: getOrderedStationIds(line, stationsIds),
          pagination: { ...prev.pagination, offset: 0 },
        }));
      },
      selectTags: (tagIds) => {
        setState((prev) => ({
          ...prev,
          tagIds: validateTagIds(tagIds),
        }));
      },
      selectPage: (page: number) => {
        setState((prev) => ({
          ...prev,
          pagination: {
            ...prev.pagination,
            offset: (page - 1) * prev.pagination.limit,
          },
        }));
      },
    };
  }, [line, validateTagIds]);

  return (
    // provide the line context again, so that the "selectedLine" comes
    // from state value, which prevents double rerenders caused by line change
    <SelectedLineContext.Provider value={selectedLine}>
      <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
        <SelectedDateRangeContext.Provider value={selectedDateRange}>
          <SelectedStationIdsContext.Provider value={stationsIds}>
            <PaginationContext.Provider value={pagination}>
              <SelectedTagIdsContext.Provider value={tagIds}>
                <VideoLibraryFilterActionsContext.Provider value={actions}>
                  {children}
                </VideoLibraryFilterActionsContext.Provider>
              </SelectedTagIdsContext.Provider>
            </PaginationContext.Provider>
          </SelectedStationIdsContext.Provider>
        </SelectedDateRangeContext.Provider>
      </SelectedShiftIdsContext.Provider>
    </SelectedLineContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useVideoLibraryFilterActions() {
  const actions = useContext(VideoLibraryFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useVideoLibraryFilterActions must be used inside VideoLibraryFilterActionsProvider"
    );
  }
  return actions;
}

export function VideoLibraryFilters() {
  const actions = useVideoLibraryFilterActions();
  const tagStore = useTags();
  const availableTags = getAllowedTags(tagStore);
  return (
    <>
      <FiltersSelect
        availableTags={availableTags}
        onStationIdsChange={actions.selectStations}
        onTagIdsChange={actions.selectTags}
      />
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
