import { useMemo } from "react";

import { Station, StationId } from "@/domain/station";

import { useSelectedLine } from "../../line-id/use-selected-line";

export function useStationName() {
  const line = useSelectedLine();
  const stationsById = useMemo(() => {
    return line.stations.reduce(
      (acc, station) => {
        acc[station.id] = station;
        return acc;
      },
      {} as Record<string, Station>
    );
  }, [line]);

  function getStationName(stationId: StationId) {
    const station = stationsById[stationId];
    if (!station) return stationId;
    return station.name;
  }

  return getStationName;
}
