import React, { useContext } from "react";

import { AnalyticsClient } from "@/domain/analytics";

const AnalyticsClientContext = React.createContext<AnalyticsClient | null>(
  null
);

/**
 * Provides the analytics client down the component tree.
 */
export function AnalyticsClientProvider({
  client,
  children,
}: {
  client: AnalyticsClient;
  children: React.ReactNode;
}) {
  return (
    <AnalyticsClientContext.Provider value={client}>
      {children}
    </AnalyticsClientContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useAnalytics() {
  const client = useContext(AnalyticsClientContext);
  if (!client) {
    throw new Error("AnalyticsClientContext is not provided");
  }
  return client;
}
