import "@/view/configureAmplify";
import "@aws-amplify/ui-react/styles.css";

import {
  Authenticator,
  AuthenticatorProps,
  Loader,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { useLingui } from "@lingui/react";
import { useEffect } from "react";

import { DeltiaIcon } from "@/view/components";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { useTracing } from "@/view/providers/tracing-provider";
import styles from "@/view/styles/authenticator.module.css";
import { cn } from "@/view/utils";

type Props = {
  children: React.ReactNode;
};

export const AuthenticatorProvider = Authenticator.Provider;

export function AppAuthenticator({ children }: Props) {
  const { i18n } = useLingui();
  const analytics = useAnalytics();
  const tracing = useTracing();
  const auth = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (auth.authStatus === "authenticated" && auth.user) {
      const userId = auth.user.userId;
      const email = auth.user.signInDetails?.loginId;

      if (!email) {
        tracing.captureMessage(
          `User object with ID: "${userId}" has no email value during login.`
        );
      }
      analytics.identifyUser(userId, { email });
    }
  }, [auth, analytics, tracing]);

  const components = {
    Footer() {
      return (
        <div className={cn("flex justify-center text-brand-blue-1 p-6")}>
          <DeltiaIcon />
        </div>
      );
    },
    SignIn: {
      Header() {
        return (
          <div className="pt-6 px-8">
            <h3 className={cn("text-3xl")}>{i18n.t("signInFormTitle")}</h3>
          </div>
        );
      },
    },
  };

  const formFields: AuthenticatorProps["formFields"] = {
    signIn: {
      username: {
        label: i18n.t("signInFormUsernameLabel"),
        placeholder: "john.doe@factory.com",
      },
      password: {
        label: i18n.t("signInFormForgotPasswordLabel"),
        placeholder: "********",
      },
    },
    setupTotp: {
      QR: {
        totpIssuer: "Deltia",
      },
    },
  };

  if (auth.authStatus !== "authenticated") {
    return (
      <div className="flex justify-center items-center min-h-screen bg-brand-gray-1">
        {auth.authStatus === "configuring" && <Loader size="large" />}
        {auth.authStatus === "unauthenticated" && (
          <Authenticator
            formFields={formFields}
            components={components}
            hideSignUp={true}
            className={styles.root}
          />
        )}
      </div>
    );
  }

  return <>{children}</>;
}
