import { line as d3line } from "d3-shape";
import { memo, useMemo } from "react";

import {
  AreaConnection,
  AreaCoordinates,
  AreaId,
} from "@/domain/areas-of-interests";

import { useVirtualSensorAreasLayout } from "./use-virtual-sensor-layout";

const line = d3line<{ x: number; y: number }>()
  .x((d) => d.x)
  .y((d) => d.y);

export const AreaConnections = memo(function AreaConnections({
  connections,
  areaId,
}: {
  connections: AreaConnection[];
  areaId: AreaId;
}) {
  const areasLayout = useVirtualSensorAreasLayout();
  const area = useMemo(
    () => areasLayout.areas.find((area) => area.id === areaId),
    [areasLayout, areaId]
  );

  if (!area) return null;

  return (
    <g>
      {connections.map((connection) => {
        const toAreaCoordinates =
          areasLayout.areaCoordinatesByAreaId[connection.areaId];

        // make sure we have coordinates for the connected area
        if (!toAreaCoordinates) return null;

        return (
          <CanvasAreaConnection
            key={`${area.id}_${connection.areaId}_connections`}
            fromCoordinates={area.coordinates}
            toCoordinates={toAreaCoordinates}
          />
        );
      })}
      {connections.map((connection) => {
        const toAreaCoordinates =
          areasLayout.areaCoordinatesByAreaId[connection.areaId];
        // make sure we have coordinates for the connected area
        if (!toAreaCoordinates) return null;

        return (
          <CanvasAreaConnectionLabel
            key={`${area.id}_${connection.areaId}_labels`}
            label={`${connection.count}x`}
            toCoordinates={toAreaCoordinates}
          />
        );
      })}
    </g>
  );
});

function CanvasAreaConnectionLabel({
  label,
  toCoordinates,
}: {
  label: string;
  toCoordinates: AreaCoordinates;
}) {
  const height = 32;
  const width = 80;
  const x = toCoordinates.x + toCoordinates.width / 2 - width / 2;
  const y = toCoordinates.y + toCoordinates.height / 2 - height / 2;

  return (
    <g pointerEvents="none">
      <rect
        x={x}
        y={y}
        height={height}
        width={width}
        rx={height / 2}
        fill="rgba(20, 142, 255, 0.9)"
        stroke="rgba(20, 142, 255, 1)"
        strokeWidth={1}
      />
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="white"
        fontWeight="bold"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {label}
      </text>
    </g>
  );
}

function CanvasAreaConnection({
  fromCoordinates,
  toCoordinates,
}: {
  fromCoordinates: AreaCoordinates;
  toCoordinates: AreaCoordinates;
}) {
  const points = getConnectionPoints(fromCoordinates, toCoordinates);
  const path = line(points) ?? "";

  return (
    <g pointerEvents="none">
      <defs>
        <marker
          id="arrow"
          markerWidth="10"
          markerHeight="10"
          refX="5"
          refY="5"
          orient="auto"
        >
          <path d="M0,0 L10,5 L0,10 Z" fill="rgba(20, 142, 255, 0.9)" />
        </marker>
      </defs>
      <path
        d={path}
        fill="none"
        stroke="rgba(20, 142, 255, 0.9)"
        strokeWidth={2}
        markerMid="url(#arrow)"
      />
    </g>
  );
}

function getConnectionPoints(
  startArea: AreaCoordinates,
  endArea: AreaCoordinates
) {
  const startPoints = getAreaSideCenters(startArea);
  const endPoints = getAreaSideCenters(endArea);

  let minDistance = Infinity;
  let startPoint = startPoints[0];

  for (const sp of startPoints) {
    for (const ep of endPoints) {
      const distance = Math.hypot(ep.x - sp.x, ep.y - sp.y);
      if (distance < minDistance) {
        minDistance = distance;
        startPoint = sp;
      }
    }
  }

  const endPoint = {
    x: endArea.x + endArea.width / 2,
    y: endArea.y + endArea.height / 2,
  };
  const midPoint = {
    x: (startPoint.x + endPoint.x) / 2,
    y: (startPoint.y + endPoint.y) / 2,
  };

  return [startPoint, midPoint, endPoint];
}

function getAreaSideCenters(area: AreaCoordinates) {
  return [
    { x: area.x, y: area.y + area.height / 2 }, // left center
    { x: area.x + area.width, y: area.y + area.height / 2 }, // right center
    { x: area.x + area.width / 2, y: area.y }, // top center
    { x: area.x + area.width / 2, y: area.y + area.height }, // bottom center
  ];
}
