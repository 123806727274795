import { UseQueryResult } from "@tanstack/react-query";
import { ReactNode } from "react";

import { useElementSize } from "@/view/providers/use-element-size";
import { cn } from "@/view/utils";

import { Card, CardContent, CardHeader, CardTitle } from "../card";
import { LoadingStatus } from "../loading-status";
import { ChartErrorStatus } from "./chart-error-status";

export function ChartCard<T>({
  query,
  title,
  children,
  height,
}: {
  query: UseQueryResult<T>;
  children: (data: T, sizes: { height: number; width: number }) => ReactNode;
  title?: string;
  height: number;
}) {
  const { ref, width: wrapperWidth, height: wrapperHeight } = useElementSize();
  const isLoadingNewData = query.isFetching && !query.isLoading;

  return (
    <Card
      className={cn(`p-4 border grid`, {
        "grid-rows-[auto_1fr]": Boolean(title),
      })}
      style={{ height }}
    >
      {title && (
        <CardHeader>
          <CardTitle>{title}</CardTitle>
        </CardHeader>
      )}
      <CardContent
        ref={(el) => (ref.current = el)}
        className={cn("relative h-full p-0 grid items-center", {
          "blur-sm": isLoadingNewData,
        })}
      >
        {(() => {
          if (query.data)
            return children(query.data, {
              width: wrapperWidth,
              height: wrapperHeight,
            });
          if (query.isLoading) return <LoadingStatus />;
          return <ChartErrorStatus size={height < 400 ? "small" : "medium"} />;
        })()}
      </CardContent>
    </Card>
  );
}
