import {
  keepPreviousData,
  Query,
  QueryClient,
  queryOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useMemo, useState } from "react";

import { PaginatedResponse } from "@/domain/api-client";
import { BaseError } from "@/domain/common/errors";
import { Pagination } from "@/domain/common/pagination";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { LineId } from "@/domain/line";
import { ShiftId } from "@/domain/shifts";
import { StationId } from "@/domain/station";
import { Tag, TagId } from "@/domain/tag";
import {
  TagVideoParamsSchema,
  UpdateVideoDescriptionParamsSchema,
  Video,
  VideoBookmarkParamsSchema,
  VideoId,
} from "@/domain/video";
import { getTagsQueryKey } from "@/view/pages/line-id/use-tags";
import { useApiClient } from "@/view/providers/api-client-provider";

function getQueryKeyForVideos(
  dateRange: DateRangeFilter,
  stationIds: Array<StationId>,
  shiftIds: Array<ShiftId>,
  tagIds: Array<TagId>,
  pagination: Pagination,
  durationRange?: [number, number] | null,
  onlyBookmarks?: boolean
) {
  return [
    "videos",
    stationIds,
    shiftIds,
    tagIds,
    dateRange,
    durationRange,
    onlyBookmarks,
    pagination.offset,
    pagination.limit,
    pagination.order,
  ] as const;
}

export function useVideosQuery({
  stationIds,
  shiftIds,
  tagIds,
  dateRange,
  durationRange = null,
  onlyBookmarks = false,
  pagination,
}: {
  dateRange: DateRangeFilter;
  stationIds: Array<StationId>;
  shiftIds: Array<ShiftId>;
  tagIds: Array<TagId>;
  durationRange?: [number, number] | null;
  onlyBookmarks?: boolean;
  pagination: Pagination;
}) {
  const apiClient = useApiClient();
  return queryOptions<PaginatedResponse<Video>, BaseError>({
    queryKey: getQueryKeyForVideos(
      dateRange,
      stationIds,
      shiftIds,
      tagIds,
      pagination,
      durationRange,
      onlyBookmarks
    ),
    queryFn: () =>
      apiClient.getVideosForStations({
        stationIds,
        tagIds,
        shiftIds,
        dateRange,
        durationRange,
        onlyBookmarks,
        ...pagination,
      }),
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
    placeholderData: keepPreviousData,
    refetchOnMount: true,
  });
}

export function useSelectedVideo(videos: Array<Video>) {
  const [selectedVideoId, setSelectedVideoId] = useState<VideoId | null>(null);
  const [prevVideoId, nextVideoId] = useMemo(() => {
    if (!selectedVideoId) return [null, null];
    const idx = videos.findIndex((video) => video.id === selectedVideoId);
    return [videos[idx - 1]?.id ?? null, videos[idx + 1]?.id ?? null];
  }, [selectedVideoId, videos]);

  return {
    selectedVideoId,
    prevVideoId,
    nextVideoId,
    setSelectedVideoId,
  };
}

export function useUpdateVideoDescriptionMutation(
  onSuccess?: (props: { description: string }) => void
) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, BaseError, UpdateVideoDescriptionParamsSchema>({
    mutationFn: apiClient.updateVideoDescription,
    onSuccess(_, props) {
      const value = { description: props.description };
      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        description: props.description,
      }));

      onSuccess?.(value);
    },
  });
}

export function useCreateVideoBookmarkMutation(
  onSuccess?: (props: { bookmarked: boolean }) => void
) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, BaseError, VideoBookmarkParamsSchema>({
    mutationFn: apiClient.createVideoBookmark,
    onSuccess(_, props) {
      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        bookmarked: true,
      }));

      onSuccess?.({ bookmarked: true });
    },
  });
}

export function useDeleteVideoBookmarkMutation(
  onSuccess?: (props: { bookmarked: boolean }) => void
) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, BaseError, VideoBookmarkParamsSchema>({
    mutationFn: apiClient.deleteVideoBookmark,
    onSuccess(_, props) {
      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        bookmarked: false,
      }));

      onSuccess?.({ bookmarked: false });
    },
  });
}

export function useCreateTagForVideoMutation({ lineId }: { lineId: LineId }) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useMutation<
    Tag,
    BaseError,
    {
      name: string;
      videoId: VideoId;
    }
  >({
    mutationFn: async ({ name, videoId }) => {
      const createResponse = await apiClient.createTag({ name, lineId });
      await apiClient.tagVideo({ tagId: createResponse.id, videoId });

      return createResponse;
    },
    onSuccess(data, props) {
      queryClient.setQueryData<Tag[]>(getTagsQueryKey(lineId), (tags) =>
        (tags ?? []).concat(data)
      );

      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        tagIds: video.tagIds.concat(data.id),
      }));
    },
  });
}

export function useTagVideoMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, BaseError, TagVideoParamsSchema>({
    mutationFn: apiClient.tagVideo,
    onSuccess(_, props) {
      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        tagIds: video.tagIds.concat(props.tagId),
      }));
    },
  });
}

export function useUntagVideoMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation<void, BaseError, TagVideoParamsSchema>({
    mutationFn: apiClient.untagVideo,
    onSuccess(_, props) {
      updateVideoInCache(queryClient, props.videoId, (video) => ({
        ...video,
        tagIds: video.tagIds.filter((tagId) => tagId !== props.tagId),
      }));
    },
  });
}

function updateVideoInCache(
  queryClient: QueryClient,
  videoId: VideoId,
  patchFn: (videos: Video) => Video
) {
  const videoQueries = queryClient
    .getQueryCache()
    .findAll({ queryKey: ["videos"] }) as Query<PaginatedResponse<Video>>[];

  for (const query of videoQueries) {
    if (query.state.data) {
      query.setData({
        ...query.state.data,
        data: query.state.data.data.map((video) => {
          if (video.id === videoId) {
            return patchFn(video);
          }

          return video;
        }),
      });
    }
  }
}
