import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { StationId } from "@/domain/station";
import { type LineAverageCycleTimeByStation } from "@/domain/statistics";
import { ChartErrorStatus } from "@/view/components";
import { useSelectedStationIds } from "@/view/pages/line-id/use-selected-station-ids";
import { CycleTimeByStationChart } from "@/view/pages/line-id-reporting/charts/cycle-time-by-station-chart";
import { useApiClient } from "@/view/providers/api-client-provider";

import { LineReportingChartsLoading } from "./line-statistics";
import { useLineStatisticsParams } from "./line-statistics-provider";

function toAverageCycleTimeByStationChartData(stationIds: StationId[]) {
  return (data: LineAverageCycleTimeByStation) => {
    const result = [];

    for (const stationId of stationIds) {
      const dataByStation = data[stationId];
      if (dataByStation) {
        result.push(dataByStation);
      }
    }

    return result;
  };
}

export function LineCycleTimeByStationChart({
  height,
  onStationSelect,
}: {
  height: number;
  onStationSelect: (stationId: StationId) => void;
}) {
  const stationIds = useSelectedStationIds();
  const apiClient = useApiClient();
  const [line, shiftIds, tagIds, dateRange, productIds] =
    useLineStatisticsParams();

  const toChartData = useMemo(
    () => toAverageCycleTimeByStationChartData(stationIds),
    [stationIds]
  );

  const query = useQuery({
    queryKey: [
      "line-average-cycle-time-by-station",
      line.id,
      shiftIds,
      tagIds,
      dateRange,
      productIds,
    ],
    queryFn: () =>
      apiClient.getLineAverageCycleTimeByStation({
        lineId: line.id,
        shiftIds,
        tagIds,
        dateRange,
        productIds,
      }),
    select: toChartData,
  });

  if (query.isLoading) {
    return <LineReportingChartsLoading />;
  }

  if (query.data && query.data.length > 0) {
    return (
      <CycleTimeByStationChart
        height={height}
        data={query.data}
        onBarClick={onStationSelect}
      />
    );
  }

  return <ChartErrorStatus size="medium" className="min-h-[540px]" />;
}
