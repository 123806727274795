import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { LoadingShimmer } from "@/view/components/loading-shimmer";
import { paths } from "@/view/routes";

import { LineMainLayout } from "../line-id/line-main-layout";
import { LineSelect } from "../line-id/line-select";
import { BirdEyeViewCanvas } from "./bev-canvas";
import {
  BirdEyeViewFilters,
  BirdEyeViewFiltersProvider,
} from "./bev-filters-provider";
import { VirtualSensorErrorBoundary } from "./virtual-sensor-error-boundary";

export function LineBirdEyeViewPage() {
  return (
    <BirdEyeViewFiltersProvider>
      <LineMainLayout
        filters={
          <>
            <div className="grow">
              <LineSelect path={paths.lineBirdEyeViewPath} />
            </div>
            <BirdEyeViewFilters />
          </>
        }
      >
        <Suspense fallback={<LoadingShimmer className="aspect-video" />}>
          <VirtualSensorErrorBoundary>
            <BirdEyeViewCanvas />
          </VirtualSensorErrorBoundary>
        </Suspense>
      </LineMainLayout>

      <Outlet />
    </BirdEyeViewFiltersProvider>
  );
}
