import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useAnalytics } from "./analytics-provider";

/**
 * Notifies our analytics platform of a location change.
 */
export function LocationChangeNotifier({
  children,
}: {
  children: React.ReactNode;
}) {
  const analytics = useAnalytics();
  const location = useLocation();

  useEffect(() => {
    analytics.collect("page_entered");
  }, [location, analytics]);

  return <>{children}</>;
}
