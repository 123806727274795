import { i18n, Messages } from "@lingui/core";
import type { Locale } from "date-fns";
import { de, enUS, pl } from "date-fns/locale";

const SELECTED_LOCALE_STORAGE_KEY = "lang";
const locales = {
  en: "English",
  de: "Deutsch",
  pl: "Polski",
};

/**
 * Detects the locale to be used for internationalization.
 *
 * @returns The detected locale as a string.
 */
function detectLocale(): string {
  let locale: string;
  const availableLocales = Object.keys(locales);

  locale = window.localStorage.getItem(SELECTED_LOCALE_STORAGE_KEY) ?? "";

  if (availableLocales.includes(locale)) {
    return locale;
  }

  locale =
    // check if it's a string just in case
    typeof window.navigator.language == "string"
      ? window.navigator.language.slice(0, 2)
      : "";

  return availableLocales.includes(locale) ? locale : "en";
}

/**
 * Returns the appropriate DateFns locale based on the provided locale string.
 * If the locale is not supported, the default English (enUS) locale is returned.
 * @param locale - The locale string.
 * @returns The DateFns locale.
 */
function localeForDateFns(): Locale {
  switch (i18n.locale) {
    case "de":
      return de;
    case "pl":
      return pl;
    default:
      return enUS;
  }
}

/**
 * Activates the specified locale dynamically by loading the corresponding messages file.
 * If the messages file for the specified locale is not found, falls back to the default "en" locale.
 * Updates the selected locale in the local storage for persistence.
 *
 * @param locale - The locale to load and activate.
 * @returns A promise that resolves once the locale is activated.
 */
async function lazyLoadLocale(locale: string) {
  let messages: Messages;

  try {
    messages = (await import(`./locales/${locale}.po`)).messages;
  } catch (_error) {
    locale = "en";
    messages = (await import(`./locales/${locale}.po`)).messages;
  }

  i18n.load(locale, messages);
  i18n.activate(locale);

  // when changing locale, we need to update the local storage
  // so that the selected locale is remembered on page reload
  window.localStorage.setItem(SELECTED_LOCALE_STORAGE_KEY, locale);
}

export { detectLocale, i18n, lazyLoadLocale, localeForDateFns, locales };
