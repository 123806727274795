import { useLingui } from "@lingui/react";
import { BarDatum, BarItem, BarItemProps, ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

import { StationId } from "@/domain/station";
import { ActivityByStation } from "@/domain/statistics";
import { formatDuration } from "@/view/utils";

import { useTags } from "../../line-id/use-tags";
import { ChartLegend } from "./chart-legend";
import { ChartWrapper } from "./chart-wrapper";
import {
  getActivityKeysForChartType,
  getColor,
  getLegendName,
  getValueForChartType,
} from "./helpers";
import { useStationName } from "./use-station-name";

export function ActivityByStationChart({
  data,
  height,
  onBarClick,
}: {
  data: Array<ActivityByStation>;
  height: number;
  onBarClick?: (label: StationId) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const getStationName = useStationName();
  const [hiddenLegends, setHiddenLegends] = useState<Array<string>>([]);
  const keys = getActivityKeysForChartType();

  const chartData = data.map((it) =>
    getValueForChartType("combined", it, hiddenLegends)
  );

  return (
    <ChartWrapper
      height={height}
      controls={
        <ChartLegend
          dataType="activity"
          chartType="combined"
          legends={keys}
          hiddenLegends={hiddenLegends}
          onLegendClick={(legend) =>
            setHiddenLegends((it) => {
              if (it.includes(legend)) {
                return it.filter((l) => l !== legend);
              } else {
                return [...it, legend];
              }
            })
          }
        />
      }
    >
      <ResponsiveBar
        data={chartData}
        keys={keys}
        indexBy="stationId"
        groupMode="stacked"
        onClick={(d) => onBarClick?.(d.data.stationId as StationId)}
        // styling
        enableLabel
        barComponent={CustomBarItem}
        margin={{ top: 60, right: 0, bottom: 120, left: 150 }}
        colors={(d) => getColor(`${d.id}`, "combined", "activity", tagsStore)}
        padding={0.6}
        labelSkipHeight={16}
        borderRadius={3}
        valueFormat={formatDuration}
        labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        tooltipLabel={(d) => {
          const stationName = getStationName(d.data.stationId as StationId);
          const legendName = getLegendName(
            `${d.id}`,
            "combined",
            "activity",
            tagsStore,
            i18n
          );
          return `${stationName} - ${legendName}`;
        }}
        axisLeft={{
          legend: i18n.t("lineOverviewChartTabActivity"),
          legendPosition: "middle",
          legendOffset: -90,
          format: formatDuration,
        }}
        axisBottom={{
          tickPadding: 12,
          tickRotation: -14,
          truncateTickAt: 10,
          format: (value) => getStationName(value as StationId),
        }}
        theme={{
          grid: {
            line: {
              stroke: "#e5eaf0",
            },
          },
        }}
      />
    </ChartWrapper>
  );
}

function CustomBarItem<RawDatum extends BarDatum>(
  props: BarItemProps<RawDatum>
) {
  return (
    <g className="cursor-pointer">
      <BarItem {...props} />
    </g>
  );
}
