import { Slot } from "@radix-ui/react-slot";
import { type VariantProps } from "class-variance-authority";
import { forwardRef } from "react";

import { AnalyticsEventName } from "@/domain/analytics";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { cn } from "@/view/utils";

import { buttonVariants } from "./button-variants";

interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement | HTMLElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  analyticsEvent: AnalyticsEventName;
  analyticsData?: Record<string, unknown>;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      analyticsEvent,
      analyticsData,
      onClick,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const analytics = useAnalytics();
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        onClick={(e) => {
          if (analyticsEvent) {
            analytics.collect(analyticsEvent, analyticsData);
          }
          onClick?.(e);
        }}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";
