import { Plural, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ResponsiveBar } from "@nivo/bar";

import { AreaId } from "@/domain/areas-of-interests";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";
import { ChartCard } from "@/view/components/chart/chart-card";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { useSelectedArea } from "@/view/pages/line-id-area-id/selected-area-provider";

import { useAreaOfInterestsStatistics } from "./use-area-of-interests-statistics";

const numberOfWorkersColorsMap = {
  1: "#2CD9FF",
  2: "#2C98FF",
  3: "#01519E",
  4: "#702CFF",
} as const;
type NumberOfWorkers = keyof typeof numberOfWorkersColorsMap;

function useNumberOfWorkersChartData({
  shiftIds,
  dateRange,
  areaId,
}: {
  shiftIds: Array<ShiftId>;
  dateRange: DateRangeFilter;
  areaId: AreaId;
}) {
  return useAreaOfInterestsStatistics<Array<Record<string, string | number>>>(
    {
      dateRange,
      areaId,
      shiftGroupIds: shiftIds,
    },
    {
      select: (data) =>
        data.avgWorkerCount.reduce<Array<Record<string, string | number>>>(
          (acc, item) => {
            const existingItem = acc.find((i) => i.time === item.time);

            if (existingItem) {
              existingItem[item.workerCount] = (
                item.timeSpentRatio * 100
              ).toFixed(1);
            } else {
              const newItem = {
                time: item.time,
                [item.workerCount]: (item.timeSpentRatio * 100).toFixed(1),
              };
              acc.push(newItem);
            }

            return acc;
          },
          []
        ),
    }
  );
}

const formatTime = (value: string) => {
  const [hours, minutes] = value.split(":");

  return `${hours}:${minutes}`;
};

export function NumberOfWorkersChart() {
  const { i18n } = useLingui();
  const areaId = useSelectedArea();
  const dateRange = useSelectedDateRange();
  const shiftIds = useSelectedShiftIds();

  const query = useNumberOfWorkersChartData({
    areaId,
    dateRange,
    shiftIds,
  });

  return (
    <ChartCard
      query={query}
      title={i18n.t("Average number of workers in area")}
      height={350}
    >
      {(data) => {
        const keys = Array.from(
          new Set(
            data.reduce<Array<string>>(
              (acc, item) =>
                acc.concat(Object.keys(item).filter((key) => !isNaN(+key))),
              []
            )
          )
        );

        return (
          <ResponsiveBar
            enableGridY
            animate
            motionConfig="stiff"
            enableLabel={false}
            gridYValues={[0, 33, 66, 100]}
            indexBy="time"
            data={data}
            keys={keys}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            margin={{ top: 60, right: 0, bottom: 50, left: 70 }}
            colors={({ id }) => numberOfWorkersColorsMap[id as NumberOfWorkers]}
            maxValue={100}
            padding={0.1}
            axisLeft={{
              tickValues: [0, 33, 66, 100],
              format: (value) => `${value}%`,
              tickSize: 0,
            }}
            axisBottom={{
              tickPadding: 12,
              tickSize: 0,
              truncateTickAt: 10,
              format: formatTime,
            }}
            theme={{
              grid: {
                line: {
                  stroke: "#e5eaf0",
                },
              },
            }}
            tooltip={({ id, value }) => {
              return (
                <div className="bg-white p-2 border border-gray-200">
                  <Trans>
                    <Plural
                      value={id as NumberOfWorkers}
                      one="# worker"
                      other="# workers"
                    />
                    : {value}%
                  </Trans>
                </div>
              );
            }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "top-left",
                direction: "row",
                justify: false,
                translateX: -30,
                translateY: -50,
                itemsSpacing: 2,
                itemWidth: 50,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 10,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        );
      }}
    </ChartCard>
  );
}
