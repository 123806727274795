import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  OnboardingGuideKey,
  OnboardingGuides,
} from "@/domain/user-preferences";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@/view/components";
import { useApiClient } from "@/view/providers/api-client-provider";
import { cn } from "@/view/utils";

const ONBOARDING_GUIDE_KEY = "onboarding-guide";

type OnboardingSlide = {
  title: string;
  description: string;
  imgSrc: string;
};

type Actions = {
  close: (keys: Array<OnboardingGuideKey>) => void;
};

const OnboardingGuideActionsContext = createContext<Actions | null>(null);

function useActions() {
  const actions = useContext(OnboardingGuideActionsContext);
  if (!actions) {
    throw new Error("useActions must be used within a OnboardingGuideProvider");
  }
  return actions;
}

function updateGuides(
  keys: Array<OnboardingGuideKey>,
  prevData?: OnboardingGuides
) {
  const onboardingGuides: OnboardingGuides = {
    ...(prevData ?? {
      shiftsTargets: false,
    }),
  };
  for (const key of keys) {
    onboardingGuides[key] = true;
  }
  return onboardingGuides;
}

export function OnboardingGuideProvider({ children }: PropsWithChildren) {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const query = useQuery<OnboardingGuides>({
    queryKey: [ONBOARDING_GUIDE_KEY],
    queryFn: async () => {
      const data = await apiClient.getUserPreferences();
      return data.onboardingGuides;
    },
  });
  const mutation = useMutation({
    mutationFn: async (keys: Array<OnboardingGuideKey>) => {
      return apiClient.saveUserPreferences({
        onboardingGuides: updateGuides(keys, query.data),
      });
    },
    onMutate: async (keys) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [ONBOARDING_GUIDE_KEY] });
      // Get the previous value
      const prevData = queryClient.getQueryData<OnboardingGuides>([
        ONBOARDING_GUIDE_KEY,
      ]);
      // Optimistically update to the new value
      queryClient.setQueryData(
        [ONBOARDING_GUIDE_KEY],
        updateGuides(keys, prevData),
        { updatedAt: Date.now() }
      );
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        [ONBOARDING_GUIDE_KEY],
        response.onboardingGuides
      );
    },
  });
  const actions = useMemo(() => {
    return {
      close: (keys: Array<OnboardingGuideKey>) => {
        mutation.mutate(keys);
      },
    };
  }, [mutation]);

  const guides = query.data ?? {};
  const guidesToShow: Array<OnboardingGuideKey> = [];

  for (const key in guides) {
    const wasShown = (guides as OnboardingGuides)[key as OnboardingGuideKey];
    if (!wasShown) {
      guidesToShow.push(key as OnboardingGuideKey);
    }
  }

  return (
    <OnboardingGuideActionsContext.Provider value={actions}>
      <>{children}</>
      {guidesToShow.length > 0 && (
        <Dialog
          open
          onOpenChange={(open) => {
            if (open) return;
            actions.close(["shiftsTargets"]);
          }}
        >
          <DialogPortal>
            <DialogOverlay className="z-20" />
            <DialogContent
              className="max-w-xl h-auto"
              // prevents closing the dialog with ESC key or clicking outside
              onEscapeKeyDown={(e) => e.preventDefault()}
              onInteractOutside={(e) => e.preventDefault()}
            >
              <OnboardingGuide guidesToShow={guidesToShow} />
            </DialogContent>
          </DialogPortal>
        </Dialog>
      )}
    </OnboardingGuideActionsContext.Provider>
  );
}

function OnboardingGuide({
  guidesToShow,
}: {
  guidesToShow: Array<OnboardingGuideKey>;
}) {
  const actions = useActions();
  const { i18n } = useLingui();
  const [currentIdx, setCurrentIdx] = useState(0);
  const slides = useMemo(() => {
    const guides = getOnboardingGuides(i18n);
    return guidesToShow.flatMap((key) => guides[key]);
  }, [i18n, guidesToShow]);

  const currentSlide = slides[currentIdx];
  const isLastSlide = currentIdx === slides.length - 1;

  return (
    <section className="p-6">
      <div className="mb-6">
        <img
          className="w-full object-cover aspect-video bg-gray-50 rounded-lg"
          src={currentSlide.imgSrc}
          alt={currentSlide.title}
        />
      </div>
      <div className="max-w-md mx-auto mb-6">
        <h3 className="text-center text-xl text-black font-semibold leading-loose">
          {currentSlide.title}
        </h3>
        <p className="text-center text-base text-brand-gray-5">
          {currentSlide.description}
        </p>
      </div>
      <ul className="flex gap-2 items-stretch justify-center mb-8">
        {slides.map((_, index) => (
          <li key={index}>
            <button
              className={cn("w-2 h-2 rounded-full bg-brand-gray-3", {
                "bg-brand-blue-1": index === currentIdx,
              })}
              onClick={() => setCurrentIdx(index)}
            />
          </li>
        ))}
      </ul>
      <footer className="flex gap-2 justify-between">
        <button
          className={cn(
            "basis-1/2 border rounded-md p-2",
            "text-brand-gray-5 hover:text-brand-black",
            "hover:bg-brand-gray-1 transition-colors"
          )}
          onClick={() => actions.close(guidesToShow)}
        >
          {i18n.t("Skip")}
        </button>
        <button
          className={cn(
            "basis-1/2 border rounded-md p-2",
            "text-brand-white transition-colors",
            "bg-brand-blue-1 hover:bg-brand-blue-2"
          )}
          onClick={() => {
            if (isLastSlide) {
              actions.close(guidesToShow);
              return;
            }
            setCurrentIdx((prev) => prev + 1);
          }}
        >
          {isLastSlide ? i18n.t("Close") : i18n.t("Next")}
        </button>
      </footer>
    </section>
  );
}

import shiftsTargetsImgOne from "@/view/assets/onboarding-guides/shifts-targets-1.png";
import shiftsTargetsImgTwo from "@/view/assets/onboarding-guides/shifts-targets-2.png";
import shiftsTargetsImgThree from "@/view/assets/onboarding-guides/shifts-targets-3.png";
import shiftsTargetsImgFour from "@/view/assets/onboarding-guides/shifts-targets-4.png";
import shiftsTargetsImgFive from "@/view/assets/onboarding-guides/shifts-targets-5.png";

function getOnboardingGuides(
  i18n: I18n
): Record<OnboardingGuideKey, Array<OnboardingSlide>> {
  return {
    shiftsTargets: [
      {
        title: i18n.t("Introducing: Targets"),
        description: i18n.t(
          "Targets allow you to change and schedule targets for output and more."
        ),
        imgSrc: shiftsTargetsImgOne,
      },
      {
        title: i18n.t("Set targets daily"),
        description: i18n.t(
          "You can set the output target, target cycle time and say how many people you want to assign."
        ),
        imgSrc: shiftsTargetsImgTwo,
      },
      {
        title: i18n.t("Set shifts & breaks"),
        description: i18n.t(
          "This makes data more accurate as it considers breaks when calculating activity."
        ),
        imgSrc: shiftsTargetsImgThree,
      },
      {
        title: i18n.t("Schedule ahead"),
        description: i18n.t(
          "Set targets once, and they will be set until you change them again."
        ),
        imgSrc: shiftsTargetsImgFour,
      },
      {
        title: i18n.t("Target prediction"),
        description: i18n.t(
          "Always know when to adjust your daily production speed ahead of time."
        ),
        imgSrc: shiftsTargetsImgFive,
      },
    ],
  };
}
