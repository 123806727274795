import { isAfter } from "date-fns";
import { createContext, useContext } from "react";

import { DateRangeFilter, dateRangeOptions } from "@/domain/common/time-filter";
import { padNumberWithZero } from "@/view/utils";

const defaultValue: DateRangeFilter = {
  values: dateRangeOptions[0].range, // today
  excluded: [],
};

export const SelectedDateRangeContext =
  createContext<DateRangeFilter>(defaultValue);

export function useSelectedDateRange() {
  const selectedDateRange = useContext(SelectedDateRangeContext);
  if (!selectedDateRange) {
    throw new Error(
      "useSelectedDateRange must be used inside SelectedDateRangeProvider"
    );
  }
  return selectedDateRange;
}

const searchParamKey = "dateRange";

export function parseDateRangeFromSearchParams(searchParams: URLSearchParams) {
  const maybeDateRange = searchParams.get(searchParamKey);
  const dateRange = decodeDateRange(maybeDateRange) ?? defaultValue;

  if (isAfter(dateRange.values.start, dateRange.values.end)) {
    return {
      values: {
        start: dateRange.values.end,
        end: dateRange.values.start,
      },
      excluded: dateRange.excluded,
    };
  }
  return dateRange;
}

export function encodeDateRangeToSearchParams(
  searchParams: URLSearchParams,
  dateRange: DateRangeFilter
) {
  searchParams.set(searchParamKey, encodeDateRange(dateRange));
  return searchParams;
}

export function encodeDateRange(dateRange: DateRangeFilter) {
  function encodeDate(date: Date) {
    const year = date.getFullYear();
    const month = padNumberWithZero(date.getMonth() + 1);
    const day = padNumberWithZero(date.getDate());
    return `${year}${month}${day}`;
  }

  return [
    encodeDate(dateRange.values.start),
    encodeDate(dateRange.values.end),
    `excl${dateRange.excluded.join("")}`,
  ].join("_");
}

function decodeDateRange(
  dateRange: string | null | undefined
): DateRangeFilter | null | undefined {
  if (
    dateRange == null ||
    dateRange === "" ||
    typeof dateRange == "undefined"
  ) {
    return null;
  }

  const excluded: DateRangeFilter["excluded"] = [];
  let dates: Array<Date> = [];

  for (const part of dateRange.split("_")) {
    if (part.startsWith("excl")) {
      excluded.push(...new Set(part.slice(4).split("").map(Number)));
    } else if (part.length === 8) {
      const year = Number(part.slice(0, 4));
      const month = Number(part.slice(4, 6)) - 1;
      const day = Number(part.slice(6, 8));
      dates.push(new Date(year, month, day));
    }
  }

  dates = dates.sort((a, b) => a.getTime() - b.getTime());

  return {
    excluded,
    values: {
      start: dates[0],
      end: dates[1],
    },
  };
}
