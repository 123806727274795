import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import {
  OverviewDrawer,
  OverviewDrawerContent,
  OverviewDrawerHeader,
  Tabs,
  TabsList,
  TabsTrigger,
} from "@/view/components";
import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "@/view/pages/line-id/use-selected-date-range";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "@/view/pages/line-id/use-selected-shift-ids";
import { useSelectedStationDetails } from "@/view/pages/line-id-reporting-station-id/use-selected-station-details";
import { cn } from "@/view/utils";

import { useSelectedLine } from "../line-id/use-selected-line";

enum DrawerTab {
  Overview = "overview",
  Videos = "videos",
}

const drawerTabs = [DrawerTab.Overview, DrawerTab.Videos];

export function StationDetailsDrawer({ onClose }: { onClose: () => void }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useLingui();
  const selectedLine = useSelectedLine();
  const selectedStation = useSelectedStationDetails();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();

  const stationName = selectedStation?.name || "---";
  const lineName = selectedLine?.name || "---";

  const selectedTab = location.pathname.split("/").pop() as DrawerTab;

  const navigateToTab = (tab: DrawerTab) => {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);

    navigate({
      pathname: tab,
      search: searchParams.toString(),
    });
  };

  return (
    <OverviewDrawer onClose={onClose}>
      <Tabs
        className="h-full flex flex-col"
        value={selectedTab}
        onValueChange={(value) => navigateToTab(value as DrawerTab)}
      >
        <OverviewDrawerHeader title={stationName} subTitle={lineName}>
          <TabsList className="justify-start gap-0.5 border rounded-lg bg-brand-gray-1 p-0.5">
            {drawerTabs.map((tab) => (
              <TabsTrigger
                key={tab}
                value={tab}
                className={cn(
                  "rounded-md px-3 py-2.5 border border-transparent",
                  "data-[state=active]:bg-brand-white",
                  "data-[state=active]:text-brand-black",
                  "data-[state=active]:border-brand-gray-2",
                  "data-[state=active]:shadow-sm"
                )}
              >
                {getMainTabTitle(tab, i18n)}
              </TabsTrigger>
            ))}
          </TabsList>
        </OverviewDrawerHeader>

        <OverviewDrawerContent>
          <Outlet />
        </OverviewDrawerContent>
      </Tabs>
    </OverviewDrawer>
  );
}

function getMainTabTitle(tab: DrawerTab, i18n: I18n) {
  switch (tab) {
    case "overview":
      return i18n.t("stationDetailsTabOverview");
    case "videos":
      return i18n.t("stationDetailsTabVideos");
  }
}
