import { useLingui } from "@lingui/react";
import { lazy, PropsWithChildren, Suspense } from "react";

import { paths } from "@/view/routes";

import { LineIdErrorBoundary } from "../line-id/error-boundary";
import { LineSelect } from "../line-id/line-select";
import { NotificationSettingsLoadingFallback } from "./loading-fallback";
import { NotificationSettingsLayout } from "./notification-settings-layout";

const NotificationRulesProvider = lazy(() =>
  import("./notification-rules-provider").then((module) => ({
    default: module.NotificationRulesProvider,
  }))
);

const NotificationSettings = lazy(() =>
  import("./notification-settings").then((module) => ({
    default: module.NotificationSettings,
  }))
);

export function LineNotificationSettingsPage() {
  return (
    <div className="max-w-7xl mx-auto">
      <LineSelect path={paths.lineSettingsNotificationsPath} />
      <Suspense fallback={<NotificationSettingsLoadingFallback />}>
        <NotificationSettingsLayout>
          <ErrorBoundary>
            <NotificationRulesProvider>
              <NotificationSettings />
            </NotificationRulesProvider>
          </ErrorBoundary>
        </NotificationSettingsLayout>
      </Suspense>
    </div>
  );
}

function ErrorBoundary({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  return (
    <LineIdErrorBoundary
      message={i18n.t("Something went wrong when fetching shifts data.")}
    >
      {children}
    </LineIdErrorBoundary>
  );
}
