import { generatePath, useNavigate } from "react-router-dom";

import { SelectedAreaProvider } from "@/view/pages/line-id-area-id/selected-area-provider";
import { SelectedStationDetailsProvider } from "@/view/pages/line-id-reporting-station-id/selected-station-provider";
import { StationReportingFiltersProvider } from "@/view/pages/line-id-reporting-station-id/station-reporting-filters-provider";
import { paths } from "@/view/routes";

import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "../line-id/use-selected-shift-ids";
import { StationDetailsDrawer } from "./station-details-drawer";

export function LineBirdEyeViewStationDetails() {
  const navigate = useNavigate();
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();

  function closeDrawer() {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);
    navigate({
      pathname: generatePath(paths.lineBirdEyeViewPath, {
        lineId: line.id,
      }),
      search: searchParams.toString(),
    });
  }

  return (
    <SelectedAreaProvider>
      <SelectedStationDetailsProvider>
        <StationReportingFiltersProvider>
          <StationDetailsDrawer onClose={closeDrawer} />
        </StationReportingFiltersProvider>
      </SelectedStationDetailsProvider>
    </SelectedAreaProvider>
  );
}
