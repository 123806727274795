import type { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { AlertOctagonIcon } from "lucide-react";

import {
  ApiError,
  BadRequestError,
  BaseError,
  NetworkError,
  NotFoundError,
  ResponseParsingError,
  UnauthorizedError,
} from "@/domain/common/errors";
import { Button } from "@/view/components";

export function ErrorStatus({
  error,
  onRetry,
}: {
  error: BaseError;
  onRetry?: () => void;
}) {
  const { i18n } = useLingui();
  const { title, message } = getErrorMessages(error, i18n);
  return (
    <div className="flex flex-col gap-12 p-6">
      <AlertOctagonIcon className="mx-auto w-44 h-44 text-brand-black opacity-10" />
      <div className="text-center">
        <h1 className="mb-2 text-2xl text-brand-black uppercase font-bold">
          {title}
        </h1>
        <p className="text-brand-gray-5">{message}</p>
        {error instanceof UnauthorizedError && (
          <Button
            className="mt-4 px-4 bg-brand-gray-1"
            analyticsEvent="error_status_reload_button_clicked"
            onClick={() => {
              window.sessionStorage.clear();
              window.location.reload();
            }}
          >
            {i18n.t("errorReloadButton")}
          </Button>
        )}
        {onRetry && (
          <Button
            className="mt-4 px-4 bg-brand-gray-1"
            analyticsEvent="error_retry_clicked"
            onClick={onRetry}
          >
            {i18n.t("Try again")}
          </Button>
        )}
      </div>
    </div>
  );
}

function getErrorMessages(error: BaseError, i18n: I18n) {
  switch (true) {
    case error instanceof UnauthorizedError:
      return {
        title: i18n.t("errorUnauthorizedTitle"),
        message: i18n.t("errorUnauthorizedMessage"),
      };
    case error instanceof NetworkError:
      return {
        title: i18n.t("errorNetworkTitle"),
        message: i18n.t("errorNetworkMessage"),
      };
    case error instanceof NotFoundError:
      return {
        title: i18n.t("errorNotFoundTitle"),
        message: i18n.t("errorMetricsNotFoundMessage"),
      };
    case error instanceof BadRequestError:
      return {
        title: i18n.t("errorBadRequestTitle"),
        message: i18n.t("errorBadRequestMessage"),
      };
    case error instanceof ApiError:
      return {
        title: i18n.t("errorApiTitle"),
        message: i18n.t("errorApiMessage"),
      };
    case error instanceof ResponseParsingError:
      return {
        title: i18n.t("errorParsingResponseTitle"),
        message: i18n.t("errorParsingResponseMessage"),
      };
    default:
      return {
        title: i18n.t("errorUnknownTitle"),
        message: i18n.t("errorUnknownMessage"),
      };
  }
}
