import { CSSProperties } from "react";

import { cn } from "../utils";

export function DeltiaIcon({ className }: { className?: string }) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 428 428"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="428" height="428" rx="47.1193" fill="currentColor" />
      <path
        d="M208.1 86.873C234.69 86.873 257.927 92.1433 277.81 102.684C297.693 113.224 313.025 128.076 323.805 147.241C334.824 166.166 340.334 188.085 340.334 212.999C340.334 237.673 334.824 259.592 323.805 278.757C313.025 297.921 297.574 312.773 277.451 323.314C257.568 333.854 234.451 339.124 208.1 339.124H113.595V86.873H208.1ZM204.147 285.943C227.384 285.943 245.47 279.595 258.406 266.899C271.342 254.202 277.81 236.236 277.81 212.999C277.81 189.762 271.342 171.675 258.406 158.739C245.47 145.804 227.384 139.336 204.147 139.336H175.041V285.943H204.147Z"
        fill="white"
      />
    </svg>
  );
}

export function CalendarIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.78571 0.9375C6.78571 0.417969 6.30804 0 5.71429 0C5.12054 0 4.64286 0.417969 4.64286 0.9375V2.5H2.85714C1.28125 2.5 0 3.62109 0 5V5.625V7.5V17.5C0 18.8789 1.28125 20 2.85714 20H17.1429C18.7188 20 20 18.8789 20 17.5V7.5V5.625V5C20 3.62109 18.7188 2.5 17.1429 2.5H15.3571V0.9375C15.3571 0.417969 14.8795 0 14.2857 0C13.692 0 13.2143 0.417969 13.2143 0.9375V2.5H6.78571V0.9375ZM2.14286 7.5H17.8571V17.5C17.8571 17.8438 17.5357 18.125 17.1429 18.125H2.85714C2.46429 18.125 2.14286 17.8438 2.14286 17.5V7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ChevronLeftIcon({ className }: { className?: string }) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.89571 1.17092C6.95473 1.25222 6.94184 1.36514 6.86602 1.43104L2.47902 5.2444C2.02005 5.64335 2.02046 6.35618 2.47988 6.7546L6.86329 10.556C6.9408 10.6232 6.95449 10.7384 6.89491 10.8219V10.8219C6.82678 10.9174 6.69123 10.9336 6.60258 10.8567L1.87194 6.75586C1.41187 6.35703 1.41198 5.6431 1.87218 5.24442L6.61198 1.1383C6.69784 1.06392 6.82897 1.079 6.89571 1.17092V1.17092Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ChevronRightIcon({ className }: { className?: string }) {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.10429 1.17092C1.04527 1.25222 1.05816 1.36514 1.13398 1.43104L5.52098 5.2444C5.97995 5.64335 5.97954 6.35618 5.52012 6.7546L1.13671 10.556C1.0592 10.6232 1.04551 10.7384 1.10509 10.8219V10.8219C1.17322 10.9174 1.30877 10.9336 1.39742 10.8567L6.12806 6.75586C6.58813 6.35703 6.58802 5.6431 6.12782 5.24442L1.38802 1.1383C1.30216 1.06392 1.17103 1.079 1.10429 1.17092V1.17092Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function SpinnerIcon({ className }: { className?: string }) {
  return (
    <svg
      aria-hidden="true"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        "w-8 h-8 animate-spin text-brand-gray-2 fill-brand-blue-1",
        className
      )}
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  );
}

export function AnalyzeCtxIcon({ className }: { className?: string }) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("text-brand-blue-1 fill-brand-blue-1", className)}
    >
      <path
        d="M12.8745 13.2764C11.6173 14.3287 9.9981 14.9636 8.23265 14.9636C4.2402 14.9636 1 11.7234 1 7.7318C1 3.7402 4.2402 0.5 8.23265 0.5C12.2234 0.5 15.4645 3.7402 15.4645 7.7318C15.4645 9.4981 14.8295 11.1182 13.7772 12.3737L17.8139 16.4111C17.938 16.5352 18 16.6985 18 16.8625C18 17.3615 17.5436 17.5 17.3625 17.5C17.1993 17.5 17.0361 17.438 16.9111 17.313L12.8745 13.2764ZM8.23265 1.77585C4.94485 1.77585 2.2767 4.44485 2.2767 7.7318C2.2767 11.0188 4.94485 13.6877 8.23265 13.6877C11.5188 13.6877 14.1886 11.0188 14.1886 7.7318C14.1886 4.44485 11.5188 1.77585 8.23265 1.77585ZM6.375 7.725C6.375 6.5 7 5.875 8.225 5.875C9.5 5.875 10.075 6.45 10.075 7.725C10.075 9 9.5 9.575 8.225 9.575C6.95 9.575 6.375 8.95 6.375 7.725Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.7"
      />
    </svg>
  );
}

export function BellIcon({
  outlined = false,
  className,
}: {
  outlined?: boolean;
  className?: string;
}) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className={cn("text-brand-blue-1", className)}
    >
      <path
        d={
          outlined
            ? "M20.6338 14.4714L20.2867 14.09C18.8232 12.4723 18.0117 10.3905 18.0117 8.22603V7.36207C18.0117 3.80511 15.4317 0.836369 12.0075 0.156226C10.9348 -0.0568675 10.0299 -0.0472273 9.00538 0.156226C5.58112 0.836369 3.0012 3.80511 3.0012 7.36207V8.22603C3.0012 10.3859 2.18969 12.4723 0.726172 14.09L0.379055 14.4714C-0.0149702 14.9034 -0.108786 15.5238 0.130444 16.0523C0.369673 16.5808 0.909112 16.9209 1.50015 16.9209H19.5127C20.1037 16.9209 20.6385 16.5808 20.8824 16.0523C21.1263 15.5238 21.0278 14.9034 20.6338 14.4714ZM13.5085 18.3914H7.50433C7.50433 18.5703 7.5208 18.7474 7.55294 18.9209C7.65441 19.4684 7.91208 19.9794 8.3012 20.3914C8.32738 20.4192 8.35415 20.4464 8.38151 20.4732C8.9444 21.0247 9.709 21.3326 10.5064 21.3326C11.3039 21.3326 12.0685 21.0247 12.6313 20.4732C12.6587 20.4464 12.6855 20.4192 12.7117 20.3914C13.1008 19.9794 13.3584 19.4684 13.4599 18.9209C13.4921 18.7474 13.5085 18.5703 13.5085 18.3914ZM9.39502 2.1179C10.1689 1.96422 10.809 1.95722 11.6178 2.11789C14.1519 2.62123 16.0117 4.80549 16.0117 7.36207V8.22603C16.0117 10.6702 16.8516 13.0217 18.369 14.9209H2.64375C4.16151 13.0208 5.0012 10.6656 5.0012 8.22603V7.36207C5.0012 4.80551 6.86096 2.62126 9.39502 2.1179Z"
            : "M9.00538 0.156116C5.58112 0.836263 3.0012 3.80502 3.0012 7.362V8.22597C3.0012 10.3859 2.18969 12.4723 0.726172 14.0899L0.379055 14.4714C-0.0149702 14.9034 -0.108786 15.5238 0.130444 16.0523C0.369673 16.5808 0.909112 16.9208 1.50015 16.9208H19.5127C20.1037 16.9208 20.6385 16.5808 20.8824 16.0523C21.1263 15.5238 21.0278 14.9034 20.6338 14.4714L20.2867 14.0899C18.8232 12.4723 18.0117 10.3905 18.0117 8.22597V7.362C18.0117 3.80502 15.4317 0.836263 12.0075 0.156116C10.9348 -0.0569787 10.0299 -0.0473384 9.00538 0.156116ZM12.6313 20.4732C13.1942 19.9217 13.5085 19.1727 13.5085 18.3914H10.5064H7.50433C7.50433 19.1727 7.81862 19.9217 8.38151 20.4732C8.9444 21.0247 9.709 21.3326 10.5064 21.3326C11.3039 21.3326 12.0685 21.0247 12.6313 20.4732Z"
        }
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
      />
    </svg>
  );
}

export function CoffeeBreakIcon({
  className,
  style,
}: {
  className?: string;
  style?: CSSProperties;
}) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn("text-brand-blue-1", className)}
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 2V2.21C3.75 2.37611 3.81589 2.53556 3.93267 2.65233L4.34767 3.06733C4.60589 3.32556 4.75 3.67611 4.75 4.04V4.25H4V4.04C4 3.87389 3.93411 3.71444 3.81733 3.59767L3.40233 3.18267C3.14411 2.92444 3 2.57389 3 2.21V2H3.75ZM5.75 2V2.21C5.75 2.37611 5.81589 2.53556 5.93266 2.65233L6.34766 3.06733C6.60589 3.32556 6.75 3.67611 6.75 4.04V4.25H6V4.04C6 3.87389 5.93411 3.71444 5.81733 3.59767L5.40233 3.18267C5.14411 2.92444 5 2.57389 5 2.21V2H5.75ZM2 5.625C2 5.27982 2.27982 5 2.625 5H9.875C10.2202 5 10.5 5.27982 10.5 5.625V7.375C10.5 8.13439 9.88439 8.75 9.125 8.75H8.75V9.125C8.75 9.88439 8.13439 10.5 7.375 10.5H3.375C2.61561 10.5 2 9.88439 2 9.125V5.625ZM8 5.75H2.75V9.125C2.75 9.47018 3.02982 9.75 3.375 9.75H7.375C7.72018 9.75 8 9.47018 8 9.125V5.75ZM8.75 5.75V8H9.125C9.47018 8 9.75 7.72018 9.75 7.375V5.75H8.75Z"
        fill="#2C98FF"
      />
    </svg>
  );
}
