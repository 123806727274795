import { cn } from "@/view/utils";

export function NumberInput({
  value,
  className,
  step = 1,
  disabled = false,
  formatter = (value) => `${value}`,
  parser = (value) => Number(value),
  onChange,
}: {
  value: number;
  className?: string;
  step?: number;
  disabled?: boolean;
  formatter?: (value: number) => string;
  parser?: (value: string) => number;
  onChange: (value: number) => void;
}) {
  return (
    <input
      className={cn(
        "w-5 border-none leading-none text-right",
        "focus:ring-0 focus:outline-none",
        "disabled:opacity-30 disabled:cursor-not-allowed",
        className
      )}
      disabled={disabled}
      type="text"
      inputMode="numeric"
      value={formatter(value)}
      onChange={(e) => {
        const value = parser(e.target.value);
        if (Number.isNaN(value)) return;
        onChange(value);
      }}
      onKeyDown={(e) => {
        if (e.key === "ArrowUp") {
          e.preventDefault();
          onChange(value + 1 * step);
        }
        if (e.key === "ArrowDown") {
          e.preventDefault();
          onChange(value - 1 * step);
        }
      }}
    />
  );
}
