import { lazy, Suspense } from "react";

import { Card } from "@/view/components";
import { LoadingStatus } from "@/view/components/loading-status";
import { paths } from "@/view/routes";

import { LineMainLayout } from "../line-id/line-main-layout";
import { LineSelect } from "../line-id/line-select";
import { TrendsErrorBoundary } from "./trends-error-boundary";
import {
  TrendsFilters,
  TrendsFiltersProvider,
} from "./trends-filters-provider";

const TrendsStatisticsProvider = lazy(() =>
  import("./trends-statistics-provider").then((module) => ({
    default: module.TrendsStatisticsProvider,
  }))
);

const TrendsStatistics = lazy(() =>
  import("./trends-statistics").then((module) => ({
    default: module.TrendsStatistics,
  }))
);

export function LineTrendsPage() {
  return (
    <TrendsFiltersProvider>
      <LineMainLayout
        filters={
          <>
            <div className="grow">
              <LineSelect path={paths.lineTrendsPath} />
            </div>
            <TrendsFilters />
          </>
        }
      >
        <Card className="relative border shadow-sm min-h-[640px] flex flex-col">
          <Suspense fallback={<LoadingStatus />}>
            <TrendsErrorBoundary>
              <TrendsStatisticsProvider>
                <TrendsStatistics />
              </TrendsStatisticsProvider>
            </TrendsErrorBoundary>
          </Suspense>
        </Card>
      </LineMainLayout>
    </TrendsFiltersProvider>
  );
}
