import { useLingui } from "@lingui/react";
import { BarDatum, BarItem, BarItemProps, ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

import { StationId } from "@/domain/station";
import { CycleCountByStation } from "@/domain/statistics";
import { useSelectedTagIds } from "@/view/pages/line-id/use-selected-tag-ids";
import { useTags } from "@/view/pages/line-id/use-tags";

import { useSelectedProductIds } from "../../line-id/use-selected-product-ids";
import { ChartLegend } from "./chart-legend";
import { ChartType, ChartTypeSelector } from "./chart-type-select";
import { ChartWrapper } from "./chart-wrapper";
import {
  getColor,
  getLegendName,
  getOutputKeysForChartType,
  getValueForChartType,
} from "./helpers";
import { useStationName } from "./use-station-name";

export function OutputByStationChart({
  data,
  height,
  onBarClick,
}: {
  data: Array<CycleCountByStation>;
  height: number;
  onBarClick?: (label: StationId) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const selectedTagIds = useSelectedTagIds();
  const selectedProductIds = useSelectedProductIds();
  const getStationName = useStationName();
  const [hiddenLegends, setHiddenLegends] = useState<Array<string>>([]);
  const [chartType, setChartType] = useState<ChartType>(() => {
    return selectedTagIds.length > 0 ? "compared" : "single";
  });
  const keys = getOutputKeysForChartType(
    chartType,
    selectedTagIds,
    selectedProductIds
  );

  const chartData = data.map((it) =>
    getValueForChartType(chartType, it, hiddenLegends)
  );

  const maxValue = Math.max(
    10,
    ...data.flatMap((it) => {
      return it[chartType]
        .filter((it) => !hiddenLegends.includes(it.key))
        .map((it) => it.value)
        .reduce((a, b) => a + b, 0);
    })
  );

  return (
    <ChartWrapper
      height={height}
      controls={
        <>
          <ChartLegend
            dataType="cycleCount"
            chartType={chartType}
            legends={keys}
            hiddenLegends={hiddenLegends}
            onLegendClick={(legend) =>
              setHiddenLegends((it) => {
                if (it.includes(legend)) {
                  return it.filter((l) => l !== legend);
                } else {
                  return [...it, legend];
                }
              })
            }
          />
          <ChartTypeSelector
            options={
              selectedTagIds.length > 0
                ? ["single", "combined", "compared"]
                : ["single"]
            }
            value={chartType}
            onChange={(type) => {
              setChartType(type);
              setHiddenLegends([]);
            }}
          />
        </>
      }
    >
      <ResponsiveBar
        indexBy="stationId"
        data={chartData}
        keys={keys}
        groupMode={chartType == "compared" ? "grouped" : "stacked"}
        onClick={(d) => onBarClick?.(d.data.stationId as StationId)}
        // styling
        barComponent={CustomBarItem}
        margin={{ top: 60, right: 0, bottom: 120, left: 150 }}
        colors={(d) => getColor(`${d.id}`, chartType, "cycleCount", tagsStore)}
        maxValue={maxValue}
        padding={chartData.length > 10 ? 0.1 : 0.3}
        labelSkipHeight={chartType === "compared" ? 0 : 16}
        labelSkipWidth={chartType === "compared" ? 0 : 56}
        labelPosition={chartType === "compared" ? "end" : "middle"}
        labelOffset={chartType === "compared" ? 16 : 0}
        labelTextColor={(d) => {
          if (d.data.id === "count_difference") return "#475467";
          return chartType === "compared" ? "black" : "white";
        }}
        label={(d) => {
          if (chartType !== "single") {
            return `${d.value}`;
          }

          let total = 0;

          for (const key in d.data) {
            if (key === "stationId") {
              continue;
            }
            if (d.data[key] == null) {
              continue;
            }
            total += d.data[key] as number;
          }

          // this should never happen, but just in case
          if (total === 0 || d.value == null) {
            return `${d.value}`;
          }
          return i18n.t("{value} ({percent})", {
            value: i18n.number(d.value, { style: "decimal" }),
            percent: i18n.number(d.value / total, { style: "percent" }),
          });
        }}
        borderRadius={3}
        enableTotals={chartType !== "compared"}
        enableLabel
        tooltipLabel={(d) => {
          const stationName = getStationName(d.data.stationId as StationId);
          const legendName = getLegendName(
            `${d.id}`,
            chartType,
            "cycleCount",
            tagsStore,
            i18n
          );
          return `${stationName} - ${legendName}`;
        }}
        axisLeft={{
          legend: i18n.t("lineOverviewChartTabOutput"),
          legendPosition: "middle",
          legendOffset: -90,
        }}
        axisBottom={{
          tickPadding: 12,
          tickRotation: -14,
          truncateTickAt: 10,
          format: (value) => getStationName(value as StationId),
        }}
        theme={{
          grid: {
            line: {
              stroke: "#e5eaf0",
            },
          },
        }}
      />
    </ChartWrapper>
  );
}

function CustomBarItem<RawDatum extends BarDatum>(
  props: BarItemProps<RawDatum>
) {
  return (
    <g className="cursor-pointer">
      <BarItem {...props} />
    </g>
  );
}
