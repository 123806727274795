import { useLingui } from "@lingui/react";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  generatePath,
  isRouteErrorResponse,
  Navigate,
  Outlet,
  useParams,
  useRouteError,
} from "react-router-dom";

import { Button } from "@/view/components";
import { LoadingShimmer } from "@/view/components/loading-shimmer";
import { LocationChangeNotifier } from "@/view/providers/location-change-notifier";
import { paths } from "@/view/routes";

import { LineMainLayout } from "./line-main-layout";
import { OnboardingGuideProvider } from "./onboarding-guide-provider";
import { RootLayout } from "./root-layout";
import { useAfterMidnightRefresh } from "./use-midnight-refresh";

const LevelsProvider = lazy(() =>
  import("./levels-provider").then((module) => ({
    default: module.LevelsProvider,
  }))
);
const TagsProvider = lazy(() =>
  import("./tags-provider").then((module) => ({
    default: module.TagsProvider,
  }))
);
const SelectedLineProvider = lazy(() =>
  import("./selected-line-provider").then((module) => ({
    default: module.SelectedLineProvider,
  }))
);

export function LineRootPage() {
  // Let's do a hard refresh after midnight to reset the app state
  useAfterMidnightRefresh();
  return (
    <LocationChangeNotifier>
      <OnboardingGuideProvider>
        <Suspense fallback={<LevelsQueryLoadingFallback />}>
          <QueryErrorResetBoundary>
            {({ reset }) => (
              <ErrorBoundary
                onReset={reset}
                fallbackRender={(props) => (
                  <LevelsQueryErrorFallback
                    onClick={props.resetErrorBoundary}
                  />
                )}
              >
                <LevelsProvider>
                  <SelectedLineProvider>
                    <TagsProvider>
                      <RootLayout hasLoaded>
                        <Outlet />
                      </RootLayout>
                    </TagsProvider>
                  </SelectedLineProvider>
                </LevelsProvider>
              </ErrorBoundary>
            )}
          </QueryErrorResetBoundary>
        </Suspense>
      </OnboardingGuideProvider>
    </LocationChangeNotifier>
  );
}

/**
 * Redirect to the line reporting page.
 */
export function LineCatchAllPage() {
  // SelectedLineProvider makes sure that `lineId` is available
  const { lineId = "" } = useParams<{ lineId: string }>();
  const path = generatePath(paths.lineReportingPath, { lineId });
  return <Navigate to={path} replace />;
}

export function ErrorPage() {
  const { i18n } = useLingui();
  const error = useRouteError();

  return (
    <RootLayout>
      <div className="min-h-[560px] flex items-center justify-center">
        <div className="flex flex-col gap-4 text-brand-black">
          {isRouteErrorResponse(error) ? (
            <>
              <h1 className="text-4xl font-bold">
                {error.status}: {error.statusText}
              </h1>
              {error.data?.message && <p>{error.data.message}</p>}
            </>
          ) : (
            <>
              <h1 className="text-4xl font-bold">{i18n.t("errorPageTitle")}</h1>
              <p>
                <i>{i18n.t("errorPageUnknownError")}</i>
              </p>
            </>
          )}
        </div>
      </div>
    </RootLayout>
  );
}

function LevelsQueryLoadingFallback() {
  return (
    <RootLayout>
      <LineMainLayout
        filters={
          <>
            <LoadingShimmer className="w-96" />
            <LoadingShimmer className="w-96" />
          </>
        }
      >
        <LoadingShimmer className="h-[640px]" />
      </LineMainLayout>
    </RootLayout>
  );
}

function LevelsQueryErrorFallback({ onClick }: { onClick: () => void }) {
  const { i18n } = useLingui();
  return (
    <RootLayout>
      <div className="flex items-center justify-center p-12 min-h-96">
        <Button
          analyticsEvent="error_retry_clicked"
          className="font-semibold text-brand-warning bg-transparent hover:bg-brand-warninig-shade"
          onClick={onClick}
        >
          {i18n.t("lineSelectorRetry")}
        </Button>
      </div>
    </RootLayout>
  );
}
