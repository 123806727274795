import { createContext, useContext } from "react";

import { getAllValidTagIds, TagId } from "@/domain/tag";

export const SelectedProductIdsContext = createContext<Array<TagId>>([]);

export function useSelectedProductIds() {
  const ctx = useContext(SelectedProductIdsContext);
  if (!ctx) {
    throw new Error(
      "useSelectedProductIds must be used inside SelectedProductIdsContext"
    );
  }
  return ctx;
}

const searchParamKey = "productIds";

export function createProductIdValidator(allowedProductIds: Set<TagId>) {
  const allValidTagIds = getAllValidTagIds(allowedProductIds);
  return (productIds: Array<string>) =>
    allValidTagIds(
      productIds.length == 0 ? Array.from(allowedProductIds) : productIds
    )
      // sort to ensure consistent order
      .sort((a, b) => Number(a) - Number(b));
}

export function parseProductIdsFromSearchParams(
  searchParams: URLSearchParams,
  validateProductIds: (productIds: Array<string>) => Array<TagId>
): Array<TagId> {
  const maybeProductIds = searchParams.get(searchParamKey);
  const productIds = decodeProductIds(maybeProductIds) ?? [];
  return validateProductIds(productIds);
}

export function encodeProductIdsToSearchParams(
  searchParams: URLSearchParams,
  productIds: Array<TagId>
) {
  searchParams.set(searchParamKey, encodeProductIds(productIds));
  return searchParams;
}

function encodeProductIds(arr: Array<TagId>) {
  return [...new Set(arr)].join(".");
}

function decodeProductIds(
  arr: string | null | undefined
): Array<string> | undefined {
  return arr?.split(".").filter(Boolean);
}
