import { z } from "zod";

export const paginationSchema = z.object({
  limit: z.number().int().positive().default(12).catch(12),
  offset: z.number().int().nonnegative().default(0).catch(0),
  order: z.enum(["ASC", "DESC"]).default("DESC").catch("DESC"),
});

export type Pagination = z.infer<typeof paginationSchema>;

export function generatePaginationArray(
  totalPages: number,
  currentPage: number
) {
  const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
  const start = Math.max(0, currentPage - 3);
  const end = Math.min(totalPages, currentPage + 3);
  return pages.slice(start, end);
}
