import { useQuery } from "@tanstack/react-query";

import { CameraId } from "@/domain/areas-of-interests";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";
import { useApiClient } from "@/view/providers/api-client-provider";

export const getHeatmapQueryKey = (filters: {
  dateRange: DateRangeFilter;
  selectedShifts: ShiftId[];
  cameraId: CameraId;
}) => ["heatmap", filters.cameraId, filters.dateRange, filters.selectedShifts];

export function useHeatmapDataQuery(filters: {
  dateRange: DateRangeFilter;
  selectedShifts: ShiftId[];
  cameraId: CameraId;
}) {
  const apiClient = useApiClient();
  const { dateRange, selectedShifts, cameraId } = filters;

  return useQuery({
    queryKey: getHeatmapQueryKey(filters),
    queryFn: ({ signal }) => {
      return apiClient.getHeatmapByCameraId(
        { dateRange, cameraId, shiftsIds: selectedShifts },
        { signal }
      );
    },
  });
}
