import {
  addDays,
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  isSameDay,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
  subDays,
  subMonths,
} from "date-fns";
import { z } from "zod";

export const today = startOfToday();
const defaultTimeStart = startOfDay(today);
const defaultTimeEnd = endOfDay(today);
export const defaultStartOfWeek = startOfWeek(today, {
  weekStartsOn: 1, // 1 = Monday
});
const defaultTimeRange = {
  start: defaultTimeStart,
  end: defaultTimeEnd,
};
export const dateRangeOptions = [
  {
    value: "today",
    range: Object.freeze({
      start: today,
      end: today,
    }),
  },
  {
    value: "yesterday",
    range: Object.freeze({
      start: subDays(today, 1),
      end: subDays(today, 1),
    }),
  },
  {
    value: "current_week",
    range: Object.freeze({
      start: defaultStartOfWeek,
      end: today,
    }),
  },
  {
    value: "previous_week",
    range: Object.freeze({
      start: subDays(defaultStartOfWeek, 7),
      end: subDays(defaultStartOfWeek, 1),
    }),
  },
  {
    value: "current_month",
    range: Object.freeze({
      start: startOfMonth(today),
      end: today,
    }),
  },
  {
    value: "previous_month",
    range: Object.freeze({
      start: startOfMonth(subMonths(today, 1)),
      end: endOfMonth(subMonths(today, 1)),
    }),
  },
] as const;

export const dateTimeRangeSchema = z.object({
  start: z.date(),
  end: z.date(),
});

export const dateRangeSchema = z
  .object({
    values: dateTimeRangeSchema,
    // Days of week, 0 = Sunday, 6 = Saturday
    excluded: z.array(z.number().min(0).max(6)),
  })
  .catch({
    values: dateRangeOptions[0].range, // today
    excluded: [],
  });

export const timeRangeSchema = z
  .object({
    values: z.array(dateTimeRangeSchema).min(1).max(2),
  })
  .catch({
    values: [defaultTimeRange],
  });

export type DateTimeRange = z.infer<typeof dateTimeRangeSchema>;
export type DateRangeFilter = z.infer<typeof dateRangeSchema>;
export type TimeRangeFilter = z.infer<typeof timeRangeSchema>;
export type DateRangeOption = (typeof dateRangeOptions)[number]["value"];

export function findDateOptionValueByRange(
  dateRange: DateTimeRange
): DateRangeOption | undefined {
  const option = dateRangeOptions.find(
    ({ range }) =>
      isSameDay(range.start, dateRange.start) &&
      isSameDay(range.end, dateRange.end)
  );
  return option?.value;
}

export function findPreviousDateRange(dateRange: DateTimeRange): DateTimeRange {
  const diff = Math.abs(
    differenceInCalendarDays(dateRange.start, dateRange.end)
  );
  return {
    start: subDays(dateRange.start, diff + 1),
    end: subDays(dateRange.start, 1),
  };
}

export function findNextDateRange(dateRange: DateTimeRange): DateTimeRange {
  const diff = Math.abs(
    differenceInCalendarDays(dateRange.start, dateRange.end)
  );
  const newDateRange = {
    start: addDays(dateRange.end, 1),
    end: addDays(dateRange.end, diff + 1),
  };
  if (newDateRange.start > today) {
    newDateRange.start = today;
  }
  if (newDateRange.end > today) {
    newDateRange.end = today;
  }
  return newDateRange;
}
