import { useAuthenticator } from "@aws-amplify/ui-react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useQuery } from "@tanstack/react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import {
  AlertCircleIcon,
  LoaderCircleIcon,
  MessageCircleCode,
} from "lucide-react";
import { useEffect, useState } from "react";

import {
  Dialog,
  DialogOverlay,
  DialogPortal,
  DialogTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

function generateRandomId() {
  return Math.random().toString(36).substring(2);
}

type Message =
  | {
      sender: "llm";
      id: string;
      requestId: string;
      text: string;
    }
  | {
      sender: "user";
      id: string;
      text: string;
      responseStatus: "pending" | "success" | "error";
    };

type MessageHistory = {
  byId: Record<string, Message>;
  allIds: Array<string>;
};

/**
 * This is a quick and dirty implementation of a chat component that connects to the LLM backend.
 * It's meant to be used for testing the LLM backend and is only available for users in the 'snapsoft' group.
 * It's NOT meant to be a production-ready chat component, nor to be exposed to customers and WILL be replaced in the future.
 */
export function LlmChat() {
  const { user } = useAuthenticator();
  const sessionQuery = useQuery({
    queryKey: ["authSession", user?.userId ?? ""],
    queryFn: () => fetchAuthSession(),
    enabled: !!user,
  });

  const accessToken = sessionQuery.data?.tokens?.idToken?.toString();
  const userGroups: Array<string> = (sessionQuery.data?.tokens?.accessToken
    .payload["cognito:groups"] ?? []) as Array<string>;
  const inGroupWithAccess = userGroups.some((group) =>
    ["snapsoft", "Admin"].includes(group)
  );

  // if user is not an admin, do not render the chat
  if (!inGroupWithAccess || !accessToken) {
    return null;
  } else {
    return <ChatDrawer accessToken={accessToken} />;
  }
}

function ChatDrawer({ accessToken }: { accessToken: string }) {
  const [status, setStatus] = useState<WebSocket["readyState"]>(
    WebSocket.CLOSED
  );
  const [messages, setMessages] = useState<MessageHistory>({
    byId: {},
    allIds: [],
  });
  const connectionQuery = useQuery<[WebSocket, string]>({
    queryKey: ["llm-auth"],
    queryFn: async () => {
      const baseUrl = "/api/llm-auth";
      const response = await fetch(baseUrl, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (!response.ok) {
        throw new Error("Failed to authenticate with LLM backend");
      }
      const { token_01, token_02 } = (await response.json()) as {
        token_01: string;
        token_02: string;
      };
      const wsUrl = `wss://vxe8ml4akj.execute-api.eu-central-1.amazonaws.com/dev/?auth=${token_01}`;
      const ws = new WebSocket(wsUrl);
      return [ws, token_02];
    },
  });

  useEffect(() => {
    if (connectionQuery.isPending || !connectionQuery.data) return;

    const [ws, token] = connectionQuery.data;

    ws.onopen = () => {
      setStatus(WebSocket.OPEN);
      // send initial auth message
      ws.send(
        JSON.stringify({
          action: "message",
          type: "auth",
          id: generateRandomId(),
          token,
        })
      );
    };
    ws.onerror = (error) => {
      console.error("error", error);
      // TODO: handle error
    };
    ws.onclose = () => {
      setStatus(WebSocket.CLOSED);
      connectionQuery.refetch();
    };
    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const id = generateRandomId();

      setMessages((prev) => ({
        byId: {
          ...prev.byId,
          [message.id]: {
            ...prev.byId[message.id],
            responseStatus: "success",
          },
          [id]: {
            id,
            sender: "llm",
            text: message.response,
            requestId: message.id,
          },
        },
        allIds: [...prev.allIds, id],
      }));
    };
  }, [connectionQuery]);

  const statusText = {
    [WebSocket.CONNECTING]: "Connecting...",
    [WebSocket.OPEN]: "Connected",
    [WebSocket.CLOSING]: "Disconnecting...",
    [WebSocket.CLOSED]: "Disconnected",
  }[status];

  return (
    <>
      <Dialog>
        <DialogTrigger
          className={cn(
            "text-brand-blue-1 hover:bg-brand-gray-1 focus:outline-none rounded-full",
            "flex items-center justify-center w-10 h-10 relative"
          )}
        >
          {connectionQuery.isLoading && (
            <LoaderCircleIcon className="h-4 w-4 animate-spin" />
          )}
          {connectionQuery.isError && (
            <AlertCircleIcon className="h-4 w-4 text-brand-warning" />
          )}
          {connectionQuery.isSuccess && (
            <MessageCircleCode className="h-4 w-4" />
          )}
        </DialogTrigger>
        <DialogPortal>
          <DialogOverlay className="z-20" />
          <DialogPrimitive.Content
            onClick={(e) => e.stopPropagation()}
            className={cn(
              "fixed z-30 right-0 top-0 bottom-0 overflow-hidden",
              "flex flex-col w-[540px]",
              "bg-brand-white shadow-lg transition ease-in-out",
              "data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=open]:slide-in-from-right",
              "data-[state=closed]:animate-out data-[state=closed]:duration-500 data-[state=closed]:slide-out-to-right"
            )}
          >
            {connectionQuery.isLoading && (
              <div className="flex items-center justify-center p-6">
                <LoaderCircleIcon className="h-8 w-8 animate-spin text-brand-blue-1" />
              </div>
            )}
            {connectionQuery.isError && (
              <div className="flex items-center justify-center p-6">
                <AlertCircleIcon className="h-8 w-8 text-brand-warning" />
              </div>
            )}
            {connectionQuery.isSuccess && (
              <div className="flex flex-col h-full">
                <header className="shadow-sm">
                  <div
                    className={cn(
                      "px-6 py-2 text-white font-semibold transition-colors",
                      {
                        "bg-brand-improving": status === WebSocket.OPEN,
                        "bg-brand-gray-3": status === WebSocket.CONNECTING,
                        "bg-brand-warning": status === WebSocket.CLOSED,
                      }
                    )}
                  >
                    {statusText}
                  </div>
                  <ChatInput
                    disabled={status !== WebSocket.OPEN}
                    onSend={(userInput) => {
                      if (status !== WebSocket.OPEN) return;

                      const [ws] = connectionQuery.data;
                      const messageId = generateRandomId();

                      ws.send(
                        JSON.stringify({
                          action: "message",
                          type: "user_input",
                          id: messageId,
                          user_input: userInput,
                        })
                      );
                      setMessages((prev) => ({
                        byId: {
                          ...prev.byId,
                          [messageId]: {
                            id: messageId,
                            text: userInput,
                            sender: "user",
                            responseStatus: "pending",
                          },
                        },
                        allIds: [...prev.allIds, messageId],
                      }));
                    }}
                  />
                </header>
                <ul className="flex flex-col gap-4 px-6 pb-12 pt-6 overflow-auto grow">
                  {messages.allIds.map((messageId) => {
                    const message = messages.byId[messageId];
                    return (
                      <li
                        key={message.id}
                        className={cn(
                          "px-4 py-2 w-3/4 rounded-lg text-slate-700",
                          {
                            "bg-blue-100 place-self-end":
                              message.sender === "llm",
                            "bg-green-50": message.sender === "user",
                          }
                        )}
                      >
                        <h4
                          className={cn("text-sm font-semibold", {
                            "text-green-700": message.sender === "llm",
                            "text-blue-700": message.sender === "user",
                          })}
                        >
                          From: {message.sender}
                        </h4>
                        {message.text}
                        {message.sender == "user" && (
                          <div>
                            {message.responseStatus === "pending" && (
                              <span className="text-brand-gray-3 animate-pulse text-2xl">
                                ...
                              </span>
                            )}
                            {message.responseStatus === "success" && (
                              <span className="text-brand-improving">✓</span>
                            )}
                            {message.responseStatus === "error" && (
                              <span className="text-brand-warning">✗</span>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </DialogPrimitive.Content>
        </DialogPortal>
      </Dialog>
    </>
  );
}

function ChatInput({
  disabled,
  onSend,
}: {
  disabled: boolean;
  onSend: (message: string) => void;
}) {
  const [userInput, setUserInput] = useState("");
  return (
    <div className="p-6 flex gap-4 items-center">
      <input
        type="text"
        disabled={disabled}
        className="flex-1 border border-brand-gray-3 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-brand-blue-1"
        value={userInput}
        onChange={(e) => {
          setUserInput(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && userInput.trim().length > 2) {
            onSend(userInput);
            setUserInput("");
          }
        }}
      />
      <button
        className={cn("bg-brand-blue-1 text-white rounded-md px-4 py-2", {
          "opacity-50 cursor-not-allowed":
            disabled || userInput.trim().length < 3,
        })}
        disabled={disabled || userInput.trim().length < 3}
        onClick={() => {
          onSend(userInput);
          setUserInput("");
        }}
      >
        Send
      </button>
    </div>
  );
}
