import * as SwitchPrimitives from "@radix-ui/react-switch";
import React from "react";

import { cn } from "@/view/utils";

export const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "peer inline-flex h-6 w-11 shrink-0 cursor-pointer items-center",
      "rounded-full border-2 border-transparent transition-colors",
      "focus-visible:outline-none",
      "disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-brand-blue-1 data-[state=unchecked]:bg-brand-gray-3",
      className
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-5 w-5 rounded-full bg-brand-white shadow-lg",
        "ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;
