import { generatePath, Navigate, Outlet, useParams } from "react-router-dom";

import { paths } from "@/view/routes";

import { LineSettingsLayout } from "./line-settings-layout";
import { SettingsNav } from "./settings-nav";

export function LineSettingsRootPage() {
  return (
    <LineSettingsLayout navigation={<SettingsNav />}>
      <Outlet />
    </LineSettingsLayout>
  );
}

export function LineSettingsIndexPage() {
  const { lineId = "" } = useParams<{ lineId: string }>();
  const path = generatePath(paths.lineSettingsShiftsPath, { lineId });
  return <Navigate to={path} replace />;
}
