import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { format } from "date-fns";

import { BaseError } from "@/domain/common/errors";
import { LineId } from "@/domain/line";
import { ShiftVariant, ShiftWithVariants } from "@/domain/shifts";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedLine } from "../line-id/use-selected-line";

const listQueryKey = "shift-variants";

export function shiftWithVariantsQueryKey(
  lineId: LineId,
  applicableDate: Date | null = null
) {
  const dateKey = applicableDate
    ? format(applicableDate, "yyyy-MM-dd")
    : "null";
  return [listQueryKey, lineId, dateKey] as const;
}

export function useShiftsWithVariantsQuery({
  applicableDate,
}: {
  applicableDate: Date | null;
}) {
  const apiClient = useApiClient();
  const line = useSelectedLine();
  return useSuspenseQuery({
    queryKey: shiftWithVariantsQueryKey(line.id, applicableDate),
    queryFn: ({ signal }) =>
      apiClient.getShiftsWithVariants(
        { lineId: line.id, applicableDate },
        { signal }
      ),
    staleTime: Infinity,
  });
}

export function useCreateShiftVariantMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const line = useSelectedLine();
  return useMutation<ShiftVariant, BaseError, Omit<ShiftVariant, "id">>({
    mutationFn: async (params: Omit<ShiftVariant, "id">) => {
      return apiClient.createShiftVariant(params);
    },
    onSuccess(value) {
      // TODO: maybe refetch data on the line reporting screen to get data based on potentially new schedule
      const query = queryClient
        .getQueryCache()
        .find<
          Array<ShiftWithVariants>
        >({ queryKey: shiftWithVariantsQueryKey(line.id) });

      if (query && query.state.data) {
        query.setData(
          query.state.data.map((shift) => ({
            ...shift,
            variants: [...shift.variants, value],
          }))
        );
      }

      queryClient.refetchQueries({
        queryKey: shiftWithVariantsQueryKey(line.id),
      });
    },
  });
}

export function useUpdateShiftVariantMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const line = useSelectedLine();
  return useMutation<ShiftVariant, BaseError, ShiftVariant>({
    mutationFn: async (params: ShiftVariant) => {
      return apiClient.updateShiftVariant(params);
    },
    onSuccess(value) {
      // TODO: maybe refetch data on the line reporting screen to get data based on potentially new schedule
      const query = queryClient
        .getQueryCache()
        .find<
          Array<ShiftWithVariants>
        >({ queryKey: shiftWithVariantsQueryKey(line.id) });

      if (query && query.state.data) {
        query.setData(
          query.state.data.map((shift) => ({
            ...shift,
            variants: shift.variants.map((variant) =>
              variant.id === value.id ? value : variant
            ),
          }))
        );
      }

      queryClient.refetchQueries({
        queryKey: shiftWithVariantsQueryKey(line.id),
      });
    },
  });
}

export function useDeleteShiftVariantMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const line = useSelectedLine();
  return useMutation<void, BaseError, ShiftVariant>({
    mutationFn: async (params: ShiftVariant) => {
      return apiClient.deleteShiftVariant(params);
    },
    onSuccess(_, params) {
      // TODO: maybe refetch data on the line reporting screen to get data based on potentially new schedule
      const query = queryClient
        .getQueryCache()
        .find<
          Array<ShiftWithVariants>
        >({ queryKey: shiftWithVariantsQueryKey(line.id) });

      if (query && query.state.data) {
        query.setData(
          query.state.data.map((shift) => ({
            ...shift,
            variants: shift.variants.filter(
              (variant) => variant.id !== params.id
            ),
          }))
        );
      }

      queryClient.refetchQueries({
        queryKey: shiftWithVariantsQueryKey(line.id),
      });
    },
  });
}
