import { useLingui } from "@lingui/react";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import {
  generatePath,
  NavLink,
  useNavigate,
  useParams,
} from "react-router-dom";

import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

export function MainNav() {
  const { i18n } = useLingui();

  return (
    <NavigationMenu.Root className="">
      <NavigationMenu.List className={cn("flex justify-center gap-2")}>
        <MenuItem
          href={paths.lineReportingPath}
          label={i18n.t("reportingRouteLabel")}
        />
        <MenuItem
          href={paths.lineTrendsPath}
          label={i18n.t("trendsRouteLabel")}
        />
        <MenuItem
          href={paths.lineVideoLibraryPath}
          label={i18n.t("videoLibraryRouteLabel")}
        />
        <MenuItem
          href={paths.lineBirdEyeViewPath}
          label={i18n.t("Spaghetti diagram")}
        />
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}

function MenuItem({ href, label }: { href: string; label: string }) {
  const navigate = useNavigate();
  const { lineId = "" } = useParams<{ lineId: string }>();
  const path = generatePath(href, { lineId });
  return (
    <NavigationMenu.Item>
      <NavigationMenu.Link asChild>
        <NavLink
          to={path}
          className="focus:outline-none"
          onClick={(e) => {
            e.preventDefault();
            navigate(path + location.search);
          }}
        >
          {({ isActive }) => (
            <span
              className={cn(
                "block px-4 py-2 rounded-md",
                "text-base text-brand-gray-5 font-semibold",
                "hover:text-brand-black",
                {
                  "bg-brand-gray-1": isActive,
                }
              )}
            >
              {label}
            </span>
          )}
        </NavLink>
      </NavigationMenu.Link>
    </NavigationMenu.Item>
  );
}
