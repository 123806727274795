import { z } from "zod";

import { dateRangeSchema } from "./common/time-filter";
import { lineIdSchema } from "./line";
import { shiftIdSchema } from "./shifts";
import { stationIdSchema } from "./station";

export const areaIdSchema = z.string().brand<"AreaId">();

const areaColorSchema = z.array(z.number().min(0).max(255)).length(3);
const dimensionsSchema = z.object({
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
});
const areaCoordinatesSchema = dimensionsSchema.extend({
  x: z.number().nonnegative(),
  y: z.number().nonnegative(),
});
const absAreaCoordinates =
  areaCoordinatesSchema.brand<"AbsoluteAreaCoordinates">();
export const relAreaCoordinates =
  areaCoordinatesSchema.brand<"RelativeAreaCoordinates">();
const areaConnectionSchema = z.object({
  count: z.number().nonnegative(),
  areaId: areaIdSchema,
});
const areaSchema = z.object({
  id: areaIdSchema,
  name: z.string(),
  color: areaColorSchema,
  coordinates: absAreaCoordinates,
  stationId: stationIdSchema.nullable(),
});
const cameraIdSchema = z.string().brand<"CameraId">();
const areaCoordinatesByAreaIdSchema = z.record(
  areaIdSchema,
  absAreaCoordinates
);

const getAreaOfInterestsStatisticsFiltersSchema = z.object({
  areaId: areaIdSchema,
  dateRange: dateRangeSchema,
  shiftGroupIds: z.array(shiftIdSchema),
});
const workerCountSchema = z.object({
  time: z.string(),
  workerCount: z.number().nonnegative(),
  timeSpentRatio: z.number().nonnegative().min(0).max(1),
});
const timeSpentSchema = z.object({
  time: z.string(),
  durationSeconds: z.number().nonnegative(),
});
export const areaOfInterestsStatisticsSchema = z.object({
  avgTimeSpent: z.array(timeSpentSchema),
  avgWorkerCount: z.array(workerCountSchema),
});
export type AreaOfInterestsStatistics = z.infer<
  typeof areaOfInterestsStatisticsSchema
>;
export type GetAreaOfInterestStatisticsFilters = z.infer<
  typeof getAreaOfInterestsStatisticsFiltersSchema
>;

export type AreaId = z.infer<typeof areaIdSchema>;
export type AreaConnection = z.infer<typeof areaConnectionSchema>;
export type AreaColor = z.infer<typeof areaColorSchema>;
export type Dimensions = z.infer<typeof dimensionsSchema>;
export type AreaCoordinates = z.infer<typeof absAreaCoordinates>;

export type AreaCoordinatesByAreaId = z.infer<
  typeof areaCoordinatesByAreaIdSchema
>;
type RelativeAreaCoordinates = z.infer<typeof relAreaCoordinates>;

export function convertRelativeToAbsoluteCoordinates(
  coordinates: RelativeAreaCoordinates,
  canvasSize: Dimensions
): AreaCoordinates {
  return absAreaCoordinates.parse({
    x: Math.trunc(coordinates.x * canvasSize.width),
    y: Math.trunc(coordinates.y * canvasSize.height),
    width: Math.trunc(coordinates.width * canvasSize.width),
    height: Math.trunc(coordinates.height * canvasSize.height),
  });
}

const getHeatmapByCameraIdFiltersSchema = z.object({
  cameraId: cameraIdSchema,
  dateRange: dateRangeSchema,
  shiftsIds: z.array(shiftIdSchema),
});
export const heatmapSchema = z.array(
  z.tuple([
    z.number().nonnegative(),
    z.number().nonnegative(),
    z.number().nonnegative(),
  ])
);
export type CameraId = z.infer<typeof cameraIdSchema>;

export type GetHeatmapByCameraIdFilters = z.infer<
  typeof getHeatmapByCameraIdFiltersSchema
>;
export type Heatmap = z.infer<typeof heatmapSchema>;

const getVirtualSensorImageByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});
export type GetVirtualSensorImageByLineIdFilters = z.infer<
  typeof getVirtualSensorImageByLineIdFiltersSchema
>;

const getVirtualSensorAreasLayoutByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});
export type GetVirtualSensorAreasLayoutByLineIdFilters = z.infer<
  typeof getVirtualSensorAreasLayoutByLineIdFiltersSchema
>;

export const virtualSensorAreasLayoutSchema = z.object({
  id: cameraIdSchema,
  dimensions: dimensionsSchema,
  areas: z.array(areaSchema),
  areaCoordinatesByAreaId: z.record(areaIdSchema, absAreaCoordinates),
});
export type VirtualSensorAreasLayout = z.infer<
  typeof virtualSensorAreasLayoutSchema
>;
const virtualSensorImageSchema = z.object({
  id: cameraIdSchema,
  bgUrl: z.string().url(),
  dimensions: dimensionsSchema,
});
export type VirtualSensorImage = z.infer<typeof virtualSensorImageSchema>;

export const areaConnectionsSchema = z.array(areaConnectionSchema);
export type AreaConnections = z.infer<typeof areaConnectionsSchema>;
const getAreaConnectionsFiltersSchema = z.object({
  areaId: areaIdSchema,
  dateRange: dateRangeSchema,
  shiftGroupIds: z.array(shiftIdSchema),
});
export type GetAreaConnectionsFilters = z.infer<
  typeof getAreaConnectionsFiltersSchema
>;
