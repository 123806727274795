import { XIcon } from "lucide-react";
import * as React from "react";

import { cn } from "@/view/utils";

export function TagItem({
  className,
  tag,
  disabled,
  onTagRemove,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  tag: {
    id: string;
    name: string;
    color: string;
  };
  onTagRemove?: () => void;
}) {
  return (
    <>
      <div
        className={cn(
          "flex items-center bg-brand-white border px-2 py-1 rounded-sm gap-1.5",
          className
        )}
        {...props}
      >
        <div
          className="w-3 h-3 rounded-sm"
          style={{ backgroundColor: tag.color }}
        />
        <span className="text-sm">{tag.name}</span>
        {onTagRemove && (
          <XIcon
            className={cn(
              "h-4 w-4 hover:bg-red-500 hover:text-white rounded-sm",
              {
                "cursor-pointer": !disabled,
                "pointer-events-none": disabled,
              }
            )}
            onClick={!disabled ? onTagRemove : undefined}
          />
        )}
      </div>
    </>
  );
}
