import { createContext, useContext } from "react";

export type DurationRange = [number, number] | null;

export const DurationRangeContext = createContext<DurationRange>(null);

export function useDurationRange() {
  const ctx = useContext(DurationRangeContext);
  return ctx;
}

const searchParamKey = "duration";
const durationRangeRegex = new RegExp(/^(\d+)_(\d+)$/);

export function parseDurationRangeFromSearchParams(
  searchParams: URLSearchParams
): DurationRange {
  const maybeDurationRange = searchParams.get(searchParamKey) ?? "";
  if (!durationRangeRegex.test(maybeDurationRange)) return null;
  return maybeDurationRange.split("_").map(Number) as [number, number];
}

export function encodeDurationRangeToSearchParams(
  searchParams: URLSearchParams,
  durationRange: DurationRange
) {
  if (durationRange) {
    searchParams.set(searchParamKey, durationRange.join("_"));
  }
  return searchParams;
}
