import { useLingui } from "@lingui/react";
import { useMemo } from "react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/view/components";
import { formatKpiValue, getKpiUnit } from "@/view/utils";

import { useSelectedStationIds } from "../line-id/use-selected-station-ids";
import { useLineStatistics } from "./line-statistics-provider";
import { LineWidgetDropdown } from "./line-widget-dropdown";

export function LineWidgetActivityError() {
  const { i18n } = useLingui();
  return (
    <Card className="p-4 h-44 grow border rounded-xl shadow-sm">
      <CardHeader>
        <CardTitle>{i18n.t("lineOverviewWidgetActivityTitle")}</CardTitle>
      </CardHeader>
      <CardContent>
        <CardDescription>
          <span className="text-5xl">--.--</span>
          <span className="text-2xl"> h</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}

export function LineWidgetActivity() {
  const { i18n } = useLingui();
  const stationIds = useSelectedStationIds();
  const data = useLineStatistics();
  const value = useMemo(() => {
    const totalActiveTime = stationIds.reduce((acc, stationId) => {
      const activeTime =
        data.activityByStationId[stationId]?.combined.find(
          (it) => it.key === "person_product"
        )?.value ?? 0;
      return acc + activeTime;
    }, 0);
    const totalPersonTime = stationIds.reduce((acc, stationId) => {
      const activeTime =
        data.activityByStationId[stationId]?.combined.find(
          (it) => it.key === "person_product"
        )?.value ?? 0;
      const personTime =
        data.activityByStationId[stationId]?.combined.find(
          (it) => it.key === "person"
        )?.value ?? 0;
      return acc + personTime + activeTime;
    }, 0);
    const activeTimeRatio =
      totalPersonTime > 0 ? (totalActiveTime / totalPersonTime) * 100 : 0;
    const formattedValue = formatKpiValue("activity", activeTimeRatio);
    const valueUnit = getKpiUnit("activity", activeTimeRatio);
    return { formattedValue, valueUnit };
  }, [data, stationIds]);

  return (
    <Card className="p-4 border rounded-xl shadow-sm h-44 flex-grow max-w-xl min-w-min">
      <CardHeader className="relative">
        <CardTitle>{i18n.t("lineOverviewWidgetActivityTitle")}</CardTitle>
        <div className="absolute top-0 right-0">
          <LineWidgetDropdown targetType="persons" />
        </div>
      </CardHeader>
      <CardContent>
        <CardDescription className="font-bold">
          <span className="text-5xl">{value.formattedValue}</span>
          <span className="text-2xl">{` ${value.valueUnit}`}</span>
        </CardDescription>
      </CardContent>
    </Card>
  );
}
