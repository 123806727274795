import posthog from "posthog-js";

import { AnalyticsClient, AnalyticsEventName } from "@/domain/analytics";

export function createPosthogAnalyticsClient(): AnalyticsClient {
  const analyticsToken = import.meta.env.VITE_ANALYTICS_ID as
    | string
    | undefined;
  const analyticsHost =
    (import.meta.env.VITE_ANALYTICS_HOST as string | undefined) ??
    "https://eu.posthog.com";
  // import.meta.env.PROD is `true` for the bundled version of JavaScript,
  // meaning the code that is shippable to any of the deployment environments.
  // It will be `false` when the code is running in local development or testing.
  const isProductionBuild = import.meta.env.PROD;
  const isWindowDefined = typeof window !== "undefined";
  const isProductionEnv =
    isWindowDefined && window.location.hostname.includes(".deltia.ai");
  const enableAnalytics =
    !!analyticsToken && isProductionBuild && isWindowDefined && isProductionEnv;

  if (enableAnalytics) {
    posthog.init(analyticsToken, {
      // we are sending events via proxy to prevent ad blockers from blocking them
      api_host: `${window.location.origin}/ingest`,
      ui_host: analyticsHost,
    });
  }

  function collect(
    eventName: AnalyticsEventName,
    data?: Record<string, unknown>,
    pathname?: string
  ) {
    if (!enableAnalytics) return;

    if (eventName === "page_entered") {
      posthog.capture("$pageview");
      return;
    }

    posthog.capture(eventName, {
      ...(data ?? {}),
      pathname: pathname ?? window.location.pathname,
    });
  }

  function identifyUser(userId: string, data?: Record<string, unknown>) {
    if (!enableAnalytics) return;

    posthog.identify(userId, data);
  }

  function resetUser() {
    if (!enableAnalytics) return;

    posthog.reset();
  }

  return Object.freeze({
    collect,
    identifyUser,
    resetUser,
  });
}
