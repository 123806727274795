import "@/view/styles/globals.css";

import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import {
  AppAuthenticator,
  AuthenticatorProvider,
} from "@/view/components/authenticator";

import { createPosthogAnalyticsClient } from "./infra/posthog-analytics";
import { AnalyticsClientProvider } from "./view/providers/analytics-provider";
import { ApiClientProvider } from "./view/providers/api-client-provider";
import { LocaleProvider } from "./view/providers/locale-provider";
import { TracingProvider } from "./view/providers/tracing-provider";
import { apiClient, queryClient, router, tracingClient } from "./view/routes";

const analyticsClient = createPosthogAnalyticsClient();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <LocaleProvider>
      <AuthenticatorProvider>
        <ApiClientProvider client={apiClient}>
          <AnalyticsClientProvider client={analyticsClient}>
            <QueryClientProvider client={queryClient}>
              <TracingProvider client={tracingClient}>
                <AppAuthenticator>
                  <RouterProvider router={router} />
                </AppAuthenticator>
              </TracingProvider>
            </QueryClientProvider>
          </AnalyticsClientProvider>
        </ApiClientProvider>
      </AuthenticatorProvider>
    </LocaleProvider>
  </React.StrictMode>
);
