import { createContext, useContext } from "react";

export enum DrawerTab {
  Overview = "overview",
  Videos = "videos",
}

export const drawerTabs = [DrawerTab.Overview, DrawerTab.Videos];

export const SelectedDrawerTabContext = createContext<DrawerTab>(
  DrawerTab.Overview
);

export function useSelectedDrawerTab() {
  const ctx = useContext(SelectedDrawerTabContext);
  if (!ctx) {
    throw new Error(
      "useSelectedDrawerTab must be used inside SelectedDrawerTabProvider"
    );
  }
  return ctx;
}

const searchParamKey = "tab";
const validDrawerTabs = new Set(drawerTabs);

export function parseSelectedDrawerTabFromSearchParams(
  searchParams: URLSearchParams
) {
  const maybeDrawerTab = searchParams.get(searchParamKey);
  return validDrawerTabs.has(maybeDrawerTab as DrawerTab)
    ? (maybeDrawerTab as DrawerTab)
    : DrawerTab.Overview;
}

export function encodeSelectedDrawerTabToSearchParams(
  searchParams: URLSearchParams,
  drawerTab: DrawerTab
) {
  searchParams.set(searchParamKey, drawerTab);
  return searchParams;
}
