import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { addHours, isBefore, startOfDay } from "date-fns";
import { useReducer } from "react";

import { PaginatedResponse } from "@/domain/api-client";
import { BaseError } from "@/domain/common/errors";
import { Notification, NotificationId } from "@/domain/notification";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { useApiClient } from "@/view/providers/api-client-provider";

type NotificationsState = {
  drawerOpen: boolean;
  drawerNeverOpened: boolean;
  viewMode: "unread" | "all";
};

type NotificationsAction =
  | {
      type: "open_drawer";
    }
  | {
      type: "close_drawer";
    }
  | {
      type: "show_all";
    }
  | {
      type: "show_unread";
    };

const NOTIFICATION_PAGE_SIZE = 20;

const initState: NotificationsState = {
  drawerOpen: false,
  drawerNeverOpened: true,
  viewMode: "all",
};

function notificationsReducer(
  state: NotificationsState,
  action: NotificationsAction
): NotificationsState {
  switch (action.type) {
    case "open_drawer":
      return {
        ...state,
        drawerNeverOpened: false,
        drawerOpen: true,
      };
    case "close_drawer":
      return {
        ...state,
        drawerOpen: false,
      };
    case "show_all":
      return {
        ...state,
        viewMode: "all",
      };
    case "show_unread":
      return {
        ...state,
        viewMode: "unread",
      };
    default:
      return state;
  }
}

function useReadNotificationMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (notification: Notification) =>
      apiClient.updateNotification({
        id: notification.id,
        publishedAt: notification.publishedAt,
        isRead: true,
      }),
    onSuccess: (data: Notification) => {
      queryClient.setQueryData(["notification-by-id", data.id], data);
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "notifications",
      });
    },
  });
}

export function useNotifications() {
  const analytics = useAnalytics();
  const [state, dispatch] = useReducer(notificationsReducer, initState);
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  const isRead = state.viewMode === "all" ? null : false;
  const query = useInfiniteQuery<
    PaginatedResponse<Notification>,
    BaseError,
    InfiniteData<PaginatedResponse<Notification>>,
    QueryKey,
    number
  >({
    queryKey: ["notifications", isRead],
    queryFn: ({ signal, pageParam }) => {
      return apiClient.getNotifications(
        {
          isRead,
          offset: pageParam,
          limit: NOTIFICATION_PAGE_SIZE,
        },
        { signal }
      );
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage: PaginatedResponse<Notification>) =>
      lastPage.totalCount > lastPage.offset + NOTIFICATION_PAGE_SIZE
        ? lastPage.offset + NOTIFICATION_PAGE_SIZE
        : undefined,
    refetchOnWindowFocus: true,
    staleTime: Infinity,
  });
  const snoozeMutation = useMutation({
    mutationFn: (notification: Notification) => {
      const currentTime = new Date();
      const today = startOfDay(currentTime);
      const endOfDay = addHours(today, 16);
      const nextMorning = addHours(today, 30);
      const publishedAt = isBefore(currentTime, addHours(today, 12))
        ? endOfDay
        : nextMorning;

      return apiClient.updateNotification({
        id: notification.id,
        publishedAt,
        isRead: false,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey[0] === "notifications",
      });
    },
  });
  const readMutation = useReadNotificationMutation();
  const notifications = query.data?.pages?.flatMap((it) => it.data) ?? [];
  const unreadCount = notifications.filter((it) => !it.isRead).length;
  const hasNoUnread = unreadCount === 0;
  const showCountBadge = state.drawerNeverOpened && unreadCount > 0;

  function openDrawer() {
    dispatch({ type: "open_drawer" });
    analytics.collect("notifications_drawer_opened");
  }
  function closeDrawer() {
    dispatch({ type: "close_drawer" });
    analytics.collect("notifications_drawer_closed");
  }
  function showAll() {
    dispatch({ type: "show_all" });
  }
  function showUnread() {
    dispatch({ type: "show_unread" });
  }
  function fetchNextPage() {
    query.fetchNextPage();
  }
  function readNotification(notification: Notification) {
    if (readMutation.isPending) return;
    readMutation.mutate(notification);
    analytics.collect("notification_read");
  }
  function snoozeNotification(notification: Notification) {
    if (snoozeMutation.isPending) return;
    snoozeMutation.mutate(notification);
    analytics.collect("notification_snoozed");
  }

  return {
    state: {
      ...state,
      notifications,
      unreadCount,
      hasNoUnread,
      showCountBadge,
      isLoading: query.isPending,
      isFailure: query.isError,
      isSuccess: query.isSuccess,
      isFetching: query.isFetching,
      isFetchingNextPage: query.isFetchingNextPage,
      hasNextPage: query.hasNextPage,
      isMarkingAsRead: (id: NotificationId) =>
        readMutation.variables?.id === id && readMutation.isPending,
      isSnoozing: (id: NotificationId) =>
        snoozeMutation.variables?.id === id && snoozeMutation.isPending,
    },
    actions: {
      openDrawer,
      closeDrawer,
      showAll,
      showUnread,
      fetchNextPage,
      readNotification,
      snoozeNotification,
    },
  };
}
