import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";

import { AreaId, AreaOfInterestsStatistics } from "@/domain/areas-of-interests";
import { BaseError } from "@/domain/common/errors";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";
import { useApiClient } from "@/view/providers/api-client-provider";

export function useAreaOfInterestsStatistics<TData = AreaOfInterestsStatistics>(
  params: {
    areaId: AreaId;
    dateRange: DateRangeFilter;
    shiftGroupIds: Array<ShiftId>;
  },
  options?: Omit<
    UseQueryOptions<AreaOfInterestsStatistics, BaseError, TData, QueryKey>,
    "queryKey" | "queryFn"
  >
) {
  const apiClient = useApiClient();

  return useQuery<AreaOfInterestsStatistics, BaseError, TData, QueryKey>({
    queryKey: [
      "area-statistics",
      params.areaId,
      params.dateRange,
      params.shiftGroupIds,
    ],
    queryFn: ({ signal }) => {
      return apiClient.getAreaOfInterestsStatistics(params, { signal });
    },
    staleTime: Infinity,
    refetchInterval: 1000 * 60 * 30, // Half an hour
    refetchIntervalInBackground: true,
    placeholderData: keepPreviousData,
    ...options,
  });
}
