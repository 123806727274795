import { PropsWithChildren, useMemo } from "react";
import { generatePath, Navigate, useParams } from "react-router-dom";

import { stationIdSchema } from "@/domain/station";
import { paths } from "@/view/routes";

import { useLevels } from "../line-id/levels-provider";
import { useSelectedLine } from "../line-id/use-selected-line";
import { SelectedStationDetailsContext } from "./use-selected-station-details";

/**
 * Provides the selected line down the component tree.
 *
 * It also validates if the lineId search param is valid
 * based on the available lines. If it's not valid, it
 * redirects to a default valid lineId.
 */
export function SelectedStationDetailsProvider({
  children,
}: PropsWithChildren) {
  const levels = useLevels();
  const line = useSelectedLine();
  const { stationId } = useParams<{ stationId: string }>();

  const selectedStation = useMemo(() => {
    const validatedId = stationIdSchema.safeParse(stationId);
    return validatedId.success
      ? (levels.stationsByStationId[validatedId.data] ?? line.stations.at(0))
      : line.stations.at(0);
  }, [levels, line, stationId]);

  if (!selectedStation) {
    // This should never happen
    return null;
  }

  if (selectedStation.id !== stationId) {
    const path = generatePath(paths.lineReportingStationDetailsPath, {
      lineId: line.id,
      stationId: selectedStation.id,
    });
    return <Navigate to={path} replace />;
  }

  return (
    <SelectedStationDetailsContext.Provider value={selectedStation}>
      {children}
    </SelectedStationDetailsContext.Provider>
  );
}
