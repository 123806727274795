import { createContext, useContext } from "react";

import { LineWithStations } from "@/domain/levels";
import { Station } from "@/domain/station";

export const SelectedStationContext = createContext<Station | null>(null);

export function useSelectedStation() {
  return useContext(SelectedStationContext);
}

const searchParamKey = "stationId";

export function parseStationIdFromSearchParams(
  searchParams: URLSearchParams,
  line: LineWithStations
) {
  const stationId = searchParams.get(searchParamKey);
  return line.stations.find((s) => s.id === stationId) ?? null;
}

export function encodeStationIdToSearchParams(
  searchParams: URLSearchParams,
  station: Station
) {
  searchParams.set(searchParamKey, station.id);
  return searchParams;
}
