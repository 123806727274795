import React, { useContext } from "react";

import { ApiClient } from "@/domain/api-client";

const ApiClientContext = React.createContext<ApiClient | null>(null);

/**
 * Provides the API client down the component tree.
 */
export function ApiClientProvider({
  client,
  children,
}: {
  client: ApiClient;
  children: React.ReactNode;
}) {
  return (
    <ApiClientContext.Provider value={client}>
      {children}
    </ApiClientContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useApiClient() {
  const apiClient = useContext(ApiClientContext);
  if (!apiClient) {
    throw new Error("ApiClientContext is not provided");
  }
  return apiClient;
}
