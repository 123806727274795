import { createContext, useContext } from "react";

import { LineWithStations } from "@/domain/levels";

export const SelectedLineContext = createContext<LineWithStations | null>(null);

export function useSelectedLine() {
  const selectedLine = useContext(SelectedLineContext);
  if (!selectedLine) {
    throw new Error("useSelectedLine must be used inside SelectedLineProvider");
  }
  return selectedLine;
}
