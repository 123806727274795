import { createContext, useContext } from "react";
import { z } from "zod";

const bevTypes = ["walking_routes", "heatmap"] as const;

const bevTypeSchema = z.enum(bevTypes);
export type BevType = z.infer<typeof bevTypeSchema>;

export const SelectedBevTypeContext = createContext<BevType>(bevTypes[0]);

export function useSelectedBevType() {
  const bevType = useContext(SelectedBevTypeContext);
  if (!bevType) {
    throw new Error(
      "useSelectedBevType must be used inside SelectedBevTypeProvider"
    );
  }
  return bevType;
}

const searchParamKey = "tab";

export function parseBevTypeFromSearchParams(
  searchParams: URLSearchParams
): BevType {
  const maybeBevType = bevTypeSchema.safeParse(
    searchParams.get(searchParamKey)
  );
  return maybeBevType.success ? maybeBevType.data : bevTypes[0];
}

export function encodeBevTypeToSearchParams(
  searchParams: URLSearchParams,
  bevType: BevType
) {
  searchParams.set(searchParamKey, bevType);
  return searchParams;
}
