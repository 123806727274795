import { useLingui } from "@lingui/react";
import {
  createContext,
  PropsWithChildren,
  Suspense,
  useContext,
  useMemo,
  useState,
} from "react";

import { ShiftVariantTargetType } from "@/domain/shifts";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@/view/components";
import { LoadingStatus } from "@/view/components/loading-status";

import { LineIdErrorBoundary } from "../line-id/error-boundary";
import { ShiftsWithVariantsProvider } from "../line-id-settings-shifts/shifts-with-variants-provider";
import { TargetsOverrideForm } from "./targets-override-form";

type Actions = {
  select: (state: State) => void;
  close: () => void;
};

type State = {
  targetType: ShiftVariantTargetType;
};

const TargetsOverrideActionsContext = createContext<Actions | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useTargetsOverrideActions() {
  const ctx = useContext(TargetsOverrideActionsContext);
  if (!ctx) {
    throw new Error(
      "useTargetsOverrideActions must be used within a TargetsOverrideFormProvider"
    );
  }
  return ctx;
}

/**
 * Provides line statistics down the component tree.
 */
export function TargetsOverrideFormProvider({ children }: PropsWithChildren) {
  const [state, setState] = useState<State | null>(null);
  const actions = useMemo<Actions>(() => {
    return {
      select: (state: State) => setState(state),
      close: () => setState(null),
    };
  }, []);

  return (
    <TargetsOverrideActionsContext.Provider value={actions}>
      <>{children}</>
      {state && (
        <TargetsOverrideDialog
          targetType={state.targetType}
          onClose={actions.close}
        />
      )}
    </TargetsOverrideActionsContext.Provider>
  );
}

function ErrorBoundary({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  return (
    <LineIdErrorBoundary
      message={i18n.t("Something went wrong when fetching shifts data.")}
    >
      {children}
    </LineIdErrorBoundary>
  );
}

function TargetsOverrideDialog({
  targetType,
  onClose,
}: {
  targetType: ShiftVariantTargetType;
  onClose: () => void;
}) {
  const today = useMemo(() => new Date(), []);
  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (open) return;
        onClose();
      }}
    >
      <DialogPortal>
        <DialogOverlay className="z-20" />
        <DialogContent className="mx-auto w-full max-w-lg h-[95vh] max-h-[480px] relative">
          <Suspense fallback={<LoadingStatus />}>
            <ErrorBoundary>
              <ShiftsWithVariantsProvider applicableDate={today}>
                <TargetsOverrideForm
                  applicableDate={today}
                  targetType={targetType}
                />
              </ShiftsWithVariantsProvider>
            </ErrorBoundary>
          </Suspense>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
}
