import { PropsWithChildren } from "react";

import { Container } from "@/view/components";

export function LineSettingsLayout({
  children,
  navigation,
}: PropsWithChildren<{
  navigation: React.ReactNode;
}>) {
  return (
    <section className="flex flex-col">
      <header className="bg-brand-white py-4 border-b">
        <Container>{navigation}</Container>
      </header>
      <Container className="py-12">{children}</Container>
    </section>
  );
}
