import { useAuthenticator } from "@aws-amplify/ui-react";
import { useLingui } from "@lingui/react";
import { useQuery } from "@tanstack/react-query";
import { fetchAuthSession } from "aws-amplify/auth";
import { PackageIcon } from "lucide-react";
import { useCallback, useState } from "react";

import {
  deriveNewTagSelectionFactory,
  getAllowedTagIds,
  getAllValidTagIds,
  Tag,
  TagId,
} from "@/domain/tag";
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { useSelectedProductIds } from "./use-selected-product-ids";

export function ProductsSelect({
  availableProductTags,
  onTagIdsChange,
}: {
  availableProductTags: Array<Tag>;
  onTagIdsChange: (ids: Array<TagId>) => void;
}) {
  const { user } = useAuthenticator();
  const sessionQuery = useQuery({
    queryKey: ["authSession", user?.userId ?? ""],
    queryFn: () => fetchAuthSession(),
    enabled: !!user,
  });

  const userGroups: Array<string> = (sessionQuery.data?.tokens?.accessToken
    .payload["cognito:groups"] ?? []) as Array<string>;
  const isAdmin = userGroups.some((group) => "Admin" == group);

  if (!isAdmin) {
    return null;
  }

  // NOTE: for now we always want to pass all product ids
  // so we don't need to show the component
  return null;

  return (
    <ProductsSelectImpl
      availableProductTags={availableProductTags}
      onTagIdsChange={onTagIdsChange}
    />
  );
}

function ProductsSelectImpl({
  availableProductTags,
  onTagIdsChange,
}: {
  availableProductTags: Array<Tag>;
  onTagIdsChange: (ids: Array<TagId>) => void;
}) {
  const [open, setOpen] = useState(false);
  const initialProductIds = useSelectedProductIds();
  const [tagIds, setTagIds] = useState(() => new Set(initialProductIds));

  const deriveNewTagSelection = useCallback(
    (tagId: TagId | null) => {
      const deriveNewTagSelection = deriveNewTagSelectionFactory(
        tagIds,
        getAllValidTagIds(getAllowedTagIds(availableProductTags))
      );
      return deriveNewTagSelection(tagId);
    },
    [tagIds, availableProductTags]
  );

  function closeMenu() {
    setOpen(false);
    onTagIdsChange(Array.from(tagIds));
  }

  if (availableProductTags.length === 0) {
    return null;
  }

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => setOpen(true)}
          analyticsEvent="tag_station_filter_clicked"
          className={cn(
            "border-2 rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <ProductsSelectLabel />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] p-0 rounded-lg flex flex-col"
        onEscapeKeyDown={closeMenu}
        onInteractOutside={closeMenu}
      >
        <ProductsFilters
          tags={availableProductTags}
          selectedIds={tagIds}
          onTagClick={(tagId) => {
            setTagIds(deriveNewTagSelection(tagId));
          }}
        />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function ProductsSelectLabel() {
  const { i18n } = useLingui();
  return (
    <span className="select-none flex gap-2 justify-between items-center">
      <PackageIcon />
      {i18n.t("Products")}
    </span>
  );
}

function ProductsFilters({
  tags,
  selectedIds,
  onTagClick,
}: {
  tags: Array<Tag>;
  selectedIds: Set<TagId>;
  onTagClick: (tagId: TagId | null) => void;
}) {
  return (
    <>
      <div className="max-h-[290px] overflow-y-auto py-1">
        {tags
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((tag) => {
            return (
              <DropdownMenuCheckboxItem
                key={tag.id}
                className="mx-1 py-1"
                checked={selectedIds.has(tag.id)}
                onClick={() => onTagClick(tag.id)}
              >
                <span className="flex items-center gap-2">
                  <PackageIcon className="w-4" style={{ color: tag.color }} />
                  <span className="grow">{tag.name}</span>
                </span>
              </DropdownMenuCheckboxItem>
            );
          })}
      </div>
    </>
  );
}
