import { MinutesInAreaChart } from "@/view/pages/line-id-area-id/minutes-in-area-chart";
import { NumberOfWorkersChart } from "@/view/pages/line-id-area-id/number-of-workers-chart";

export function AreaActivity() {
  return (
    <div className="grid gap-6">
      <NumberOfWorkersChart />
      <MinutesInAreaChart />
    </div>
  );
}
