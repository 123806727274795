import { createContext, useContext } from "react";

import { LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";

export const SelectedShiftIdsContext = createContext<Array<ShiftId>>([]);

export function useSelectedShiftIds() {
  const ctx = useContext(SelectedShiftIdsContext);
  if (!ctx) {
    throw new Error(
      "useSelectedShift must be used inside SelectedShiftProvider"
    );
  }
  return ctx;
}

const searchParamKey = "shiftIds";

export function parseShiftIdsFromSearchParams(
  searchParams: URLSearchParams,
  line: LineWithStations
) {
  const maybeShiftIds = searchParams.get(searchParamKey);
  const decodedIds = decodeShiftIds(maybeShiftIds);
  const validShiftIds = line.shifts.map((shift) => shift.id);
  const shiftIds = validShiftIds.filter((shiftId) =>
    decodedIds ? decodedIds.includes(shiftId) : true
  );
  return shiftIds.length > 0 ? shiftIds : validShiftIds;
}

export function encodeShiftIdsToSearchParams(
  searchParams: URLSearchParams,
  shiftIds: Array<ShiftId>
) {
  searchParams.set(searchParamKey, encodeShiftIds(shiftIds));
  return searchParams;
}

export function encodeShiftIds(arr: Array<ShiftId>) {
  return [...new Set(arr)].join(".");
}

function decodeShiftIds(
  arr: string | null | undefined
): Array<string> | undefined {
  return arr?.split(".").filter(Boolean);
}
