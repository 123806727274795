import { z } from "zod";

import { lineIdSchema } from "./line";

export const tagIdSchema = z.string().brand<"TagId">();
const tagTypeSchema = z.enum(["System", "User", "CV", "Product"]);

export const tagSchema = z.object({
  id: tagIdSchema,
  name: z.string(),
  color: z.string(),
  type: tagTypeSchema,
});

const getAllTagsFiltersSchema = z.object({
  lineId: lineIdSchema,
});

const createTagParamsSchema = z.object({
  lineId: lineIdSchema,
  name: z.string().min(1),
});

export type TagId = z.infer<typeof tagIdSchema>;
export type TagType = z.infer<typeof tagTypeSchema>;
export type Tag = z.infer<typeof tagSchema>;
export type GetAllTagsFilters = z.infer<typeof getAllTagsFiltersSchema>;
export type CreateTagParamsSchema = z.infer<typeof createTagParamsSchema>;

type TagsByType = Record<TagType, Array<Tag>>;
type TagsById = Record<TagId, Tag>;

export type TagStore = {
  tagsById: TagsById;
  tagsByType: TagsByType;
  allTagsIds: Array<TagId>;
};

type GetAllowedTags = (store: TagStore) => Array<Tag>;

/**
 * Filters and returns the last three valid tag IDs from the provided list of tag IDs.
 *
 * @param tagIds - An array of tag IDs to be validated.
 * @param allowedTagsIds - A set of allowed tag IDs for validation.
 * @returns An array containing up to the last three valid tag IDs.
 */
export function getLimitedValidTagIds(
  allowedTagsIds: Set<TagId>
): (tagIds: Array<string>) => Array<TagId> {
  return (tagIds) => {
    // only return the last 3 valid tags
    return getAllValidTagIds(allowedTagsIds)(tagIds).slice(-3);
  };
}

/**
 * Retrieves all valid tag IDs from the provided list of ids.
 *
 * This function filters the given `tagIds` array and returns only those
 * tag IDs that are valid according to the `tagIdSchema` and are present
 * in the `allowedTagsIds` set.
 *
 * @param tagIds - An array of tag IDs to be validated.
 * @param allowedTagsIds - A set of allowed tag IDs.
 * @returns An array of valid tag IDs.
 */
export function getAllValidTagIds(
  allowedTagsIds: Set<TagId>
): (tagIds: Array<string>) => Array<TagId> {
  return (tagIds) => {
    const validTagIds: Array<TagId> = [];
    for (const tagId of tagIds) {
      const result = tagIdSchema.safeParse(tagId);
      if (!result.success) continue;
      if (!allowedTagsIds.has(result.data)) continue;
      validTagIds.push(result.data);
    }
    return validTagIds;
  };
}

export function deriveNewTagSelectionFactory(
  selectedIds: Set<TagId>,
  onAddTagToSet: (tagIds: Array<TagId>) => Iterable<TagId>
): (tagId: TagId | null) => Set<TagId> {
  return (tagId: TagId | null) => {
    let selectedTagIds: Set<TagId>;
    if (tagId === null) {
      selectedTagIds = new Set();
    } else if (selectedIds.has(tagId)) {
      selectedIds.delete(tagId);
      selectedTagIds = new Set(selectedIds);
    } else {
      selectedTagIds = new Set(onAddTagToSet([...selectedIds, tagId]));
    }
    return selectedTagIds;
  };
}

export function getAllowedTagIds(allowedTags: Array<Tag>): Set<TagId> {
  return new Set(allowedTags.map((tag) => tag.id));
}

export function getTagsForTypes(types: Array<TagType>): GetAllowedTags {
  return (store) => types.flatMap((type) => store.tagsByType[type]);
}

export function normalizeAllTagsResponse(tags: Array<Tag>): TagStore {
  const allTagsIds: Array<TagId> = [];
  const tagsById: TagsById = {};
  const tagsByType: TagsByType = {
    System: [],
    User: [],
    CV: [],
    Product: [],
  };

  for (const tag of tags) {
    allTagsIds.push(tag.id);
    tagsById[tag.id] = tag;
    tagsByType[tag.type].push(tag);
  }

  return {
    tagsById,
    tagsByType,
    allTagsIds,
  };
}
