import { queryOptions, useSuspenseQueries } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext } from "react";

import { BaseError } from "@/domain/common/errors";
import {
  MetricsStore,
  normalizeMetricsResponse,
} from "@/domain/common/metrics";
import { TimeRangeFilter } from "@/domain/common/time-filter";
import { getSelectedShifts, Shift } from "@/domain/shifts";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedLine } from "../line-id/use-selected-line";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import { useSelectedStation } from "./use-selected-station";

const TrendsStatisticsContext = createContext<MetricsStore | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useTrendsStatistics() {
  const trendsStatistics = useContext(TrendsStatisticsContext);
  if (!trendsStatistics) {
    throw new Error(
      "useTrendsStatistics must be used within a TrendsStatisticsProvider"
    );
  }
  return trendsStatistics;
}

function shiftsToTimeRange(shifts: Array<Shift>): TimeRangeFilter {
  const values = shifts.map((s) => ({ start: s.start, end: s.end }));
  return { values };
}

// eslint-disable-next-line react-refresh/only-export-components
export function useTrendsStatisticsParams(): [
  ReturnType<typeof useSelectedLine>,
  ReturnType<typeof useSelectedShiftIds>,
  ReturnType<typeof useSelectedDateRange>,
] {
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();
  return [line, shiftIds, dateRange];
}

/**
 * Provides trends statistics down the component tree.
 */
export function TrendsStatisticsProvider({ children }: PropsWithChildren) {
  const apiClient = useApiClient();
  const [line, shiftIds, dateRange] = useTrendsStatisticsParams();
  const station = useSelectedStation();
  const [linesQuery, stationsQuery] = useSuspenseQueries({
    queries: [
      queryOptions<MetricsStore, BaseError>({
        queryKey: ["lines-with-metrics", line.factoryId, dateRange, shiftIds],
        queryFn: ({ signal }) => {
          const timeRange = shiftsToTimeRange(
            getSelectedShifts(line.shifts, shiftIds)
          );
          return (
            apiClient
              .getLinesWithMetrics(
                { factoryId: line.factoryId, dateRange, timeRange },
                { signal }
              )
              /**
               * @todo consider moving to the `select` option
               * @see {@link https://tkdodo.eu/blog/react-query-data-transformations#1-in-the-queryfn | React Query Data Transformations}
               */
              .then(normalizeMetricsResponse)
          );
        },
        staleTime: Infinity,
        refetchInterval: 1000 * 60 * 30, // Half an hour
        refetchIntervalInBackground: true,
      }),
      queryOptions<MetricsStore, BaseError>({
        queryKey: ["stations-with-metrics", line.id, dateRange, shiftIds],
        queryFn: ({ signal }) => {
          const timeRange = shiftsToTimeRange(
            getSelectedShifts(line.shifts, shiftIds)
          );
          return (
            apiClient
              .getStationsWithMetrics(
                { lineId: line.id, dateRange, timeRange },
                { signal }
              )
              /**
               * @todo consider moving to the `select` option
               * @see {@link https://tkdodo.eu/blog/react-query-data-transformations#1-in-the-queryfn | React Query Data Transformations}
               */
              .then(normalizeMetricsResponse)
          );
        },
        staleTime: Infinity,
        refetchInterval: 1000 * 60 * 30, // Half an hour
        refetchIntervalInBackground: true,
      }),
    ],
  });
  return (
    <TrendsStatisticsContext.Provider
      value={(station ? stationsQuery : linesQuery).data}
    >
      {children}
    </TrendsStatisticsContext.Provider>
  );
}
