import { format, isSameHour } from "date-fns";
import { z } from "zod";

import { TimeGranularity } from "@/domain/statistics";

import { Entity, EntityWithMetrics } from "./entity";

// factory, line, group specific
const upperLevelKpiTypes = ["output", "tact"] as const;
// station specific
const stationOnlyKpiTypes = ["cycle_count", "cycle_time"] as const;
// shared by all levels
const sharedKpiTypes = ["workers", "activity"] as const;

const kpiTypes = [
  ...upperLevelKpiTypes,
  ...stationOnlyKpiTypes,
  ...sharedKpiTypes,
] as const;

const kpiValueTypes = [
  "actual",
  "differential",
  "active",
  "interrupted",
  "idle",
  "empty",
] as const;

const kpiDataPoint = z.object({
  date: z.date(),
  value: z.number().nonnegative(), // >= 0
});

const kpiMetricValue = z.object({
  type: z.enum(kpiValueTypes),
  value: z.number().nonnegative(), // >= 0
  overTime: z.array(kpiDataPoint),
});

export const kpiMetric = z.object({
  type: z.enum(kpiTypes),
  average: z.number().nonnegative(), // >= 0
  values: z.array(kpiMetricValue),
});

export type KpiType = (typeof kpiTypes)[number];
export type KpiValueType = (typeof kpiValueTypes)[number];
export type KpiMetric = z.infer<typeof kpiMetric>;

// entityId__kpiType__index
export type MetricKey = `${string}__${KpiMetric["type"]}__${number}`;
export type MetricId = {
  entityId: string;
  type: KpiType;
  idx: number;
};

export type KpiMetricWithTrendData = KpiMetric & {
  trendDataMap: Map<string, number>;
  min: number;
  max: number;
};

export type MetricsStore = {
  timeGranularity: TimeGranularity;
  entityIds: Set<string>;
  timeRangeIdxs: Array<number>;
  entityById: Record<string, Entity>;
  metricByKey: Record<MetricKey, KpiMetricWithTrendData>;
  timeAxis: Array<Date>;
  getEntityById(id: string): Entity;
  getMetricByKey(
    id: string,
    type: KpiType,
    idx: number
  ): KpiMetricWithTrendData | undefined;
};

export function normalizeMetricsResponse(data: {
  timeGranularity: TimeGranularity;
  data: Array<Array<EntityWithMetrics>>;
}): MetricsStore {
  const times: Array<Date> = [];
  const entitiesPerTimeRange = data.data;

  const store: MetricsStore = {
    timeGranularity: data.timeGranularity,
    entityIds: new Set(),
    timeRangeIdxs: [],
    entityById: {},
    metricByKey: {},
    timeAxis: [],
    getEntityById(id: string) {
      return store.entityById[id];
    },
    getMetricByKey(id: string, type: KpiType, idx: number) {
      const key = `${id}__${type}__${idx}` as const;
      const metric = store.metricByKey[key];
      return metric;
    },
  };

  for (let idx = 0; idx < entitiesPerTimeRange.length; idx++) {
    const entitiesWithMetrics = entitiesPerTimeRange[idx];
    store.timeRangeIdxs.push(idx);

    for (const entity of entitiesWithMetrics) {
      store.entityIds.add(entity.id);
      store.entityById[entity.id] = {
        id: entity.id,
        name: entity.name,
      };

      for (const metric of entity.metrics) {
        const metricKey = metricKeyFromId({
          entityId: entity.id,
          type: metric.type,
          idx,
        });
        store.metricByKey[metricKey] = {
          ...metric,
          min: Infinity,
          max: 0,
          trendDataMap: new Map(),
        };

        for (const v of metric.values) {
          if (v.type != "active" && v.type != "actual") continue;

          for (const o of v.overTime) {
            store.metricByKey[metricKey].trendDataMap.set(
              dateToTrendDataKey(o.date),
              o.value
            );

            if (o.value > store.metricByKey[metricKey].max) {
              store.metricByKey[metricKey].max = o.value;
            }

            if (o.value < store.metricByKey[metricKey].min) {
              store.metricByKey[metricKey].min = o.value;
            }

            if (!times.some((d) => isSameHour(d, o.date))) {
              times.push(o.date);
            }
          }
        }
      }
    }
  }

  store.timeAxis = times.sort((a, b) => a.getTime() - b.getTime());
  return store;
}

export function metricKeyFromId(id: MetricId): MetricKey {
  return `${id.entityId}__${id.type}__${id.idx}` as const;
}

export function dateToTrendDataKey(date: Date) {
  return format(date, "yyyy-MM-dd_HH:mm:ss");
}
