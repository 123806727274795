import { LoadingShimmer } from "@/view/components/loading-shimmer";

import { NotificationSettingsLayout } from "./notification-settings-layout";
import {
  SettingsSection,
  SettingsSectionContent,
} from "./notification-settings-section";

export function NotificationSettingsLoadingFallback() {
  return (
    <NotificationSettingsLayout>
      <SettingsSection>
        <LoadingShimmer className="w-64" />
        <SettingsSectionContent className="flex flex-col gap-6">
          <LoadingShimmer />
          <LoadingShimmer />
        </SettingsSectionContent>
      </SettingsSection>
      <SettingsSection>
        <LoadingShimmer className="w-64" />
        <SettingsSectionContent className="flex flex-col gap-6">
          <LoadingShimmer />
          <LoadingShimmer />
          <LoadingShimmer />
        </SettingsSectionContent>
      </SettingsSection>
    </NotificationSettingsLayout>
  );
}
