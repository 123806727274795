import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorStatus } from "../line-id/error-status";
import { useVideosAndTagsParams } from "./videos-and-tags-provider";

export function VideosAndTagsErrorBoundary({ children }: PropsWithChildren) {
  const params = useVideosAndTagsParams();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          // To avoid TS error as `resetKeys` prop doesn't support readonly arrays
          resetKeys={Array.from(params)}
          onReset={reset}
          fallbackRender={(props) => (
            <div className="h-full w-full flex items-center justify-center">
              <ErrorStatus
                error={props.error}
                onRetry={props.resetErrorBoundary}
              />
            </div>
          )}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
