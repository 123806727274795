import { useLingui } from "@lingui/react";
import {
  animated,
  Interpolation,
  SpringValue,
  to,
  useSpring,
  useTransition,
} from "@react-spring/web";
import {
  ScaleBand,
  scaleBand,
  ScaleLinear,
  scaleLinear,
  ScaleTime,
  scaleTime,
} from "d3-scale";
import { curveMonotoneX as d3curve, line as d3line } from "d3-shape";
import { addHours, endOfHour, startOfHour } from "date-fns";
import React, {
  PropsWithChildren,
  SVGAttributes,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { TimeGranularity } from "@/domain/statistics";
import { CoffeeBreakIcon, Group } from "@/view/components";
import { Label } from "@/view/components/label";
import { Switch } from "@/view/components/switch";
import { cn, formatCycleTimeDifference } from "@/view/utils";

type DataPoint<T> = {
  label: T;
  value: Record<string, number>;
};

type Dimensions = {
  width: number;
  height: number;
};

const axisLabelFontSize = 14;
const axisTickSize = 12;
const axisTextOffset = axisTickSize + 12;
const marginLeft = axisTickSize * 12; // width space for y-axis legend
const marginTop = axisTickSize * 2; // some top padding
const marginBottom = axisTickSize * 8;
const breakTooltipAreaHeight = 40;

function TruncatedText({
  children,
  maxWidth,
  ellipsis = "..",
  className,
  ...props
}: SVGAttributes<SVGTextElement> & {
  children: string;
  maxWidth: number;
  ellipsis?: string;
}) {
  const ref = useRef<SVGTextElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    const node = ref.current;
    node.textContent = children;
    let textLength = node.getComputedTextLength();
    let textContent = node.textContent ?? "";

    while (textLength > maxWidth && textContent.length > 0) {
      textContent = textContent.slice(0, -2).trimEnd();
      node.textContent = textContent + ellipsis;
      textLength = node.getComputedTextLength();
    }
  }, [children, maxWidth, ellipsis]);

  return (
    <text
      ref={ref}
      className={cn("text-brand-gray-4", className)}
      fontSize={axisLabelFontSize}
      alignmentBaseline="text-before-edge"
      textAnchor="middle"
      stroke="white"
      strokeWidth="4"
      paintOrder="stroke"
      {...props}
    >
      {children}
    </text>
  );
}

export function BasicLineChart({
  xAxisTitle,
  xLabelFormatter = (value) => value.toISOString(),
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  dimensions,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: Date) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<Date>>;
  dimensions: Dimensions;
}) {
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data,
    axisTickSize * 4
  );

  const xAxisScale = scaleBand<Date>()
    .domain(data.map((d) => d.label))
    .rangeRound([0, chartWidth]);

  return (
    <BasicChartWrapper
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yValueFormatter={yValueFormatter}
      yAxisScale={yAxisScale}
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      containerWidth={dimensions.width}
      containerHeight={dimensions.height}
    >
      <Group className="text-brand-blue-1" left={xAxisScale.bandwidth() / 2}>
        <DataLine
          dataKey="value"
          xAxisScale={xAxisScale}
          yAxisScale={yAxisScale}
          data={data}
          chartHeight={chartHeight}
          yValueFormatter={yValueFormatter}
        />
      </Group>
      <Group
        className="text-brand-blue-1"
        top={chartHeight}
        left={xAxisScale.bandwidth() / 2}
      >
        <AxisBottom
          xAxisScale={xAxisScale}
          xLabelFormatter={xLabelFormatter}
          ticks={xAxisScale.domain() as Array<Date>}
        />
      </Group>
    </BasicChartWrapper>
  );
}

/**
 * TODO: Remove from common components
 */
export function OutputByTimeChart({
  xAxisTitle,
  xLabelFormatter = (value) => value.toISOString(),
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  workingHours,
  dimensions,
  granularity,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  xLabelFormatter?: (value: Date) => string;
  yValueFormatter?: (value: number) => string;
  data: Array<DataPoint<Date>>;
  workingHours: Array<[Date, Date]> | null;
  dimensions: Dimensions;
  granularity: TimeGranularity;
}) {
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data,
    axisTickSize * 4
  );
  const [showTarget, setShowTarget] = useState(false);

  const ticks = useMemo(() => {
    const labels = data.map((d) => d.label);

    if (granularity !== "hour") {
      return labels;
    }

    const firstTick = startOfHour(labels[0]);
    const lastLabel = labels[labels.length - 1];

    const lastTick =
      lastLabel.getMinutes() > 0
        ? startOfHour(addHours(lastLabel, 1))
        : endOfHour(lastLabel);

    const items = [];
    let currentTick = firstTick;
    while (currentTick <= lastTick) {
      items.push(currentTick);
      currentTick = addHours(currentTick, 1);
    }

    return items;
  }, [data, granularity]);

  const ticksNumbers = ticks.map((d) => +d);
  const xAxisScale = scaleTime()
    .domain([Math.min(...ticksNumbers), Math.max(...ticksNumbers)])
    .range(
      data.length <= 4
        ? [chartWidth / 4, (chartWidth * 3) / 4]
        : [60, chartWidth - 60]
    );

  const maxY = data.reduce(
    (max, d) => Math.max(max, d.value.value, d.value.target),
    0
  );

  const breaks =
    workingHours?.reduce((acc: Array<[Date, Date]>, [, end], index) => {
      if (index < workingHours.length - 1) {
        const nextStart = workingHours[index + 1][0];
        // Ensure we capture breaks even if the end of one period is the same as the start of the next
        if (end < nextStart) {
          acc.push([end, nextStart]);
        }
      }
      return acc;
    }, []) ?? [];

  return (
    <>
      <OutpuByTimeLegend
        showTarget={showTarget}
        setShowTarget={setShowTarget}
      />
      <BasicChartWrapper
        xAxisTitle={xAxisTitle}
        yAxisTitle={yAxisTitle}
        yValueFormatter={yValueFormatter}
        yAxisScale={yAxisScale}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        containerWidth={dimensions.width}
        containerHeight={dimensions.height}
        tooltipSlot={
          <>
            <div
              className="absolute"
              style={{
                left: marginLeft,
                top: marginTop,
                width: chartWidth,
                height: breakTooltipAreaHeight,
              }}
            >
              <BreakTooltip
                breaks={breaks}
                xAxisScale={xAxisScale}
                xLabelFormatter={xLabelFormatter}
              />
            </div>
            <div
              className="absolute"
              style={{
                left: marginLeft,
                top: yAxisScale(maxY) + marginTop,
                width: chartWidth,
                height: chartHeight - yAxisScale(maxY),
              }}
            >
              <OutputByTimeTooltip
                data={data}
                xAxisScale={xAxisScale}
                yAxisScale={yAxisScale}
                chartHeight={chartHeight}
                showTarget={showTarget}
                yValueFormatter={yValueFormatter}
                xLabelFormatter={xLabelFormatter}
              />
            </div>
          </>
        }
      >
        {breaks.map(([start, end], index) => {
          const startX = xAxisScale(start) ?? 0;
          const endX = xAxisScale(end) ?? 0;
          const width = endX - startX;
          const height = chartHeight;

          return (
            <g key={`break-${index}`}>
              <rect
                x={startX}
                y={0}
                width={width}
                height={height}
                fill="#2C98FF0D"
              />
              <line
                x1={startX}
                y1={0}
                x2={startX}
                y2={height}
                stroke="#18A0FB"
                strokeWidth={1}
              />
              <line
                x1={endX}
                y1={0}
                x2={endX}
                y2={height}
                stroke="#18A0FB"
                strokeWidth={1}
              />
              <g transform={`translate(${startX}, 10)`} className="relative">
                <g transform={`translate(${width / 2 - 6}, 0)`}>
                  <CoffeeBreakIcon className="pointer-events-none" />
                </g>
              </g>
            </g>
          );
        })}

        {showTarget && (
          <Group className="text-brand-neutral">
            <DataLine
              dataKey="target"
              xAxisScale={xAxisScale}
              yAxisScale={yAxisScale}
              data={data}
              chartHeight={chartHeight}
              showValues={false}
              futurePointsOpacity={1}
              dashed
            />
          </Group>
        )}
        <Group className="text-brand-blue-1">
          <DataLine
            dataKey="value"
            xAxisScale={xAxisScale}
            yAxisScale={yAxisScale}
            data={data}
            chartHeight={chartHeight}
          />
        </Group>
        <Group top={chartHeight}>
          <AxisBottom
            xAxisScale={xAxisScale}
            xLabelFormatter={xLabelFormatter}
            ticks={ticks}
          />
        </Group>
      </BasicChartWrapper>
    </>
  );
}

function OutpuByTimeLegend({
  showTarget,
  setShowTarget,
}: {
  showTarget: boolean;
  setShowTarget: (checked: boolean) => void;
}) {
  const { i18n } = useLingui();
  return (
    <div className="px-12 pt-6 flex justify-between">
      <div className="flex items-center space-x-2">
        <Label htmlFor="show-target">
          {i18n.t("cycleCountTargetPrediction")}
        </Label>
        <Switch
          id="show-target"
          checked={showTarget}
          onCheckedChange={setShowTarget}
        />
      </div>
      {showTarget && (
        <ul className="flex gap-6">
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-neutral block" />
            <span>{i18n.t("cycleCountTarget")}</span>
          </li>
          <li className="inline-flex items-center gap-2">
            <span className="rounded-full h-3 w-3 bg-brand-blue-1 block" />
            <span>{i18n.t("cycleCountActual")}</span>
          </li>
        </ul>
      )}
    </div>
  );
}

function useNow() {
  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setNow(new Date()), 10 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);
  return now;
}

function ChartTooltip({
  children,
  position = "top",
  style,
}: {
  children: React.ReactNode;
  position?: "top" | "bottom";
  style?: {
    transform?: Interpolation<number | string, string>;
    opacity?: SpringValue<number>;
  };
}) {
  return (
    <animated.div
      className="absolute z-20 pointer-events-none top-0 left-0"
      style={style}
    >
      <div
        className={cn(
          "border-solid border-t-white border-t-8 border-b-0",
          "border-x-transparent border-x-8",
          "h-4 w-4 absolute z-10 bottom-0 left-1/2 -translate-x-1/2",
          position === "bottom" ? "-top-4 rotate-180" : "-bottom-4"
        )}
      />
      <div
        className={cn("bg-white shadow-lg p-4 rounded-lg", "whitespace-nowrap")}
      >
        {children}
      </div>
    </animated.div>
  );
}

function BreakTooltip({
  breaks,
  xAxisScale,
  xLabelFormatter,
}: {
  breaks: Array<[Date, Date]>;
  xAxisScale: ScaleTime<number, number, never>;
  xLabelFormatter: (value: Date) => string;
}) {
  const { i18n } = useLingui();
  const [selectedRange, setSelectedRange] = useState<
    [Date, Date] | undefined
  >();

  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: selectedRange ? 1 : 0,
    x: 0,
  }));

  const handleMouseMove = (event: React.MouseEvent) => {
    const mouseX =
      event.clientX - event.currentTarget.getBoundingClientRect().left;
    const breakRange = breaks.find(([start, end]) => {
      const startX = xAxisScale(start) ?? 0;
      const endX = xAxisScale(end) ?? 0;
      return mouseX >= startX && mouseX <= endX;
    });

    if (breakRange) {
      setSelectedRange(breakRange);
      setTooltipProps({ opacity: 1 });

      const tooltipX =
        xAxisScale(breakRange[0]) +
        (xAxisScale(breakRange[1]) - xAxisScale(breakRange[0])) / 2;

      tooltipProps.x.set(tooltipX);
    } else {
      setSelectedRange(undefined);
      setTooltipProps({ opacity: 0 });
    }
  };

  return (
    <>
      {selectedRange && (
        <ChartTooltip
          position="bottom"
          style={{
            transform: tooltipProps.x.to(
              (x) => `translate(calc(${x}px - 50%), 40px)`
            ),
            opacity: tooltipProps.opacity,
          }}
        >
          <p className="text-brand-black text-sm text-center mb-1">
            {i18n.t("Break")}
          </p>
          <p className="text-brand-gray-4 font-thin text-sm text-center">
            {`${xLabelFormatter(selectedRange[0])}-${xLabelFormatter(selectedRange[1])}`}
          </p>
        </ChartTooltip>
      )}
      <div
        className="w-full absolute left-0 top-0 h-full"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setSelectedRange(undefined);
          setTooltipProps({ opacity: 0 });
        }}
      />
    </>
  );
}

function OutputByTimeTooltip({
  data,
  xAxisScale,
  yAxisScale,
  chartHeight,
  showTarget,
  yValueFormatter = (value) => `${value}`,
  xLabelFormatter,
}: {
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  xAxisScale: ScaleTime<number, number, never>;
  yAxisScale: ScaleLinear<number, number>;
  showTarget: boolean;
  yValueFormatter?: (value: number) => string;
  xLabelFormatter: (value: Date) => string;
}) {
  const { i18n } = useLingui();
  const now = useNow();
  const nowPoint = useMemo(() => {
    const pointIdx = data.findIndex((d) => d.label >= now);
    return pointIdx > 0 ? data[pointIdx - 1] : undefined;
  }, [data, now]);
  const [selectedPoint, setSelectedPoint] = useState<
    DataPoint<Date> | undefined
  >();
  const nowPointPosX = nowPoint ? (xAxisScale(nowPoint.label) ?? 0) : 0;
  const nowPointPosY = nowPoint
    ? (yAxisScale(nowPoint.value["value"]) ?? 0)
    : 0;
  const [tooltipProps, setTooltipProps] = useSpring(() => ({
    opacity: nowPoint ? 1 : 0,
    x: nowPointPosX,
    y: nowPointPosY,
  }));

  const handleMouseMove = (event: React.MouseEvent) => {
    const mouseX =
      event.clientX - event.currentTarget.getBoundingClientRect().left;
    const closestPoint = data.reduce((prev, curr) => {
      const prevDistance = Math.abs(xAxisScale(prev.label) - mouseX);
      const currDistance = Math.abs(xAxisScale(curr.label) - mouseX);
      return currDistance < prevDistance ? curr : prev;
    });

    setSelectedPoint(closestPoint);
    setTooltipProps({
      x: xAxisScale(closestPoint.label) ?? 0,
      y: yAxisScale(closestPoint.value["value"]) ?? 0,
      opacity: 1,
    });
  };

  return (
    <>
      {selectedPoint && (
        <ChartTooltip
          style={{
            transform: to(
              [tooltipProps.x, tooltipProps.y],
              (x, y) => `translate(calc(${x}px - 50%), calc(${y}px - 240px))`
            ),
          }}
        >
          <p className="text-base text-center mb-1 text-brand-gray-4 font-thin">
            {xLabelFormatter(selectedPoint.label)}
          </p>
          {showTarget && (
            <p className="flex justify-between gap-6">
              <span>{i18n.t("cycleCountTarget")}:</span>
              <span className="font-bold">
                {yValueFormatter(selectedPoint.value["target"] ?? 0)}
              </span>
            </p>
          )}
          <p className="flex justify-between gap-6">
            <span>{i18n.t("cycleCountActual")}:</span>
            <span className="font-bold">
              {yValueFormatter(selectedPoint.value["value"] ?? 0)}
            </span>
          </p>
        </ChartTooltip>
      )}
      <animated.div
        className="bg-brand-blue-1 absolute z-10 pointer-events-none bottom-0"
        style={{
          width: 2,
          height: chartHeight,
          transform: tooltipProps.x.to((x) => `translate(${x}px, 0px)`),
          opacity: tooltipProps.opacity,
        }}
      />
      <div
        className="absolute left-0 top-0 right-0 h-full"
        onMouseMove={handleMouseMove}
        onMouseLeave={() => {
          setSelectedPoint(nowPoint);
          setTooltipProps({
            x: nowPointPosX,
            y: nowPointPosY,
            opacity: nowPoint ? 1 : 0,
          });
        }}
      />
    </>
  );
}

function AxisBottom({
  xLabelFormatter = (value) => value.toISOString(),
  xAxisScale,
  ticks,
}: {
  xLabelFormatter?: (value: Date) => string;
  xAxisScale: ScaleTime<number, number, never> | ScaleBand<Date>;
  ticks: Array<Date>;
}) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  return (
    <>
      {ticks.map((date) => {
        const x = xAxisScale(date) ?? 0;

        return (
          <animated.g key={date.toJSON()} opacity={axisStyle.opacity}>
            <line
              x1={x}
              x2={x}
              y1={0}
              y2={12}
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            />
            <text
              x={x}
              y={axisTextOffset}
              fontSize={axisLabelFontSize}
              alignmentBaseline="text-before-edge"
              textAnchor="middle"
              className="fill-brand-gray-4"
            >
              {xLabelFormatter(date)}
            </text>
          </animated.g>
        );
      })}
    </>
  );
}

type TimeDataPoint = {
  y: number;
  x: number;
  label: Date;
  value: number;
};

function DataLine({
  xAxisScale,
  yAxisScale,
  dataKey,
  data,
  chartHeight,
  showValues = true,
  dashed = false,
  futurePointsOpacity = 0.25,
  yValueFormatter = (value) => `${value}`,
}: {
  xAxisScale: ScaleTime<number, number, never> | ScaleBand<Date>;
  yAxisScale: ScaleLinear<number, number, never>;
  dataKey: string;
  data: Array<DataPoint<Date>>;
  chartHeight: number;
  showValues?: boolean;
  dashed?: boolean;
  futurePointsOpacity?: number;
  yValueFormatter?: (value: number) => string;
}) {
  const now = new Date();
  const pastPoints: Array<TimeDataPoint> = [];
  const futurePoints: Array<TimeDataPoint> = [];

  for (const item of data) {
    const label = item.label;
    const value = item.value[dataKey] ?? 0;
    const point = {
      label,
      value,
      y: yAxisScale(value) ?? 0,
      x: xAxisScale(item.label) ?? 0,
    };
    if (point.label < now) {
      pastPoints.push(point);
      // lets add last past point as the first future point
      futurePoints[0] = point;
    } else {
      futurePoints.push(point);
    }
  }

  const line = d3line<TimeDataPoint>()
    .x((d) => xAxisScale(d.label) ?? 0)
    .y((d) => yAxisScale(d.value) ?? 0)
    .curve(d3curve);

  const pastLineFrom = line(pastPoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const pastLineTo = line(pastPoints) ?? "";
  const futureLineFrom =
    line(futurePoints.map((d) => ({ ...d, value: 0 }))) ?? "";
  const futureLineTo = line(futurePoints) ?? "";

  return (
    <Group>
      {showValues && (
        <LineValues
          data={pastPoints}
          chartHeight={chartHeight}
          yValueFormatter={yValueFormatter}
        />
      )}
      <LinePath
        key={pastLineTo}
        pathFrom={pastLineFrom}
        pathTo={pastLineTo}
        opacity={1}
        dashed={dashed}
      />
      {futurePoints.length > 1 && (
        <LinePath
          key={futureLineTo}
          pathFrom={futureLineFrom}
          pathTo={futureLineTo}
          opacity={futurePointsOpacity}
          dashed={dashed}
        />
      )}
    </Group>
  );
}

function LineValues({
  data,
  chartHeight,
  yValueFormatter = (value) => `${value}`,
}: {
  chartHeight: number;
  yValueFormatter?: (value: number) => string;
  data: Array<TimeDataPoint>;
}) {
  const valuesTransition = useTransition<
    TimeDataPoint,
    { y: number; x: number; opacity: number; r: number }
  >(data, {
    keys: (d) => d.label.toJSON(),
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0, r: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    leave: ({ x }) => ({ y: 0, x, opacity: 0, r: 6 }),
    update: ({ x, y }) => ({ x, y, opacity: 1, r: 6 }),
    config: {
      mass: 1,
      tension: 144,
      friction: 16,
    },
    trail: 16,
  });

  return (
    <>
      {valuesTransition((style, d) => {
        return (
          <Group key={`point-${d.label.toJSON()}`}>
            <animated.circle
              fill="currentColor"
              className="text-brand-chart-value"
              cx={style.x}
              cy={style.y}
              opacity={style.opacity}
              r={style.r}
            />
            <animated.text
              className="text-brand-black font-semibold"
              x={style.x}
              y={style.y.to((it) => it - axisTextOffset)}
              opacity={style.opacity}
              fontSize={axisLabelFontSize}
              alignmentBaseline="middle"
              textAnchor="middle"
              stroke="white"
              strokeWidth="6"
              paintOrder="stroke"
            >
              {yValueFormatter(d.value)}
            </animated.text>
          </Group>
        );
      })}
    </>
  );
}

function LinePath({
  pathFrom,
  pathTo,
  opacity,
  dashed,
}: {
  pathFrom: string;
  pathTo: string;
  opacity: number;
  dashed: boolean;
}) {
  const springProps = useSpring({
    from: { opacity: 0, path: pathFrom },
    to: { opacity, path: pathTo },
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    delay: 250,
  });

  return (
    <animated.path
      d={springProps.path}
      opacity={springProps.opacity}
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeDasharray={dashed ? "4 8" : undefined}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  );
}

function useChartYAxis(
  dimensions: Dimensions,
  data: Array<{ value: Record<string, number> }>,
  minItemWidth: number,
  defaultMaxValue?: number
) {
  const minRequiredWidth = data.length * minItemWidth;
  const availableWidth = Math.floor(dimensions.width - marginLeft);
  const requiredWidth = Math.max(availableWidth, minRequiredWidth);
  const chartWidth = requiredWidth;
  const chartHeight = dimensions.height - marginBottom - marginTop;
  const maxValue = useMemo(() => {
    return (
      Math.max(
        ...data.map((d) => Math.max(...Object.values(d.value))),
        defaultMaxValue ?? 10
      ) * 1.2
    ); // max value +20% padding
  }, [data, defaultMaxValue]);

  const yAxisDomain = [0, maxValue];
  const yAxisScale = scaleLinear()
    .domain(yAxisDomain)
    .range([chartHeight, axisTextOffset]);

  return {
    yAxisScale,
    chartWidth,
    chartHeight,
  };
}

function BasicChartWrapper({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  yAxisScale,
  chartHeight,
  chartWidth,
  containerHeight,
  containerWidth,
  children,
  tooltipSlot = null,
}: PropsWithChildren<{
  xAxisTitle: string;
  yAxisTitle: string;
  chartHeight: number;
  chartWidth: number;
  containerHeight: number;
  containerWidth: number;
  yValueFormatter?: (value: number) => string;
  yAxisScale: ScaleLinear<number, number, never>;
  tooltipSlot?: React.ReactNode;
}>) {
  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });
  const yAxisMiddle = (chartHeight + marginTop) / 2;
  const ticks = yAxisScale.ticks(4).map((value) => {
    const yOffset = yAxisScale(value) ?? 0;
    const label = yValueFormatter(value);
    return { yOffset, label, value };
  });

  return (
    <div className="relative py-6">
      <svg
        className="relative z-10 bg-gradient-to-r from-white from-30% to-80% to-white/0"
        height={containerHeight}
        width={marginLeft}
      >
        <animated.g
          style={axisStyle}
          strokeWidth={1}
          stroke="currentColor"
          className="text-brand-gray-2 absolute left-0"
        >
          <text
            className="text-brand-gray-5"
            x={axisTextOffset}
            y={yAxisMiddle + (axisTextOffset * 3) / 2}
            fontSize={axisLabelFontSize}
            alignmentBaseline="middle"
            textAnchor="middle"
            transform={`rotate(-90, ${axisTextOffset}, ${yAxisMiddle})`}
            stroke="white"
            strokeWidth="4"
            paintOrder="stroke"
          >
            {yAxisTitle}
          </text>
          {ticks.map(({ yOffset, label, value }) => (
            <Group key={`axis-left-${value}`} top={yOffset + marginTop}>
              <text
                className="text-brand-gray-4"
                x={marginLeft - axisTextOffset / 2}
                fontSize={axisLabelFontSize}
                alignmentBaseline="middle"
                textAnchor="end"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {label}
              </text>
            </Group>
          ))}
        </animated.g>
      </svg>
      <div className="absolute inset-0 top-6">
        <svg className="w-full" height={containerHeight}>
          <animated.g
            style={axisStyle}
            strokeWidth={1}
            stroke="currentColor"
            className="text-brand-gray-2"
          >
            <Group left={marginLeft} top={marginTop}>
              <text
                className="text-brand-gray-5"
                x={(containerWidth - marginLeft) / 2}
                y={chartHeight + axisTextOffset * 5}
                fontSize={axisLabelFontSize}
                alignmentBaseline="text-after-edge"
                textAnchor="middle"
                stroke="white"
                strokeWidth="4"
                paintOrder="stroke"
              >
                {xAxisTitle}
              </text>
              {ticks.map(({ yOffset, value }) => (
                <Group
                  key={`grid-${value}`}
                  top={yOffset}
                  strokeWidth={1}
                  stroke="currentColor"
                  className="text-brand-gray-2"
                >
                  <line x2={chartWidth + marginLeft} />
                </Group>
              ))}
            </Group>
          </animated.g>
        </svg>
      </div>
      <div className="absolute inset-0 top-6 overflow-auto">
        <svg className="h-full min-w-full" width={chartWidth + marginLeft}>
          <Group left={marginLeft} top={marginTop}>
            {children}
          </Group>
        </svg>
        {tooltipSlot}
      </div>
    </div>
  );
}

function getMiddleValue(value: [number, number]) {
  const [start, end] = value;
  return Math.round((start + end) / 2);
}

function getBinSize([start, end]: [number, number]) {
  return Math.abs(end - start) || 1;
}

export function CycleVarianceChart({
  xAxisTitle,
  yAxisTitle,
  yValueFormatter = (value) => `${value}`,
  data,
  mean,
  dimensions,
  onBarClick,
}: {
  xAxisTitle: string;
  yAxisTitle: string;
  yValueFormatter?: (value: number) => string;
  data: Array<{
    label: [number, number];
    value: number;
  }>;
  target: number;
  mean: number;
  dimensions: Dimensions;
  onBarClick?: (value: [number, number]) => void;
}) {
  const { i18n } = useLingui();
  const { chartWidth, chartHeight, yAxisScale } = useChartYAxis(
    dimensions,
    data.map((d) => ({ ...d, value: { value: d.value } })),
    axisTickSize * 3
  );

  const binSizeInSeconds = getBinSize(data[0].label);
  const domain = data.flatMap((d) => d.label);

  const maxValue = Math.max(...domain);
  const minValue = Math.min(...domain);
  const valueDiff = maxValue - minValue;

  const numberOfBars = Math.floor(valueDiff / binSizeInSeconds);

  const xAxisScale = scaleLinear()
    .domain([minValue, maxValue])
    .rangeRound([axisTickSize * 4, chartWidth - axisTickSize * 4]);

  const [xStart, xEnd] = xAxisScale.range();

  const bandWidth = Math.floor((xEnd - xStart) / numberOfBars);
  const barWidth = bandWidth;
  const meanPosX = xAxisScale(mean) ?? 0;

  const bars = data.map((d, index) => ({
    range: d.label,
    middle: getMiddleValue(d.label),
    value: d.value,
    x: xAxisScale(d.label[0]) + index * 2, // 2px gap between bars
    y: yAxisScale(d.value) ?? 0,
  }));

  const axisStyle = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { mass: 10 },
  });

  const transition = useTransition<
    (typeof bars)[number],
    { y: number; x: number; opacity: number }
  >(bars, {
    keys: (d) => d.middle,
    from: ({ x }) => ({ x, y: chartHeight, opacity: 0 }),
    enter: ({ x, y }) => ({ x, y, opacity: 1 }),
    leave: ({ x, y }) => ({ x: x - bandWidth / 2, y, opacity: 0 }),
    update: ({ x, y }) => ({ x, y, opacity: 1 }),
    config: {
      mass: 1,
      tension: 1500,
      friction: 100,
    },
    trail: 24,
  });

  return (
    <BasicChartWrapper
      xAxisTitle={xAxisTitle}
      yAxisTitle={yAxisTitle}
      yValueFormatter={yValueFormatter}
      yAxisScale={yAxisScale}
      chartHeight={chartHeight}
      chartWidth={chartWidth}
      containerWidth={dimensions.width}
      containerHeight={dimensions.height}
    >
      {/* Mean - removed due to issues with proper visualisation, this chart needs to be reworked */}
      <animated.line
        x1={meanPosX}
        x2={meanPosX}
        y1={0}
        y2={chartHeight}
        stroke="currentColor"
        strokeWidth="2"
        strokeDasharray="8 8"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      />
      <animated.text
        x={meanPosX}
        y={axisTickSize * 2}
        fontSize={12}
        textAnchor="middle"
        fill="currentColor"
        stroke="white"
        strokeWidth="4"
        paintOrder="stroke"
        className="text-brand-blue-1"
        opacity={axisStyle.opacity}
      >
        {i18n.t("cycleVarianceAnnotationMean")}
      </animated.text>
      {/* Mean */}
      {transition((style, d) => {
        return (
          <animated.g key={d.middle}>
            <animated.rect
              x={style.x}
              y={style.y}
              width={barWidth}
              height={style.y.to((it) => Math.max(0, chartHeight - it))}
              opacity={style.opacity}
              rx={3}
              fill="currentColor"
              className="text-brand-blue-1 hover:text-brand-blue-2 cursor-pointer"
              onClick={() => onBarClick?.(d.range)}
            />
          </animated.g>
        );
      })}
      {transition((style, d) => {
        const [start, _] = d.range;
        const label = formatCycleTimeDifference(start);

        return (
          <animated.g key={d.middle}>
            <animated.g
              opacity={style.opacity}
              transform={to(
                [style.x, style.y],
                (x, y) =>
                  `translate(${x + barWidth / 2}, ${y - axisTextOffset})`
              )}
            >
              <TruncatedText
                maxWidth={Math.max(axisTextOffset * 3.5, bandWidth)}
                alignmentBaseline="middle"
                className="text-brand-black font-semibold pointer-events-none"
              >
                {yValueFormatter(d.value)}
              </TruncatedText>
            </animated.g>
            <Group
              strokeWidth={1}
              stroke="currentColor"
              className="text-brand-gray-2"
            >
              <animated.line
                x1={style.x}
                x2={style.x}
                y1={chartHeight}
                y2={chartHeight + axisTickSize}
                opacity={style.opacity}
              />
              <animated.g
                opacity={style.opacity}
                transform={style.x.to(
                  (x) => `translate(${x}, ${chartHeight + axisTextOffset})`
                )}
              >
                <TruncatedText maxWidth={40}>{label}</TruncatedText>
              </animated.g>
            </Group>
          </animated.g>
        );
      })}
    </BasicChartWrapper>
  );
}
