import { useLingui } from "@lingui/react";
import { lazy, PropsWithChildren, Suspense } from "react";

import { paths } from "@/view/routes";

import { LineIdErrorBoundary } from "../line-id/error-boundary";
import { LineSelect } from "../line-id/line-select";
import { ShiftVariantsQueryLoadingFallback } from "./loading-fallback";

const ShiftsWithVariantsProvider = lazy(() =>
  import("./shifts-with-variants-provider").then((module) => ({
    default: module.ShiftsWithVariantsProvider,
  }))
);

const ShiftVariantFormProvider = lazy(() =>
  import("./form/shift-variant-form-provider").then((module) => ({
    default: module.ShiftVariantFormProvider,
  }))
);

const ShiftsWithVariantsList = lazy(() =>
  import("./shifts-with-variants-list").then((module) => ({
    default: module.ShiftsWithVariantsList,
  }))
);

export function LineSettingsShiftsPage() {
  return (
    <div className="max-w-4xl mx-auto">
      <LineSelect path={paths.lineSettingsShiftsPath} />
      <Suspense fallback={<ShiftVariantsQueryLoadingFallback />}>
        <ErrorBoundary>
          <ShiftsWithVariantsProvider>
            <ShiftVariantFormProvider>
              <ShiftsWithVariantsList />
            </ShiftVariantFormProvider>
          </ShiftsWithVariantsProvider>
        </ErrorBoundary>
      </Suspense>
    </div>
  );
}

function ErrorBoundary({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  return (
    <LineIdErrorBoundary
      message={i18n.t("Something went wrong when fetching shifts data.")}
    >
      {children}
    </LineIdErrorBoundary>
  );
}
