import { createContext, PropsWithChildren, useContext } from "react";

import { ShiftWithVariants } from "@/domain/shifts";

import { useShiftsWithVariantsQuery } from "./use-shift-variants";

const ShiftsWithVariantsContext =
  createContext<Array<ShiftWithVariants> | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useShiftsWithVariants() {
  const variants = useContext(ShiftsWithVariantsContext);
  if (!variants) {
    throw new Error(
      "useShiftVariants must be used within a ShiftVariantsProvider"
    );
  }
  return variants;
}

export function ShiftsWithVariantsProvider({
  applicableDate = null,
  children,
}: PropsWithChildren<{
  applicableDate?: Date | null;
}>) {
  const query = useShiftsWithVariantsQuery({ applicableDate });
  return (
    <ShiftsWithVariantsContext.Provider value={query.data}>
      {children}
    </ShiftsWithVariantsContext.Provider>
  );
}
