import { z } from "zod";

export const userPreferencesSchema = z.object({
  onboardingGuides: z.object({
    shiftsTargets: z.boolean().default(true),
  }),
});

export type UserPreferences = z.infer<typeof userPreferencesSchema>;
export type OnboardingGuides = UserPreferences["onboardingGuides"];
export type OnboardingGuideKey = keyof OnboardingGuides;
