import React from "react";

type GroupProps = {
  top?: number;
  left?: number;
};

export const Group = React.forwardRef<
  SVGGElement,
  React.SVGAttributes<SVGGElement> & GroupProps
>(({ top = 0, left = 0, transform, className, children, ...props }, ref) => (
  <g
    ref={ref}
    className={className}
    transform={transform || `translate(${left}, ${top})`}
    {...props}
  >
    {children}
  </g>
));
Group.displayName = "Group";
