import { type ClassValue, clsx } from "clsx";
import {
  addDays,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  isToday,
  startOfWeek,
} from "date-fns";
import { twMerge } from "tailwind-merge";

import { KpiType } from "@/domain/common/metrics";
import { DateRangeOption, DateTimeRange } from "@/domain/common/time-filter";
import { TimeGranularity } from "@/domain/statistics";
import { i18n, localeForDateFns } from "@/i18n";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function padNumberWithZero(value: number) {
  return value.toString().padStart(2, "0");
}

export function formatTime(date: Date) {
  return `${padNumberWithZero(date.getHours())}:${padNumberWithZero(
    date.getMinutes()
  )}`;
}

export function formatDateRange(dateRange: DateTimeRange) {
  const locale = localeForDateFns();
  if (!isSameYear(dateRange.start, dateRange.end)) {
    return `${format(dateRange.start, "d.MM.yy", { locale })} - ${format(
      dateRange.end,
      "d.MM.yy",
      { locale }
    )}`;
  }
  if (!isSameMonth(dateRange.start, dateRange.end)) {
    return `${format(dateRange.start, "d MMM", { locale })} - ${format(
      dateRange.end,
      "d MMM",
      { locale }
    )}`;
  }
  if (!isSameDay(dateRange.start, dateRange.end)) {
    return `${format(dateRange.start, "d", { locale })} - ${format(
      dateRange.end,
      "d",
      { locale }
    )} ${format(dateRange.start, "MMM", { locale })}`;
  }
  if (isToday(dateRange.start)) {
    return i18n.t("reportingTimePickerToday");
  }
  return format(dateRange.start, "d MMM", { locale });
}

export function getDateRangeLabel(value: DateRangeOption) {
  switch (value) {
    case "today":
      return i18n.t("reportingTimePickerToday");
    case "yesterday":
      return i18n.t("reportingTimePickerYesterday");
    case "current_week":
      return i18n.t("reportingTimePickerCurrentWeek");
    case "previous_week":
      return i18n.t("reportingTimePickerPreviousWeek");
    case "current_month":
      return i18n.t("reportingTimePickerCurrentMonth");
    case "previous_month":
      return i18n.t("reportingTimePickerPreviousMonth");
  }
}

export function formatKpiLabel(type: KpiType) {
  switch (type) {
    case "output":
      return i18n.t("reportingKpiOutput");
    case "cycle_count":
      return i18n.t("reportingKpiCycleCount");
    case "cycle_time":
      return i18n.t("reportingKpiCycleTime");
    case "tact":
      return i18n.t("reportingKpiTact");
    case "workers":
      return i18n.t("reportingKpiWorkers");
    case "activity":
      return i18n.t("reportingKpiActivity");
  }
}

/**
 * Display KPI value in a proper format, based on KPI type.
 */
export function formatKpiValue(metricType: KpiType, value: number) {
  const rounded10 = Math.round(value * 10) / 10;

  switch (metricType) {
    case "activity":
      return Math.round(value * 100) / 100;
    case "tact":
    case "cycle_time":
      return formatDurationLocalised(value);
    case "workers":
      return rounded10 ? rounded10 : value ? "<0.1" : 0;
    case "cycle_count":
    case "output":
    default:
      return value;
  }
}

/**
 * Displays duration given in seconds in a proper format.
 */
function formatDurationLocalised(seconds: number) {
  const locale = localeForDateFns();
  const date = new Date(0, 0, 0, 0, 0, seconds);
  const oneHour = 60 * 60;

  if (seconds < 60) {
    return format(date, "s", { locale });
  } else if (seconds < oneHour) {
    return format(date, "m:ss", { locale });
  } else if (seconds < oneHour * 24) {
    return format(date, "H:mm", { locale });
  }
  const hours = Math.floor(seconds / oneHour);
  return `${hours}` + format(date, ":mm", { locale });
}

export function formatCycleTimeDifference(seconds: number) {
  const locale = localeForDateFns();
  const date = new Date(0, 0, 0, 0, 0, seconds);
  return format(date, "m:ss", { locale });
}

export function getKpiUnit(metricType: KpiType, value: number) {
  switch (metricType) {
    case "tact":
    case "cycle_time": {
      if (value < 60) {
        return "s";
      } else if (value < 60 * 60) {
        return "min";
      } else {
        return "h";
      }
    }
    case "activity":
      return "%";
    case "cycle_count":
    case "output":
    case "workers":
    default:
      return "";
  }
}

/**
 * Displays date in a proper format, based on time range.
 */
export const formatDateForTimeAxis =
  (timeGranularity: TimeGranularity) => (date: Date) => {
    const granularityToDateFormatMap = {
      hour: "H:mm",
      day: "EEEE",
      week: "'W' I",
      month: "MMM yy",
      quarter: "QQQ yy",
    } as const;

    return format(date, granularityToDateFormatMap[timeGranularity], {
      locale: localeForDateFns(),
    });
  };

function dayOfWeekDate(dayOfWeek: number) {
  const locale = localeForDateFns();
  const start = startOfWeek(new Date(), { locale, weekStartsOn: 1 });
  return addDays(start, dayOfWeek);
}

export function formatDayOfWeek(dayOfWeek: number, isShort = false) {
  return format(dayOfWeekDate(dayOfWeek), isShort ? "E" : "EEEE");
}

export function secondsToDuration(seconds: number): [number, number, number] {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
  return [hours, minutes, remainingSeconds];
}

export function durationToSeconds(
  hours: number,
  minutes: number,
  seconds: number
) {
  return hours * 3600 + minutes * 60 + seconds;
}

export function formatDuration(value: number) {
  const [hours, minutes, seconds] = secondsToDuration(value);
  if (hours > 0) {
    return `${hours}:${padNumberWithZero(minutes % 60)}:${padNumberWithZero(seconds % 60)} h`;
  } else {
    return `${padNumberWithZero(minutes)}:${padNumberWithZero(seconds % 60)} min`;
  }
}
