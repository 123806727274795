import {
  QueryErrorResetBoundary,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { VirtualSensorAreasLayout } from "@/domain/areas-of-interests";
import { LineId } from "@/domain/line";
import { useApiClient } from "@/view/providers/api-client-provider";

import { useSelectedLine } from "../line-id/use-selected-line";

function getVirtualSensorAreasLayoutQueryKey(lineId: LineId) {
  return ["virtual-sensor-areas-layout", lineId];
}

const VirtualSensorAreasLayoutContext =
  createContext<VirtualSensorAreasLayout | null>(null);

function useVirtualSensorAreasLayoutQuery(lineId: LineId) {
  const apiClient = useApiClient();

  return useSuspenseQuery({
    queryKey: getVirtualSensorAreasLayoutQueryKey(lineId),
    queryFn: () => apiClient.getVirtualSensorAreasLayoutByLineId({ lineId }),
    staleTime: Infinity,
  });
}

// eslint-disable-next-line react-refresh/only-export-components
export function useVirtualSensorAreasLayout() {
  const areasLayout = useContext(VirtualSensorAreasLayoutContext);
  if (!areasLayout) {
    throw new Error(
      "useVirtualSensorAreasLayout must be used within a VirtualSensorAreasLayoutProvider"
    );
  }
  return areasLayout;
}

export function VirtualSensorAreasLayoutProvider({
  children,
}: PropsWithChildren) {
  const selectedLine = useSelectedLine();
  const query = useVirtualSensorAreasLayoutQuery(selectedLine.id);

  return (
    <VirtualSensorAreasLayoutContext.Provider value={query.data}>
      {children}
    </VirtualSensorAreasLayoutContext.Provider>
  );
}

/**
 * @todo: handle error properly
 */
export function VirtualSensorAreasLayoutErrorBoundary({
  children,
}: PropsWithChildren) {
  const selectedLine = useSelectedLine();

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          resetKeys={getVirtualSensorAreasLayoutQueryKey(selectedLine.id)}
          onReset={reset}
          fallbackRender={() => null}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}
