import { useLingui } from "@lingui/react";
import { ResponsiveBar } from "@nivo/bar";

import { AreaId } from "@/domain/areas-of-interests";
import { DateRangeFilter } from "@/domain/common/time-filter";
import { ShiftId } from "@/domain/shifts";
import { ChartCard } from "@/view/components/chart/chart-card";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { useSelectedArea } from "@/view/pages/line-id-area-id/selected-area-provider";

import { useAreaOfInterestsStatistics } from "./use-area-of-interests-statistics";

function generateTicks(tickCount: number, min: number, max: number) {
  if (min === max) return [min];
  if (tickCount === 1) return [max];

  const step = (max - min) / (tickCount - 1);
  return Array.from({ length: tickCount }, (_, i) => min + i * step);
}

function formatSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.ceil(seconds % 60);

  if (hours > 0) {
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  } else if (minutes > 0) {
    return `${minutes}m ${remainingSeconds}s`;
  } else {
    return `${remainingSeconds}s`;
  }
}

function useMinutesInAreaChartData({
  shiftIds,
  dateRange,
  areaId,
}: {
  shiftIds: Array<ShiftId>;
  dateRange: DateRangeFilter;
  areaId: AreaId;
}) {
  return useAreaOfInterestsStatistics(
    { dateRange, areaId, shiftGroupIds: shiftIds },
    {
      select: (data) => data.avgTimeSpent,
    }
  );
}

export function MinutesInAreaChart() {
  const { i18n } = useLingui();
  const areaId = useSelectedArea();
  const dateRange = useSelectedDateRange();
  const shiftIds = useSelectedShiftIds();
  const query = useMinutesInAreaChartData({
    areaId,
    dateRange,
    shiftIds,
  });

  return (
    <ChartCard
      query={query}
      title={i18n.t("Average number of minutes in area")}
      height={350}
    >
      {(data) => {
        const maxDuration = Math.max(...data.map((d) => d.durationSeconds));
        const yTicks = generateTicks(3, 0, maxDuration);

        return (
          <ResponsiveBar
            enableGridY
            animate={true}
            enableLabel={false}
            data={data}
            maxValue={Math.max(...data.map((d) => d.durationSeconds))}
            keys={["durationSeconds"]}
            indexBy="time"
            margin={{ top: 50, right: 0, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={() => "#2C98FF"}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              format: (value) => {
                const [hours, minutes] = value.split(":");

                return `${hours}:${minutes}`;
              },
            }}
            gridYValues={yTicks}
            axisLeft={{
              tickSize: 0,
              tickPadding: 5,
              tickValues: yTicks,
              format: formatSeconds,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            tooltip={({ value }) => {
              return (
                <div className="bg-white p-2 border border-gray-200">
                  {formatSeconds(value)}
                </div>
              );
            }}
          />
        );
      }}
    </ChartCard>
  );
}
