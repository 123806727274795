import { useMemo } from "react";
import {
  Chevron,
  DateLib,
  DateLibOptions,
  DayFlag,
  DayPicker,
  DayPickerProps,
  formatWeekdayName,
  formatWeekNumberHeader,
  getDefaultClassNames,
  isDayOfWeekType,
  labelWeekday,
  labelWeekNumberHeader,
  Locale,
  SelectionState,
  UI,
  Weekday,
  WeekdayProps,
  Weekdays,
  WeekdaysProps,
  WeekNumberHeader,
} from "react-day-picker";

import { buttonVariants } from "@/view/components/button-variants";
import { cn } from "@/view/utils";

import { ChevronLeftIcon, ChevronRightIcon } from "./icons";

type OnWeekdayClickProps = {
  onWeekdayClick?: (weekday: Date) => void;
};

export function Calendar({
  onWeekdayClick,
  ...props
}: DayPickerProps & { locale: Locale } & OnWeekdayClickProps) {
  const classNames = {
    [UI.Months]:
      "flex flex-col md:flex-row space-y-4 space-x-0 md:space-x-4 md:space-y-0 relative",
    [UI.Month]: "space-y-4 px-2",
    [UI.MonthCaption]: "flex justify-center pt-1 relative items-center",
    [UI.CaptionLabel]: "text-sm font-medium",
    [UI.Nav]: "absolute inset-x-0 z-10",
    [UI.PreviousMonthButton]: cn(
      buttonVariants({ variant: "link" }),
      "h-7 w-7 p-0 rounded-full bg-transparent opacity-50 text-brand-gray-4 hover:opacity-100 hover:text-brand-blue-1",
      "absolute left-1 top-0"
    ),
    [UI.NextMonthButton]: cn(
      buttonVariants({ variant: "link" }),
      "h-7 w-7 p-0 rounded-full bg-transparent opacity-50 text-brand-gray-4 hover:opacity-100 hover:text-brand-blue-1",
      "absolute right-1 top-0"
    ),
    [UI.MonthGrid]: "w-full border-collapse space-y-1",
    [UI.Weekdays]: "flex",
    [UI.Weekday]:
      "flex-grow text-brand-gray-4 hover:text-brand-black text-sm text-center font-normal cursor-pointer",
    [UI.Week]: "flex w-full mt-2",
    [UI.Day]: cn(
      "text-center text-sm p-0 relative",
      "focus-within:relative focus-within:z-20",
      "[&.day-range-start]:rounded-l-full",
      "[&.day-range-end]:rounded-r-full"
    ),
    [UI.DayButton]: "h-10 w-10 p-0 font-normal aria-selected:opacity-100",
    [SelectionState.range_start]:
      "day-range-start !bg-brand-blue-1 text-brand-white :rounded-l-full",
    [SelectionState.range_middle]: "day-range-middle bg-brand-gray-1",
    [SelectionState.selected]: cn(
      "day-selected",
      "[&:not(.day-range-middle)]:!bg-brand-blue-1",
      "[&:not(.day-range-middle)]:text-brand-white",
      "[&:not(.day-range-middle):not(.day-range-start):not(.day-range-end)]:rounded-full"
    ),
    [SelectionState.range_end]:
      "day-range-end !bg-brand-blue-1 text-brand-white :rounded-r-full",
    [DayFlag.today]: "bg-brand-gray-2 disabled:bg-brand-white",
    [DayFlag.outside]:
      "text-brand-gray-2 bg-brand-white disabled:bg-brand-white",
    [DayFlag.disabled]: "text-brand-gray-2 disabled:bg-brand-white",
    [DayFlag.hidden]: "invisible",
    ...props.classNames,
  };
  return (
    <DayPicker
      className={cn("p-3", props.className)}
      classNames={classNames}
      components={{
        Weekdays: (weekdayProps) => {
          return (
            <CustomWeekdays
              {...weekdayProps}
              disabled={props.disabled}
              ISOWeek={props.ISOWeek}
              dateLib={props.dateLib}
              locale={props.locale}
              weekStartsOn={props.weekStartsOn}
              showWeekNumber={props.showWeekNumber}
              styles={props.styles}
              classNames={classNames}
              firstWeekContainsDate={props.firstWeekContainsDate}
              useAdditionalWeekYearTokens={props.useAdditionalWeekYearTokens}
              useAdditionalDayOfYearTokens={props.useAdditionalDayOfYearTokens}
              onWeekdayClick={onWeekdayClick}
            />
          );
        },
        Chevron: (props) => {
          if (props.orientation === "left") {
            return <ChevronLeftIcon />;
          }
          if (props.orientation === "right") {
            return <ChevronRightIcon />;
          }
          return <Chevron />;
        },
      }}
      {...props}
    />
  );
}

function CustomWeekday({
  weekday,
  disabledDays,
  onWeekdayClick,
  ...props
}: WeekdayProps & {
  weekday: Date;
  disabledDays: Array<number>;
  onWeekdayClick?: (weekday: Date) => void;
}) {
  return (
    <Weekday
      {...props}
      className={cn(props.className, {
        "text-brand-gray-2": disabledDays.includes(weekday.getDay()),
      })}
      onClick={() => onWeekdayClick?.(weekday)}
    />
  );
}

/**
 * This is based on the Weekdays component from react-day-picker. We've added
 * the ability to disable weekdays by clicking on them.
 *
 * Original source:
 * https://github.com/gpbl/react-day-picker/blob/108e40bfcd0070f675c29454b0f87845c804dbb3/src/DayPicker.tsx#L429-L459
 */
function CustomWeekdays(
  props: WeekdaysProps & {
    disabled: DayPickerProps["disabled"];
    dateLib: DayPickerProps["dateLib"];
    locale: Locale;
    ISOWeek: DayPickerProps["ISOWeek"];
    weekStartsOn: DayPickerProps["weekStartsOn"];
    showWeekNumber: DayPickerProps["showWeekNumber"];
    styles: DayPickerProps["styles"];
    classNames: DayPickerProps["classNames"];
    firstWeekContainsDate: DayPickerProps["firstWeekContainsDate"];
    useAdditionalWeekYearTokens: DayPickerProps["useAdditionalWeekYearTokens"];
    useAdditionalDayOfYearTokens: DayPickerProps["useAdditionalDayOfYearTokens"];
    onWeekdayClick?: (weekday: Date) => void;
  }
) {
  const {
    styles,
    classNames = getDefaultClassNames(),
    showWeekNumber,
    locale,
    ISOWeek,
    weekStartsOn,
    firstWeekContainsDate,
    useAdditionalWeekYearTokens,
    useAdditionalDayOfYearTokens,
  } = props;

  const formatOptions: DateLibOptions = useMemo(
    () => ({
      locale,
      weekStartsOn,
      firstWeekContainsDate,
      useAdditionalWeekYearTokens,
      useAdditionalDayOfYearTokens,
    }),
    [
      locale,
      weekStartsOn,
      firstWeekContainsDate,
      useAdditionalWeekYearTokens,
      useAdditionalDayOfYearTokens,
    ]
  );
  const dateLib = useMemo(() => new DateLib(formatOptions), [formatOptions]);
  const weekdays = useMemo(
    () => getWeekdays(dateLib, ISOWeek),
    [dateLib, ISOWeek]
  );

  const disabledDays = useMemo(() => {
    const arr: number[] = [];
    if (Array.isArray(props.disabled)) {
      for (const d of props.disabled) {
        if (isDayOfWeekType(d)) {
          arr.push(
            ...(Array.isArray(d.dayOfWeek) ? d.dayOfWeek : [d.dayOfWeek])
          );
        }
      }
    }
    return arr;
  }, [props.disabled]);

  return (
    <Weekdays
      className={classNames[UI.Weekdays]}
      role="row"
      style={styles?.[UI.Weekdays]}
    >
      {showWeekNumber && (
        <WeekNumberHeader
          aria-label={labelWeekNumberHeader(formatOptions)}
          className={classNames[UI.WeekNumberHeader]}
          role="columnheader"
          style={styles?.[UI.WeekNumberHeader]}
        >
          {formatWeekNumberHeader()}
        </WeekNumberHeader>
      )}
      {weekdays.map((weekday, i) => (
        <CustomWeekday
          aria-label={labelWeekday(weekday, formatOptions, dateLib)}
          className={classNames[UI.Weekday]}
          key={i}
          role="columnheader"
          style={styles?.[UI.Weekday]}
          /**
           * Below are the custom props that we pass to the CustomWeekday component to enable
           * the click to enable/disable weekday functionality.
           */
          weekday={weekday}
          disabledDays={disabledDays}
          onWeekdayClick={props.onWeekdayClick}
        >
          {formatWeekdayName(weekday, formatOptions, dateLib)}
        </CustomWeekday>
      ))}
    </Weekdays>
  );
}

/**
 * Generate a series of 7 days, starting from the week, to use for formatting
 * the weekday names (Monday, Tuesday, etc.).
 *
 * The `dateLib` instance created needs to be created with proper options,
 * such as locale and weekStartsOn, to properly generate the weekdays.
 *
 * Based on https://github.com/gpbl/react-day-picker/blob/108e40bfcd0070f675c29454b0f87845c804dbb3/src/helpers/getWeekdays.ts
 */
function getWeekdays(dateLib: DateLib, ISOWeek?: boolean | undefined): Date[] {
  const start = ISOWeek
    ? dateLib.startOfISOWeek(new Date())
    : dateLib.startOfWeek(new Date());

  const days = [];
  for (let i = 0; i < 7; i++) {
    const day = dateLib.addDays(start, i);
    days.push(day);
  }
  return days;
}
