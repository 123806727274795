import { z } from "zod";

import { entitySchema, entityWithMetricsSchema } from "./common/entity";
import { factoryIdSchema } from "./factory";

export const lineIdSchema = z.string().brand<"LineId">();
export const lineSchema = entitySchema.extend({
  id: lineIdSchema,
  factoryId: factoryIdSchema,
  inTraining: z.boolean().default(false),
});
export const lineWithMetricsSchema = entityWithMetricsSchema.extend({
  id: lineIdSchema,
  factoryId: factoryIdSchema,
});

export type LineId = z.infer<typeof lineIdSchema>;
export type LineWithMetrics = z.infer<typeof lineWithMetricsSchema>;
