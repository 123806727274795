import { z } from "zod";

import { lineIdSchema } from "./line";
import { StationId, stationIdSchema } from "./station";

const notificationTemplateKeys = [
  "unachievable_target_output",
  "target_output_achieved",
  "no_line_output",
] as const;
export const notificationTypes = [
  "output",
  "total_cycle_time",
  "avg_cycle_time",
  "activity",
  "persons",
] as const;
export const relationTypes = ["gte", "lte", "eq"] as const;
const deliveryTypes = ["none", "in_app", "email"] as const;
export const thresholdTypes = ["absolute", "percent_of_target"] as const;
const notificationRulesFiltersSchema = z.object({
  lineId: lineIdSchema,
});
const notificationRuleIdSchema = z.string().brand<"NotificationRuleId">();
const notificationRuleContextSchema = z.object({
  periodSeconds: z.number().nonnegative(),
  thresholdValue: z.number().nonnegative(),
  thresholdType: z.enum(thresholdTypes),
});
const notificationRuleTemplateSchema = z.object({
  key: z.enum(notificationTemplateKeys),
  type: z.enum(notificationTypes),
  relation: z.enum(relationTypes),
  context: notificationRuleContextSchema,
});
export const notificationRuleSchema = z
  .object({
    id: notificationRuleIdSchema,
    title: z.string().nullable(),
    key: z.enum([...notificationTemplateKeys, "custom"]),
    stationId: stationIdSchema,
    delivery: z.enum(deliveryTypes),
  })
  .extend(notificationRuleTemplateSchema.omit({ key: true }).shape);

const notificationIdSchema = z.string().brand<"NotificationId">();
const notificationSchema = z.object({
  id: notificationIdSchema,
  publishedAt: z.date(),
  isRead: z.boolean(),
  notificationRule: notificationRuleSchema,
});
const notificationFiltersSchema = z.object({
  isRead: z.boolean().nullable(),
  limit: z.number().nonnegative(),
  offset: z.number().nonnegative(),
});
const notificationByIdFiltersSchema = z.object({
  id: notificationIdSchema,
});
const notificationUpdateParamsSchema = z.object({
  id: notificationIdSchema,
  isRead: z.boolean().nullable(),
  publishedAt: z.date().nullable(),
});

export type NotificationId = z.infer<typeof notificationIdSchema>;
export type Notification = z.infer<typeof notificationSchema>;
export type NotificationFilters = z.infer<typeof notificationFiltersSchema>;
export type NotificationByIdFilters = z.infer<
  typeof notificationByIdFiltersSchema
>;
export type NotificationUpdateParams = z.infer<
  typeof notificationUpdateParamsSchema
>;
export type NotificationRulesFilters = z.infer<
  typeof notificationRulesFiltersSchema
>;
export type NotificationRuleId = z.infer<typeof notificationRuleIdSchema>;
type NotificationRuleTemplate = z.infer<typeof notificationRuleTemplateSchema>;
export type NotificationRule = z.infer<typeof notificationRuleSchema>;

export type TemplateKey = (typeof notificationTemplateKeys)[number];
export type NotificationSettings = {
  ruleById: Record<NotificationRuleId, NotificationRule>;
  ruleIdByTemplateKey: Record<TemplateKey, NotificationRuleId>;
  customRules: Array<NotificationRuleId>;
};

export const newNotificationRuleId = "new-rule-id" as NotificationRuleId;

export function shouldCreateNewRule(ruleId: NotificationRuleId) {
  if (ruleId === newNotificationRuleId) return true;
  return ([...notificationTemplateKeys] as Array<NotificationRuleId>).includes(
    ruleId
  );
}

export function getRuleForTemplateKey(
  store: NotificationSettings,
  key: TemplateKey
) {
  const ruleId = store.ruleIdByTemplateKey[key];
  return store.ruleById[ruleId];
}

export function deriveNotificationSettings(
  rules: Array<NotificationRule>,
  defaultStationId: StationId
): NotificationSettings {
  const templates = getDefaultTemplates();
  const store: NotificationSettings = {
    ruleById: {},
    ruleIdByTemplateKey: {
      unachievable_target_output:
        "unachievable_target_output" as NotificationRuleId,
      target_output_achieved: "target_output_achieved" as NotificationRuleId,
      no_line_output: "no_line_output" as NotificationRuleId,
    },
    customRules: [],
  };

  for (const template of templates) {
    const rule = rules.find((rule) => template.key === rule.key);
    const ruleId = rule?.id ?? (template.key as NotificationRuleId);
    store.ruleIdByTemplateKey[template.key] = ruleId;
    store.ruleById[ruleId] = {
      id: ruleId,
      title: null,
      type: rule?.type ?? template.type,
      key: rule?.key ?? template.key,
      stationId: rule?.stationId ?? defaultStationId,
      delivery: rule?.delivery ?? "none",
      relation: rule?.relation ?? template.relation,
      context: {
        periodSeconds:
          rule?.context.periodSeconds ?? template.context.periodSeconds,
        thresholdType:
          rule?.context.thresholdType ?? template.context.thresholdType,
        thresholdValue:
          rule?.context.thresholdValue ?? template.context.thresholdValue,
      },
    };
  }

  for (let i = 0; i < rules.length; i++) {
    const rule = rules[i];
    const ruleId = rule.id;

    if (rule.key !== "custom") continue;

    store.ruleById[ruleId] = rule;
    store.customRules.push(ruleId);
  }

  return store;
}

function getDefaultTemplates(): Array<NotificationRuleTemplate> {
  return [
    // Target output wont be achieved
    {
      key: "unachievable_target_output",
      type: "output",
      relation: "lte",
      context: {
        periodSeconds: 60 * 60, // last 60 minutes
        thresholdType: "percent_of_target", // TODO: add a new type "target_wont_be_achieved"
        thresholdValue: 20,
      },
    },
    // Target output achieved
    {
      key: "target_output_achieved",
      type: "output",
      relation: "gte",
      context: {
        periodSeconds: 60 * 60, // last 60 minutes
        thresholdType: "percent_of_target",
        thresholdValue: 100,
      },
    },
    // No line output detected for the past 30 minutes
    {
      key: "no_line_output",
      type: "output",
      relation: "eq",
      context: {
        periodSeconds: 60 * 60, // last 60 minutes
        thresholdType: "absolute",
        thresholdValue: 0,
      },
    },
  ];
}
