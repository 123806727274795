import { Suspense } from "react";
import { Outlet } from "react-router-dom";

import { paths } from "@/view/routes";

import { LineMainLayout } from "../line-id/line-main-layout";
import { LineSelect } from "../line-id/line-select";
import {
  LineReportingFiltersProvider,
  ReportingFilters,
} from "./line-reporting-filters-provider";
import { LineReportingTabs } from "./line-reporting-tabs";
import { LineStatistics } from "./line-statistics";
import { LineStatisticsWidgetsErrorBoundary } from "./line-statistics-error-boundary";
import { LineStatisticsProvider } from "./line-statistics-provider";
import { LineWidgetActivity } from "./line-widget-activity";
import { LineWidgetCycleTime } from "./line-widget-cycle-time";
import { LineWidgetLoading } from "./line-widget-loading";
import { LineWidgetOutput } from "./line-widget-output";
import { LineWidgetLayout } from "./line-widgets-layout";
import { TargetsOverrideFormProvider } from "./targets-override-form-provider";

export function LineReportingPage() {
  return (
    <LineReportingFiltersProvider>
      <LineMainLayout
        filters={
          <>
            <div className="grow">
              <LineSelect path={paths.lineReportingPath} />
            </div>
            <ReportingFilters />
          </>
        }
      >
        <Suspense
          fallback={
            <LineWidgetLayout>
              <LineWidgetLoading />
              <LineWidgetLoading />
              <LineWidgetLoading />
            </LineWidgetLayout>
          }
        >
          <LineStatisticsWidgetsErrorBoundary>
            <LineStatisticsProvider>
              <LineWidgetLayout>
                <TargetsOverrideFormProvider>
                  <LineWidgetOutput />
                  <LineWidgetCycleTime />
                  <LineWidgetActivity />
                </TargetsOverrideFormProvider>
              </LineWidgetLayout>
            </LineStatisticsProvider>
          </LineStatisticsWidgetsErrorBoundary>
        </Suspense>

        <LineReportingTabs>
          <LineStatistics />
        </LineReportingTabs>
      </LineMainLayout>

      <Outlet />
    </LineReportingFiltersProvider>
  );
}
