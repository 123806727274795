import { PropsWithChildren } from "react";

import { Container } from "@/view/components";

export function LineMainLayout({
  children,
  filters,
}: PropsWithChildren<{
  filters: React.ReactNode;
}>) {
  return (
    <section className="py-4 h-full flex flex-col">
      <Container>
        <div className="flex justify-between items-center my-6">
          <div className="flex gap-3 justify-between w-full">{filters}</div>
        </div>
      </Container>
      <Container className="h-full pb-12">{children}</Container>
    </section>
  );
}
