import { useLingui } from "@lingui/react";
import { ListFilterIcon } from "lucide-react";
import { useState } from "react";

import { Station } from "@/domain/station";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { useSelectedLine } from "../line-id/use-selected-line";
import { useSelectedStation } from "./use-selected-station";

export function StationSelect({
  onChange,
}: {
  onChange: (value: Station | null) => void;
}) {
  const { i18n } = useLingui();
  const [open, setOpen] = useState(false);
  const line = useSelectedLine();
  const station = useSelectedStation();

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) setOpen(newOpen);
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="analyze_info_icon_clicked"
          className={cn(
            "border-2 rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <span className="min-w-[160px] select-none flex gap-2 justify-between items-center">
            {station
              ? `${station.name}`
              : i18n.t("stationSelectorNoValueLabel")}
            <ListFilterIcon />
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] p-0 rounded-lg"
        onInteractOutside={() => setOpen(false)}
      >
        <DropdownMenuItem
          className={cn(
            "m-1 py-2.5 px-2",
            "text-brand-black font-semibold",
            "hover:bg-brand-gray-2"
          )}
          onClick={() => onChange(null)}
        >
          {i18n.t("stationSelectorSelectNone")}
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          className="max-h-[240px] overflow-y-auto"
          value={station?.id}
          onValueChange={(value) => {
            onChange(
              line.stations.find((station) => station.id === value) || null
            );
          }}
        >
          {line.stations.map((station) => {
            return (
              <DropdownMenuRadioItem key={station.id} value={station.id}>
                <span className="flex gap-2 pr-8">{station.name}</span>
              </DropdownMenuRadioItem>
            );
          })}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
