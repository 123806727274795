import { z } from "zod";

import { entitySchema } from "./common/entity";

export const factoryIdSchema = z.string().brand<"FactoryId">();
export const factorySchema = entitySchema.extend({
  id: factoryIdSchema,
});

export type FactoryId = z.infer<typeof factoryIdSchema>;
