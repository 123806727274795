import { cn } from "@/view/utils";

export function LoadingShimmer({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        "flex h-full min-h-[44px] w-full min-w-[220px]",
        "animate-pulse bg-brand-gray-2 rounded-md",
        className
      )}
    />
  );
}
