import { I18n } from "@lingui/core";
import { useLingui } from "@lingui/react";
import { BarChartIcon, BlendIcon, LayersIcon } from "lucide-react";

import { Button } from "@/view/components";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { cn } from "@/view/utils";

export type ChartType = "single" | "combined" | "compared";

export function ChartTypeSelector({
  options,
  value,
  onChange,
}: {
  options: Array<ChartType>;
  value: ChartType;
  onChange: (value: ChartType) => void;
}) {
  const analytics = useAnalytics();
  const { i18n } = useLingui();

  return (
    <div className="flex gap-1.5 p-1.5 rounded-lg bg-brand-gray-1">
      {options.map((option) => (
        <Button
          key={option}
          analyticsEvent="chart_type_selector_clicked"
          className={cn(
            "border rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5",
            "flex gap-2 items-center",
            value === option && "text-brand-blue-1"
          )}
          onClick={() => {
            analytics.collect("chart_type_selector_value_changed", {
              value: option,
            });
            onChange(option);
          }}
        >
          {getIcon(option)}
          {getName(option, i18n)}
        </Button>
      ))}
    </div>
  );
}

function getIcon(chartType: ChartType) {
  switch (chartType) {
    case "single":
      return <BarChartIcon className="w-4 text-brand-blue-1" />;
    case "combined":
      return <LayersIcon className="w-4 text-brand-blue-1" />;
    case "compared":
      return <BlendIcon className="w-4 text-brand-blue-1" />;
  }
}

function getName(chartType: ChartType, i18n: I18n) {
  switch (chartType) {
    case "single":
      return i18n.t("Per product");
    case "combined":
      return i18n.t("Intersection");
    case "compared":
      return i18n.t("Compared");
  }
}
