import { Card } from "@/view/components";
import { LoadingShimmer } from "@/view/components/loading-shimmer";

export function LineWidgetLoading() {
  return (
    <Card className="p-12 h-44 grow border rounded-xl shadow-sm">
      <LoadingShimmer />
    </Card>
  );
}
