import * as React from "react";

import { cn } from "../utils";

export const Container = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("w-[97.5%] px-4 mx-auto", className)}
    {...props}
  />
));
Container.displayName = "Container";
