import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";

import { AreaId, areaIdSchema } from "@/domain/areas-of-interests";

const SelectedAreaContext = createContext<AreaId | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useSelectedArea() {
  const SelectedArea = useContext(SelectedAreaContext);
  if (!SelectedArea) {
    throw new Error("useSelectedArea must be used inside SelectedAreaProvider");
  }
  return SelectedArea;
}

export function SelectedAreaProvider({ children }: PropsWithChildren) {
  const { areaId } = useParams<{ areaId: string }>();

  const selectedAreaId = useMemo(() => {
    const validatedId = areaIdSchema.safeParse(areaId);
    return validatedId.success ? validatedId.data : null;
  }, [areaId]);

  if (!selectedAreaId) {
    return <Navigate to="../" relative="path" replace />;
  }

  return (
    <SelectedAreaContext.Provider value={selectedAreaId}>
      {children}
    </SelectedAreaContext.Provider>
  );
}
