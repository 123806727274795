import { PropsWithChildren } from "react";

import { cn } from "@/view/utils";

export function Badge({
  children,
  variant = "info",
}: PropsWithChildren<{
  variant?: "info" | "success" | "warning" | "error";
}>) {
  return (
    <span
      className={cn(
        "inline-flex items-center",
        "rounded-full border px-2.5 py-1 transition-colors",
        "text-xs font-semibold whitespace-nowrap",
        {
          "bg-brand-neutral-shade text-brand-gray-4 border-brand-neutral":
            variant === "info",
          "bg-brand-improving-shade text-brand-improving border-brand-improving":
            variant === "success",
          "bg-brand-warninig-shade text-brand-chart-warning-2 border-brand-chart-warning-2":
            variant === "warning",
          "bg-brand-warninig-shade text-brand-warning border-brand-warning":
            variant === "error",
        }
      )}
    >
      {children}
    </span>
  );
}
