import { createContext, useContext } from "react";

import { Pagination, paginationSchema } from "@/domain/common/pagination";

export const PaginationContext = createContext<Pagination>(
  paginationSchema.parse({})
);

export function usePagination() {
  const pagination = useContext(PaginationContext);
  if (!pagination) {
    throw new Error("usePagination must be used inside PaginationProvider");
  }
  return pagination;
}

const searchParamKey = "offset";

export function parsePaginationFromSearchParams(searchParams: URLSearchParams) {
  const pagination = paginationSchema.parse({
    offset: searchParams.get(searchParamKey),
  });
  return pagination;
}

export function encodePaginationToSearchParams(
  searchParams: URLSearchParams,
  pagination: Pagination
) {
  searchParams.set(searchParamKey, `${pagination.offset}`);
  return searchParams;
}
