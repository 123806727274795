import { useLingui } from "@lingui/react";
import { PropsWithChildren } from "react";

export function NotificationSettingsLayout({ children }: PropsWithChildren) {
  const { i18n } = useLingui();
  return (
    <>
      <header className="border-b py-6">
        <h2 className="text-2xl font-semibold text-brand-black">
          {i18n.t("Notifications")}
        </h2>
        <p className="text-sm">
          {i18n.t("Select when and how you’ll be notified.")}
        </p>
      </header>
      <div className="py-6">{children}</div>
    </>
  );
}
