import { useQuery } from "@tanstack/react-query";
import {
  memo,
  MouseEventHandler,
  ReactElement,
  useMemo,
  useState,
} from "react";
import { generatePath, NavLink } from "react-router-dom";

import { AreaColor, AreaId, Dimensions } from "@/domain/areas-of-interests";
import {
  encodeDateRangeToSearchParams,
  useSelectedDateRange,
} from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";
import {
  encodeShiftIdsToSearchParams,
  useSelectedShiftIds,
} from "@/view/pages/line-id/use-selected-shift-ids";
import { useApiClient } from "@/view/providers/api-client-provider";
import { paths } from "@/view/routes";
import { cn } from "@/view/utils";

import { AreaConnections } from "./area-connections";
import { useVirtualSensorAreasLayout } from "./use-virtual-sensor-layout";

export const AreasLayout = memo(function AreasLayout({
  opacity,
}: {
  opacity: number;
}) {
  const [selectedAreaId, setSelectedAreaId] = useState<AreaId | null>(null);
  const line = useSelectedLine();
  const apiClient = useApiClient();
  const layout = useVirtualSensorAreasLayout();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();

  const areaConnectionsQuery = useQuery({
    queryKey: ["area-connections", selectedAreaId, dateRange, shiftIds],
    queryFn: ({ signal }) =>
      apiClient.getAreaConnections(
        {
          areaId: selectedAreaId as AreaId,
          dateRange,
          shiftGroupIds: shiftIds,
        },
        { signal }
      ),
    enabled: !!selectedAreaId,
  });

  const searchParams = useMemo(() => {
    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, dateRange);

    return searchParams;
  }, [shiftIds, dateRange]);

  return (
    <>
      <g>
        {layout.areas.map((area) => {
          const path = area.stationId
            ? generatePath(paths.lineBirdEyeViewStationDetailsOverviewPath, {
                lineId: line.id,
                areaId: area.id,
                stationId: area.stationId,
              })
            : generatePath(paths.lineBirdEyeViewAreaDetailsPath, {
                lineId: line.id,
                areaId: area.id,
              });

          return (
            <g
              key={area.id}
              transform={`translate(${area.coordinates.x}, ${area.coordinates.y})`}
            >
              <CanvasArea
                key={area.id}
                opacity={opacity}
                color={area.color}
                width={area.coordinates.width}
                height={area.coordinates.height}
                isSelected={selectedAreaId === area.id}
                isLoading={areaConnectionsQuery.isLoading}
                onClick={() => {
                  setSelectedAreaId(area.id);
                }}
                name={
                  <NavLink
                    to={{
                      pathname: path,
                      search: searchParams.toString(),
                    }}
                    className={({ isActive }) =>
                      cn(
                        "hover:underline text-white fill-white",
                        isActive && "underline"
                      )
                    }
                  >
                    <text
                      x={8}
                      y={16}
                      dominantBaseline="middle"
                      fill="currentColor"
                      fontSize={16}
                      fontWeight="bold"
                    >
                      {area.name}
                    </text>
                  </NavLink>
                }
              />
            </g>
          );
        })}
      </g>

      {selectedAreaId && areaConnectionsQuery.data && (
        <AreaConnections
          areaId={selectedAreaId}
          connections={areaConnectionsQuery.data}
        />
      )}
    </>
  );
});

function CanvasArea({
  opacity,
  name,
  color,
  width,
  height,
  isSelected,
  onClick,
  isLoading,
}: {
  opacity: number;
  color: AreaColor;
  isSelected: boolean;
  onClick?: MouseEventHandler<SVGRectElement>;
  isLoading: boolean;
  name: ReactElement;
} & Dimensions) {
  return (
    <g>
      <rect
        className={cn(isSelected ? "fill-opacity-95" : "fill-opacity-75", {
          "transition-all duration-700 animate-pulse": isLoading && isSelected,
        })}
        fill={`rgba(${color.join(",")}, ${isSelected ? 0.95 : opacity})`}
        stroke={isSelected ? "white" : `rgba(${color.join(",")}, 0.85)`}
        strokeWidth={1}
        rx={6}
        height={height}
        width={width}
        style={{ cursor: "pointer" }}
        onClick={onClick}
      />
      {name}
    </g>
  );
}
