import { useLingui } from "@lingui/react";
import { BarDatum, BarItem, BarItemProps, ResponsiveBar } from "@nivo/bar";
import { useState } from "react";

import { StationId } from "@/domain/station";
import { StationCycleTimes } from "@/domain/statistics";
import { useSelectedProductIds } from "@/view/pages/line-id/use-selected-product-ids";
import { useSelectedTagIds } from "@/view/pages/line-id/use-selected-tag-ids";
import { formatDuration, formatKpiValue, getKpiUnit } from "@/view/utils";

import { useTags } from "../../line-id/use-tags";
import { ChartLegend } from "./chart-legend";
import { ChartType, ChartTypeSelector } from "./chart-type-select";
import { ChartWrapper } from "./chart-wrapper";
import {
  getColor,
  getCycleTimeKeysForChartType,
  getLegendName,
  getValueForChartType,
} from "./helpers";
import { useStationName } from "./use-station-name";

type TypeOptions = Exclude<ChartType, "combined">;

export function CycleTimeByStationChart({
  data,
  height,
  onBarClick,
}: {
  data: Array<StationCycleTimes>;
  height: number;
  onBarClick?: (label: StationId) => void;
}) {
  const { i18n } = useLingui();
  const tagsStore = useTags();
  const selectedTagsIds = useSelectedTagIds();
  const selectedProductIds = useSelectedProductIds();
  const getStationName = useStationName();
  const [hiddenLegends, setHiddenLegends] = useState<Array<string>>([]);
  const [chartType, setChartType] = useState<TypeOptions>(() =>
    selectedTagsIds.length > 0 ? "compared" : "single"
  );
  const keys = getCycleTimeKeysForChartType(
    chartType,
    selectedTagsIds,
    selectedProductIds
  );
  const chartData = data.map((it) =>
    getValueForChartType(chartType, it, hiddenLegends)
  );

  const maxValue = Math.max(
    10,
    ...data.flatMap((it) => {
      return it[chartType]
        .filter((it) => !hiddenLegends.includes(it.key))
        .map((it) => it.value);
    })
  );
  return (
    <ChartWrapper
      height={height}
      controls={
        <>
          <ChartLegend
            dataType="cycleTime"
            chartType={chartType}
            legends={keys}
            hiddenLegends={hiddenLegends}
            onLegendClick={(legend) =>
              setHiddenLegends((it) => {
                if (it.includes(legend)) {
                  return it.filter((l) => l !== legend);
                } else {
                  return [...it, legend];
                }
              })
            }
          />
          <ChartTypeSelector
            options={
              selectedTagsIds.length > 0 ? ["single", "compared"] : ["single"]
            }
            value={chartType}
            onChange={(type) => {
              setChartType(type as TypeOptions);
              setHiddenLegends([]);
            }}
          />
        </>
      }
    >
      <ResponsiveBar
        indexBy="stationId"
        data={chartData}
        keys={keys}
        groupMode="grouped"
        onClick={(d) => onBarClick?.(d.data.stationId as StationId)}
        // styling
        barComponent={CustomBarItem}
        margin={{ top: 60, right: 0, bottom: 120, left: 150 }}
        colors={(d) => getColor(`${d.id}`, chartType, "cycleTime", tagsStore)}
        maxValue={maxValue}
        padding={0.1}
        labelSkipHeight={0}
        labelSkipWidth={0}
        labelPosition={"end"}
        labelOffset={16}
        valueFormat={(value) => {
          const formattedValue = formatKpiValue("cycle_time", value);
          const unit = getKpiUnit("cycle_time", value);
          return `${formattedValue} ${unit}`;
        }}
        labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        enableLabel
        tooltipLabel={(d) => {
          const stationName = getStationName(d.data.stationId as StationId);
          const legendName = getLegendName(
            `${d.id}`,
            chartType,
            "cycleTime",
            tagsStore,
            i18n
          );
          return `${stationName} - ${legendName}`;
        }}
        axisLeft={{
          legend: i18n.t("lineOverviewChartTabAvgCycleTime"),
          legendPosition: "middle",
          legendOffset: -90,
          format: formatDuration,
        }}
        axisBottom={{
          tickPadding: 12,
          tickRotation: -14,
          truncateTickAt: 10,
          format: (value) => getStationName(value as StationId),
        }}
        theme={{
          grid: {
            line: {
              stroke: "#e5eaf0",
            },
          },
        }}
      />
    </ChartWrapper>
  );
}

function CustomBarItem<RawDatum extends BarDatum>(
  props: BarItemProps<RawDatum>
) {
  return (
    <g className="cursor-pointer">
      <BarItem {...props} />
    </g>
  );
}
