import { createContext, useContext } from "react";

import { Station } from "@/domain/station";

export const SelectedStationDetailsContext = createContext<Station | null>(
  null
);

export function useSelectedStationDetails() {
  const selectedStation = useContext(SelectedStationDetailsContext);
  if (!selectedStation) {
    throw new Error(
      "useSelectedStation must be used inside SelectedStationDetailsProvider"
    );
  }
  return selectedStation;
}
