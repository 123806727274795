import { PropsWithChildren, ReactNode } from "react";

import { cn } from "@/view/utils";

export function FormSection({
  label,
  labelSibling,
  className,
  children,
}: PropsWithChildren<{
  label: string;
  className?: string;
  labelSibling?: ReactNode;
}>) {
  return (
    <div className={cn("flex flex-col gap-2", className)}>
      <div className="flex gap-2 items-center">
        <h4 className="text-sm text-brand-gray-5">{label}</h4>
        {labelSibling}
      </div>
      {children}
    </div>
  );
}
