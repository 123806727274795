import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DateRangeFilter } from "@/domain/common/time-filter";
import { getOrderedStationIds, LineWithStations } from "@/domain/levels";
import { ShiftId } from "@/domain/shifts";
import { StationId } from "@/domain/station";
import { getAllowedTagIds, getTagsForTypes, TagId } from "@/domain/tag";
import { useAnalytics } from "@/view/providers/analytics-provider";
import { paths } from "@/view/routes";

import { DateRangeSelect } from "../line-id/date-range-select";
import { FiltersSelect } from "../line-id/filters-select";
import { ProductsSelect } from "../line-id/products-select";
import { ShiftSelect } from "../line-id/shift-select";
import {
  encodeDateRangeToSearchParams,
  parseDateRangeFromSearchParams,
  SelectedDateRangeContext,
} from "../line-id/use-selected-date-range";
import {
  SelectedLineContext,
  useSelectedLine,
} from "../line-id/use-selected-line";
import {
  createProductIdValidator,
  encodeProductIdsToSearchParams,
  parseProductIdsFromSearchParams,
  SelectedProductIdsContext,
} from "../line-id/use-selected-product-ids";
import {
  encodeShiftIdsToSearchParams,
  parseShiftIdsFromSearchParams,
  SelectedShiftIdsContext,
} from "../line-id/use-selected-shift-ids";
import {
  encodeStationIdsToSearchParams,
  parseStationIdsFromSearchParams,
  SelectedStationIdsContext,
} from "../line-id/use-selected-station-ids";
import {
  createTagIdValidator,
  encodeTagIdsToSearchParams,
  parseTagIdsFromSearchParams,
  SelectedTagIdsContext,
} from "../line-id/use-selected-tag-ids";
import { useTags } from "../line-id/use-tags";
import {
  ChartType,
  encodeChartTypeToSearchParams,
  parseChartTypeFromSearchParams,
  SelectedChartTypeContext,
} from "./use-selected-line-chart";

type LineReportingFilters = {
  line: LineWithStations;
  shiftIds: Array<ShiftId>;
  stationsIds: Array<StationId>;
  tagIds: Array<TagId>;
  productIds: Array<TagId>;
  dateRange: DateRangeFilter;
  chartType: ChartType;
};

type LineReportingFilterActions = {
  selectShifts: (shift: Array<ShiftId>) => void;
  selectDateRange: (dateRange: DateRangeFilter) => void;
  selectStations: (stationsIds: Array<StationId>) => void;
  selectTags: (tagsIds: Array<TagId>) => void;
  selectProducts: (productsIds: Array<TagId>) => void;
  selectChartType: (chartType: ChartType) => void;
};

const LineReportingFilterActionsContext =
  createContext<LineReportingFilterActions | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useLineReportingFilterActions() {
  const actions = useContext(LineReportingFilterActionsContext);
  if (!actions) {
    throw new Error(
      "useLineReportingFilterActions must be used inside LineReportingFilterActionsProvider"
    );
  }
  return actions;
}

const getAllowedTags = getTagsForTypes(["System"]);
const getAllowedProductTags = getTagsForTypes(["Product"]);

export function LineReportingFiltersProvider({ children }: PropsWithChildren) {
  const line = useSelectedLine();
  const tagStore = useTags();
  const analytics = useAnalytics();
  const validateTagIds = useMemo(
    () => createTagIdValidator(getAllowedTagIds(getAllowedTags(tagStore))),
    [tagStore]
  );
  const allowedProductIds = useMemo(
    () => getAllowedTagIds(getAllowedProductTags(tagStore)),
    [tagStore]
  );
  const validateProductIds = useMemo(
    () => createProductIdValidator(allowedProductIds),
    [allowedProductIds]
  );
  const [state, setState] = useState<LineReportingFilters>(() => {
    const searchParams = new URLSearchParams(window.location.search);
    return {
      line,
      shiftIds: parseShiftIdsFromSearchParams(searchParams, line)!,
      dateRange: parseDateRangeFromSearchParams(searchParams),
      stationsIds: parseStationIdsFromSearchParams(searchParams, line),
      chartType: parseChartTypeFromSearchParams(searchParams),
      tagIds: parseTagIdsFromSearchParams(searchParams, validateTagIds),
      productIds: parseProductIdsFromSearchParams(
        searchParams,
        validateProductIds
      ),
    };
  });

  useEffect(() => {
    // synchronise with lineId from the URL
    if (state.line.id === line.id) return;
    setState((prev) => {
      const shiftIds = line.shifts.map((it) => it.id);
      const stationsIds = line.stations.map((s) => s.id);
      // validate tagIds and productIds
      const tagIds = validateTagIds(prev.tagIds);
      const productIds = validateProductIds(prev.productIds);
      return { ...prev, line, shiftIds, stationsIds, tagIds, productIds };
    });
  }, [line, state.line.id, validateTagIds, validateProductIds]);

  // TODO: turn this into a generic "useSynchronizeUrl" custom hook
  // when the "useEffectEvent" hook is released in a stable version of React
  // => https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
  useEffect(() => {
    const locationLastSlice = window.location.pathname
      .split("/")
      .filter((it) => it)
      .at(-1);
    const reportingPath = paths.lineReportingPath.split("/").at(-1);

    // only synchronise with URL when the location is the reporting page
    if (locationLastSlice !== reportingPath) return;

    let searchParams = new URLSearchParams();
    searchParams = encodeShiftIdsToSearchParams(searchParams, state.shiftIds);
    searchParams = encodeDateRangeToSearchParams(searchParams, state.dateRange);
    searchParams = encodeStationIdsToSearchParams(
      searchParams,
      state.stationsIds
    );
    searchParams = encodeTagIdsToSearchParams(
      searchParams,
      validateTagIds(state.tagIds)
    );
    searchParams = encodeProductIdsToSearchParams(
      searchParams,
      validateProductIds(state.productIds)
    );
    searchParams = encodeChartTypeToSearchParams(searchParams, state.chartType);

    // synchronise with URL
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${searchParams}`
    );

    // send analytics event
    analytics.collect("filters_changed");
  }, [state, analytics, validateProductIds, validateTagIds]);

  const selectedLine = useMemo(() => state.line, [state.line]);
  const selectedShiftIds = useMemo(() => state.shiftIds, [state.shiftIds]);
  const selectedDateRange = useMemo(() => state.dateRange, [state.dateRange]);
  const stationsIds = useMemo(() => state.stationsIds, [state.stationsIds]);
  const tagIds = useMemo(() => state.tagIds, [state.tagIds]);
  const productIds = useMemo(() => {
    // NOTE: for now we always want to pass all product ids
    return Array.from(allowedProductIds).sort((a, b) => Number(a) - Number(b));
    // return state.productIds;
  }, [
    // state.productIds,
    allowedProductIds,
  ]);
  const chartType = useMemo(() => state.chartType, [state.chartType]);
  const actions = useMemo<LineReportingFilterActions>(() => {
    return {
      selectShifts(shiftIds) {
        setState((prev) => ({ ...prev, shiftIds }));
      },
      selectDateRange(dateRange) {
        setState((prev) => ({ ...prev, dateRange }));
      },
      selectStations(stationsIds) {
        setState((prev) => ({
          ...prev,
          stationsIds: getOrderedStationIds(line, stationsIds),
        }));
      },
      selectTags(tagIds) {
        setState((prev) => ({
          ...prev,
          tagIds: validateTagIds(tagIds),
        }));
      },
      selectProducts(productIds) {
        setState((prev) => ({
          ...prev,
          productIds: validateProductIds(productIds),
        }));
      },
      selectChartType(chartType) {
        setState((prev) => ({ ...prev, chartType }));
      },
    };
  }, [line, validateTagIds, validateProductIds]);

  return (
    // provide the line context again, so that the "selectedLine" comes
    // from state value, which prevents double rerenders caused by line change
    <SelectedLineContext.Provider value={selectedLine}>
      <SelectedShiftIdsContext.Provider value={selectedShiftIds}>
        <SelectedDateRangeContext.Provider value={selectedDateRange}>
          <SelectedStationIdsContext.Provider value={stationsIds}>
            <SelectedChartTypeContext.Provider value={chartType}>
              <SelectedTagIdsContext.Provider value={tagIds}>
                <SelectedProductIdsContext.Provider value={productIds}>
                  <LineReportingFilterActionsContext.Provider value={actions}>
                    {children}
                  </LineReportingFilterActionsContext.Provider>
                </SelectedProductIdsContext.Provider>
              </SelectedTagIdsContext.Provider>
            </SelectedChartTypeContext.Provider>
          </SelectedStationIdsContext.Provider>
        </SelectedDateRangeContext.Provider>
      </SelectedShiftIdsContext.Provider>
    </SelectedLineContext.Provider>
  );
}

export function ReportingFilters() {
  const actions = useLineReportingFilterActions();
  const tagStore = useTags();
  const availableTags = getAllowedTags(tagStore);
  const availableProducts = getAllowedProductTags(tagStore);
  return (
    <>
      <FiltersSelect
        availableTags={availableTags}
        onStationIdsChange={actions.selectStations}
        onTagIdsChange={actions.selectTags}
      />
      <ProductsSelect
        availableProductTags={availableProducts}
        onTagIdsChange={actions.selectProducts}
      />
      <ShiftSelect onChange={actions.selectShifts} />
      <DateRangeSelect onChange={actions.selectDateRange} />
    </>
  );
}
