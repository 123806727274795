import { useLingui } from "@lingui/react";
import {
  ListFilterIcon,
  PackageIcon,
  TagIcon,
  WandSparklesIcon,
  XIcon,
} from "lucide-react";
import { useCallback, useState } from "react";

import { StationId } from "@/domain/station";
import {
  deriveNewTagSelectionFactory,
  getAllowedTagIds,
  getLimitedValidTagIds,
  Tag,
  TagId,
} from "@/domain/tag";
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { StationsFilters } from "./stations-select";
import { useSelectedTagIds } from "./use-selected-tag-ids";

export function FiltersSelect({
  availableTags,
  onStationIdsChange,
  onTagIdsChange,
}: {
  availableTags: Array<Tag>;
  onStationIdsChange: (ids: Array<StationId>) => void;
  onTagIdsChange: (ids: Array<TagId>) => void;
}) {
  const [open, setOpen] = useState(false);
  const initialTagIds = useSelectedTagIds();
  const [tagIds, setTagIds] = useState(() => new Set(initialTagIds));

  const deriveNewTagSelection = useCallback(
    (tagId: TagId | null) => {
      const deriveNewTagSelection = deriveNewTagSelectionFactory(
        tagIds,
        getLimitedValidTagIds(getAllowedTagIds(availableTags))
      );
      return deriveNewTagSelection(tagId);
    },
    [tagIds, availableTags]
  );

  function closeMenu() {
    setOpen(false);
    onTagIdsChange(Array.from(tagIds));
  }

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          onClick={() => setOpen(true)}
          analyticsEvent="tag_station_filter_clicked"
          className={cn(
            "border-2 rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <SelectedTags
            tags={availableTags}
            selectedIds={tagIds}
            onTagClick={(tagId) => {
              const selectedTagIds = deriveNewTagSelection(tagId);
              setTagIds(selectedTagIds);
              onTagIdsChange(Array.from(selectedTagIds));
            }}
          />
          <FiltersSelectLabel />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] p-0 rounded-lg flex"
        onEscapeKeyDown={closeMenu}
        onInteractOutside={closeMenu}
      >
        <div className="border-r">
          <StationsFilters onChange={onStationIdsChange} />
        </div>
        <div>
          <TagsFilters
            tags={availableTags}
            selectedIds={tagIds}
            onTagClick={(tagId) => {
              setTagIds(deriveNewTagSelection(tagId));
            }}
          />
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SelectedTags({
  tags,
  selectedIds,
  itemsToShow = 2,
  onTagClick,
}: {
  tags: Array<Tag>;
  selectedIds: Set<TagId>;
  itemsToShow?: number;
  onTagClick: (tagId: TagId | null) => void;
}) {
  const { i18n } = useLingui();
  const selectedTags = tags.filter((tag) => selectedIds.has(tag.id));

  // Don't show anything if there are no selected tags
  // to avoid styling issues
  if (selectedTags.length === 0) {
    return null;
  }

  return (
    <ul className="flex gap-2 mr-4">
      {selectedTags.slice(0, itemsToShow).map((tag) => {
        return (
          <li key={tag.id}>
            <span
              className={cn(
                "flex items-center gap-2 px-2 py-0.25 h-full",
                "bg-brand-gray-2 text-brand-blue-1 rounded-sm"
              )}
            >
              <span className="grow">{tag.name}</span>
              <XIcon
                className="text-brand-gray-5 hover:text-brand-warning w-4"
                onClick={(e) => {
                  e.stopPropagation();
                  onTagClick(tag.id);
                }}
              />
            </span>
          </li>
        );
      })}
      {selectedTags.length > itemsToShow && (
        <li>
          <span
            className={cn(
              "flex items-center px-2 py-0.25 h-full",
              "bg-brand-gray-2 text-brand-blue-1 rounded-sm"
            )}
          >
            {i18n.t("+{number} more", {
              number: selectedTags.length - itemsToShow,
            })}
          </span>
        </li>
      )}
    </ul>
  );
}

function FiltersSelectLabel() {
  const { i18n } = useLingui();
  return (
    <span className="select-none flex gap-2 justify-between items-center">
      <ListFilterIcon />
      {i18n.t("Filters")}
    </span>
  );
}

function TagsFilters({
  tags,
  selectedIds,
  onTagClick,
}: {
  tags: Array<Tag>;
  selectedIds: Set<TagId>;
  onTagClick: (tagId: TagId | null) => void;
}) {
  const { i18n } = useLingui();
  return (
    <>
      <DropdownMenuLabel className="py-3 px-4 flex gap-2 items-center">
        <TagIcon className="w-4 h-4 text-brand-blue-1" />
        <h3 className="text-sm">{i18n.t("Tags")}</h3>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <div className="max-h-[290px] overflow-y-auto">
        {tags.map((tag) => {
          return (
            <DropdownMenuCheckboxItem
              key={tag.id}
              className="mx-1"
              checked={selectedIds.has(tag.id)}
              onClick={() => onTagClick(tag.id)}
            >
              <span className="flex items-center gap-2 border rounded-sm px-2 py-0.25">
                {(() => {
                  switch (tag.type) {
                    case "System":
                      return (
                        <WandSparklesIcon
                          className="w-4"
                          style={{ color: tag.color }}
                        />
                      );
                    case "Product":
                      return (
                        <PackageIcon
                          className="w-4"
                          style={{ color: tag.color }}
                        />
                      );
                    default:
                      return (
                        <TagIcon className="w-4" style={{ color: tag.color }} />
                      );
                  }
                })()}
                <span className="grow">{tag.name}</span>
              </span>
            </DropdownMenuCheckboxItem>
          );
        })}
      </div>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className={cn(
          "m-1 py-2.5 px-2",
          "text-brand-blue-1",
          "hover:bg-brand-gray-2"
        )}
        onClick={() => onTagClick(null)}
      >
        <span className="w-full text-center">{i18n.t("Remove selection")}</span>
      </DropdownMenuItem>
    </>
  );
}
