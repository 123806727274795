import { useLingui } from "@lingui/react";
import * as BaseSlider from "@radix-ui/react-slider";
import { useIsFetching } from "@tanstack/react-query";
import { Zoom } from "@visx/zoom";
import { TransformMatrix } from "@visx/zoom/lib/types";
import { memo, useEffect, useMemo, useRef, useState } from "react";

import type { Dimensions } from "@/domain/areas-of-interests";
import { Card, CardHeader, CardTitle } from "@/view/components";
import { useSelectedDateRange } from "@/view/pages/line-id/use-selected-date-range";
import { useSelectedLine } from "@/view/pages/line-id/use-selected-line";
import { useSelectedShiftIds } from "@/view/pages/line-id/use-selected-shift-ids";
import { useDebouncedValue } from "@/view/providers/use-debounced-value";
import { useElementSize } from "@/view/providers/use-element-size";
import { cn } from "@/view/utils";

import { AreasLayout } from "./areas-layout";
import { useBirdEyeViewFilterActions } from "./bev-filters-provider";
import { WalkingRoutesHeatmap } from "./heatmap";
import { getHeatmapQueryKey } from "./use-heatmap-data-query";
import { BevType, useSelectedBevType } from "./use-selected-bev-type";
import { useVirtualSensorImageQuery } from "./use-virtual-sensor-image-query";
import {
  VirtualSensorAreasLayoutErrorBoundary,
  VirtualSensorAreasLayoutProvider,
} from "./use-virtual-sensor-layout";

export function BirdEyeViewCanvas() {
  const { ref, width, height } = useElementSize();
  return (
    <Card
      ref={(el) => (ref.current = el)}
      className="relative border shadow-sm aspect-video"
    >
      <div className="absolute inset-0">
        {width > 0 && height > 0 && (
          <BirdEyeView
            canvas={{
              width: Math.round(width),
              height: Math.round(height) - 96, // subtract the height of the controls
            }}
          />
        )}
      </div>
    </Card>
  );
}

function BirdEyeView({ canvas }: { canvas: Dimensions }) {
  const viewType = useSelectedBevType();
  const [opacityPercentages, setOpacityPercentages] = useState(75);
  const opacity = useDebouncedValue(opacityPercentages / 100, 200);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const selectedLine = useSelectedLine();
  const { data } = useVirtualSensorImageQuery(selectedLine.id);
  const dateRange = useSelectedDateRange();
  const selectedShifts = useSelectedShiftIds();

  const filters = useMemo(
    () => ({
      dateRange,
      selectedShifts,
      cameraId: data.id,
    }),
    [dateRange, selectedShifts, data.id]
  );

  useEffect(() => {
    const abortController = new AbortController();

    containerRef.current?.addEventListener(
      "wheel",
      (e) => {
        e.preventDefault();
      },
      {
        passive: false,
        signal: abortController.signal,
      }
    );

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Zoom
      width={canvas.width}
      height={canvas.height}
      initialTransformMatrix={getInitialTransformMatrix(canvas, data)}
      scaleXMin={1 / 4}
      scaleXMax={2}
      scaleYMin={1 / 4}
      scaleYMax={2}
    >
      {(zoom) => (
        <>
          <CanvasControls
            opacity={opacityPercentages}
            viewType={viewType}
            onChangeOpacity={setOpacityPercentages}
            onResetMatrix={zoom.reset}
          />
          <div className="relative" ref={containerRef}>
            <LoadingLine />
            <svg
              // @ts-expect-error typescript complains about the ref type,
              // but in order to to prevent passive event errors on wheel event
              // we need to use containerRef from Zoom component
              ref={zoom.containerRef}
              width={canvas.width}
              height={canvas.height}
              style={{
                cursor: zoom.isDragging ? "grabbing" : "grab",
                display: "block",
                userSelect: "none",
                backgroundColor: "#F5F7FA",
                touchAction: "none",
              }}
            >
              <defs>
                <pattern
                  id="grid"
                  width={72}
                  height={72}
                  patternUnits="userSpaceOnUse"
                >
                  <circle cx={36} cy={36} r={2} fill="rgba(0,0,0,0.1)" />
                </pattern>
              </defs>
              <rect
                fill="url(#grid)"
                width={canvas.width}
                height={canvas.height}
              />
              <g transform={zoom.toString()}>
                <image
                  href={data.bgUrl}
                  width={data.dimensions.width}
                  height={data.dimensions.height}
                />

                {viewType === "walking_routes" && (
                  <AreasConnections opacity={opacity} />
                )}
                {viewType === "heatmap" && (
                  <WalkingRoutesHeatmap
                    width={data.dimensions.width}
                    height={data.dimensions.height}
                    filters={filters}
                    opacity={opacity}
                  />
                )}
              </g>
            </svg>
          </div>
        </>
      )}
    </Zoom>
  );
}

const AreasConnections = memo<{
  opacity: number;
}>(function AreasConnections({ opacity }) {
  return (
    <VirtualSensorAreasLayoutErrorBoundary>
      <VirtualSensorAreasLayoutProvider>
        <AreasLayout opacity={opacity} />
      </VirtualSensorAreasLayoutProvider>
    </VirtualSensorAreasLayoutErrorBoundary>
  );
});

function CanvasControls({
  opacity,
  viewType,
  onChangeOpacity,
  onResetMatrix,
}: {
  opacity: number;
  viewType: BevType;
  onChangeOpacity: (value: number) => void;
  onResetMatrix: () => void;
}) {
  const actions = useBirdEyeViewFilterActions();
  const { i18n } = useLingui();
  const toggleGroupItemClasses = [
    "flex py-3 px-4 items-center justify-center",
    "focus:z-10 focus:outline-none",
    "first:rounded-l last:rounded-r",
    "text-brand-gray-5 text-base leading-4",
    "bg-brand-white hover:bg-brand-gray-1",
  ];
  return (
    <CardHeader className="flex-row gap-6 justify-between items-center">
      <CardTitle>{i18n.t("Spaghetti diagram")}</CardTitle>
      <div className="grow">
        <div className="inline-flex border border-brand-gray-2 bg-brand-gray-2 rounded space-x-px">
          <button
            type="button"
            disabled={viewType === "walking_routes"}
            onClick={() => actions.selectBevType("walking_routes")}
            className={cn(toggleGroupItemClasses, {
              "bg-brand-gray-2 hover:bg-brand-gray-2":
                viewType === "walking_routes",
            })}
          >
            {i18n.t("Walking routes")}
          </button>
          <button
            type="button"
            disabled={viewType === "heatmap"}
            onClick={() => actions.selectBevType("heatmap")}
            className={cn(toggleGroupItemClasses, {
              "bg-brand-gray-2 hover:bg-brand-gray-2": viewType === "heatmap",
            })}
          >
            {i18n.t("Heatmap")}
          </button>
        </div>
      </div>
      <div className="inline-flex gap-2 items-center">
        <h4 className="font-semibold text-brand-gray-5">
          {i18n.t("Overlay Opacity")}
        </h4>
        <Slider value={opacity} onChange={onChangeOpacity} />
      </div>
      <div>
        <div className="inline-flex border border-brand-gray-2 bg-brand-gray-2 rounded space-x-px">
          <button
            type="button"
            className={toggleGroupItemClasses.join(" ")}
            onClick={onResetMatrix}
          >
            {i18n.t("Reset view")}
          </button>
        </div>
      </div>
    </CardHeader>
  );
}

function Slider({
  value,
  onChange,
}: {
  value: number;
  onChange: (value: number) => void;
}) {
  return (
    <form>
      <BaseSlider.Root
        className="relative flex items-center select-none touch-none w-[200px] h-5"
        defaultValue={[75]}
        max={90}
        min={30}
        step={1}
        value={[value]}
        onValueChange={(value) => onChange(value[0])}
      >
        <BaseSlider.Track className="bg-brand-gray-3 relative grow rounded-full h-[8px]">
          <BaseSlider.Range className="absolute bg-brand-blue-1 rounded-full h-full" />
        </BaseSlider.Track>
        <BaseSlider.Thumb
          className="block w-5 h-5 bg-white border-brand-blue-1 border-2 rounded-[10px] hover:bg-brand-gray-2 focus:outline-none"
          aria-label="Volume"
        />
      </BaseSlider.Root>
    </form>
  );
}

function getInitialTransformMatrix(
  canvas: Dimensions,
  sensor: { dimensions: Dimensions }
): TransformMatrix {
  const canvasRatio = canvas.width / canvas.height;
  const sensorRatio = sensor.dimensions.width / sensor.dimensions.height;
  const isHorizontal = sensorRatio > canvasRatio;
  const heightBased = {
    width: Math.round(canvas.height * sensorRatio),
    height: canvas.height,
    scale: canvas.height / sensor.dimensions.height,
  };
  const widthBased = {
    width: canvas.width,
    height: Math.round(canvas.width / sensorRatio),
    scale: canvas.width / sensor.dimensions.width,
  };
  const { scale, width, height } = isHorizontal ? widthBased : heightBased;
  return {
    scaleX: scale,
    scaleY: scale,
    translateX: (canvas.width - width) / 2,
    translateY: (canvas.height - height) / 2,
    skewX: 0,
    skewY: 0,
  };
}

/**
 * Memoized component to avoid unnecessary re-renders when a user drags the picture or changes opacity
 */
const LoadingLine = memo(function LoadingLine() {
  const selectedLine = useSelectedLine();
  const { data } = useVirtualSensorImageQuery(selectedLine.id);
  const dateRange = useSelectedDateRange();
  const selectedShifts = useSelectedShiftIds();

  const filters = useMemo(
    () => ({
      dateRange,
      selectedShifts,
      cameraId: data.id,
    }),
    [dateRange, selectedShifts, data.id]
  );

  const isFetchingHeatmap =
    useIsFetching({
      queryKey: getHeatmapQueryKey(filters),
    }) > 0;

  if (!isFetchingHeatmap) return null;

  return (
    <div className="absolute top-0 left-0 right-0 h-1 bg-transparent overflow-hidden">
      <div className="h-full bg-brand-blue-1 animate-horizontal-loading will-change-transform" />
    </div>
  );
});
