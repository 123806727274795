import { createContext, useContext } from "react";

import { getOrderedStationIds, LineWithStations } from "@/domain/levels";
import { StationId } from "@/domain/station";

export const SelectedStationIdsContext = createContext<Array<StationId>>([]);

export function useSelectedStationIds() {
  const ctx = useContext(SelectedStationIdsContext);
  if (!ctx) {
    throw new Error(
      "useSelectedStationIds must be used inside SelectedStationIdsProvider"
    );
  }
  return ctx;
}

const searchParamKey = "stationIds";

export function parseStationIdsFromSearchParams(
  searchParams: URLSearchParams,
  line: LineWithStations
) {
  const maybeStationIds = searchParams.get(searchParamKey);
  const stationIds = decodeStationIds(maybeStationIds);
  return getOrderedStationIds(line, stationIds);
}

export function encodeStationIdsToSearchParams(
  searchParams: URLSearchParams,
  stationIds: Array<StationId>
) {
  searchParams.set(searchParamKey, encodeStationIds(stationIds));
  return searchParams;
}

function encodeStationIds(arr: Array<StationId>) {
  return [...new Set(arr)].join(".");
}

function decodeStationIds(
  arr: string | null | undefined
): Array<string> | undefined {
  return arr?.split(".").filter(Boolean);
}
