import { useLingui } from "@lingui/react";
import { TagIcon } from "lucide-react";
import { useMemo } from "react";

import { StationId } from "@/domain/station";
import {
  DropdownMenuCheckboxItem,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/view/components";
import { cn } from "@/view/utils";

import { useSelectedLine } from "./use-selected-line";
import { useSelectedStationIds } from "./use-selected-station-ids";

export function StationsFilters({
  onChange,
}: {
  onChange: (selectedIds: Array<StationId>) => void;
}) {
  const { i18n } = useLingui();
  const line = useSelectedLine();
  const selectedIds = useSelectedStationIds();
  const allIds = useMemo(
    () => line.stations.map((station) => station.id),
    [line]
  );
  return (
    <>
      <DropdownMenuLabel className="py-3 px-4 flex gap-2 items-center">
        <TagIcon className="w-4 h-4 text-brand-blue-1" />
        <h3 className="text-sm">{i18n.t("Stations")}</h3>
      </DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className={cn(
          "m-1 py-2.5 px-2",
          "text-brand-black font-semibold",
          "hover:bg-brand-gray-2"
        )}
        onClick={() => onChange(allIds)}
      >
        {i18n.t("stationsSelectorSelectAll")}
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <div className="max-h-[240px] overflow-y-auto">
        {line.stations.map((station) => {
          return (
            <DropdownMenuCheckboxItem
              key={station.id}
              checked={selectedIds.includes(station.id)}
              className="mx-1"
              onClick={() => {
                onChange(
                  selectedIds.includes(station.id)
                    ? selectedIds.filter((id) => id !== station.id)
                    : [...selectedIds, station.id]
                );
              }}
            >
              {station.name}
            </DropdownMenuCheckboxItem>
          );
        })}
      </div>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className={cn(
          "m-1 py-2.5 px-2",
          "text-brand-blue-1",
          "hover:bg-brand-gray-2"
        )}
        onClick={() => onChange([])}
      >
        <span className="w-full text-center">
          {i18n.t("stationsSelectorRemoveAll")}
        </span>
      </DropdownMenuItem>
    </>
  );
}
