import { useSuspenseQueries } from "@tanstack/react-query";
import { createContext, PropsWithChildren, useContext } from "react";

import { PaginatedResponse } from "@/domain/api-client";
import { Video } from "@/domain/video";
import { useSelectedTagIds } from "@/view/pages/line-id/use-selected-tag-ids";

import { useSelectedDateRange } from "../line-id/use-selected-date-range";
import { useSelectedShiftIds } from "../line-id/use-selected-shift-ids";
import { useSelectedStationIds } from "../line-id/use-selected-station-ids";
import { usePagination } from "./use-pagination";
import { useVideosQuery } from "./use-videos";

const VideosContext = createContext<PaginatedResponse<Video> | null>(null);

// eslint-disable-next-line react-refresh/only-export-components
export function useVideos() {
  const videos = useContext(VideosContext);
  if (!videos) {
    throw new Error("useVideos must be used within a VideosAndTagsProvider");
  }
  return videos;
}

// eslint-disable-next-line react-refresh/only-export-components
export function useVideosAndTagsParams() {
  const stationIds = useSelectedStationIds();
  const tagIds = useSelectedTagIds();
  const shiftIds = useSelectedShiftIds();
  const dateRange = useSelectedDateRange();
  const pagination = usePagination();

  return [stationIds, tagIds, shiftIds, dateRange, pagination] as const;
}

export function VideosAndTagsProvider({ children }: PropsWithChildren) {
  const [stationIds, tagIds, shiftIds, dateRange, pagination] =
    useVideosAndTagsParams();
  const videosQueryOptions = useVideosQuery({
    stationIds,
    shiftIds,
    dateRange,
    pagination,
    tagIds,
    onlyBookmarks: true,
  });

  const [videosQuery] = useSuspenseQueries({
    queries: [videosQueryOptions],
  });

  return (
    <VideosContext.Provider value={videosQuery.data}>
      {children}
    </VideosContext.Provider>
  );
}
