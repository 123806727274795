import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";

import { cn } from "@/view/utils";

export const Dialog = DialogPrimitive.Root;

export const DialogTrigger = DialogPrimitive.Trigger;

export const DialogPortal = ({
  className,
  children,
  ...props
}: DialogPrimitive.DialogPortalProps & {
  className?: string;
}) => (
  <DialogPrimitive.Portal {...props}>
    <div
      className={cn(
        "fixed inset-0 z-50",
        "flex items-start justify-center sm:items-center",
        className
      )}
    >
      {children}
    </div>
  </DialogPrimitive.Portal>
);
DialogPortal.displayName = DialogPrimitive.Portal.displayName;

export const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50",
      "bg-brand-neutral opacity-70 backdrop-blur-sm",
      "transition-all duration-100",
      "data-[state=closed]:animate-out data-[state=closed]:fade-out data-[state=open]:fade-in",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Content
    ref={ref}
    onClick={(e) => e.stopPropagation()}
    className={cn(
      "mx-auto max-w-3xl h-[95vh] max-h-[920px] min-h-[560px] w-full",
      "flex flex-col z-30 relative",
      "bg-brand-white shadow-lg rounded-md transition ease-in-out",
      "data-[state=open]:animate-in data-[state=open]:duration-500 data-[state=open]:fade-in",
      "data-[state=closed]:animate-out data-[state=closed]:duration-500 data-[state=closed]:fade-out",
      className
    )}
    {...props}
  />
));
DialogContent.displayName = DialogPrimitive.Content.displayName;
