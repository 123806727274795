import { useLingui } from "@lingui/react";
import { Clock2Icon } from "lucide-react";
import { useState } from "react";

import { ShiftId } from "@/domain/shifts";
import {
  Button,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/view/components";
import { cn } from "@/view/utils";

import { useSelectedLine } from "./use-selected-line";
import { useSelectedShiftIds } from "./use-selected-shift-ids";

export function ShiftSelect({
  onChange,
}: {
  onChange: (value: Array<ShiftId>) => void;
}) {
  const { i18n } = useLingui();
  const line = useSelectedLine();
  const shiftIds = useSelectedShiftIds();
  const [open, setOpen] = useState(false);

  if (line.shifts.length === 0) return null;

  return (
    <DropdownMenu
      open={open}
      onOpenChange={(newOpen) => {
        if (newOpen) setOpen(newOpen);
      }}
    >
      <DropdownMenuTrigger asChild>
        <Button
          analyticsEvent="analyze_info_icon_clicked"
          className={cn(
            "border-2 rounded-lg border-brand-gray-2",
            "bg-brand-white hover:bg-brand-gray-2",
            "font-semibold text-brand-gray-5"
          )}
        >
          <span className="select-none flex gap-2 justify-between items-center">
            <Clock2Icon />
            {i18n.t("{count} selected shifts", { count: shiftIds.length })}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="min-w-[240px] rounded-lg"
        onInteractOutside={() => setOpen(false)}
      >
        {line.shifts.map((shift) => {
          return (
            <DropdownMenuCheckboxItem
              key={shift.id}
              checked={shiftIds.includes(shift.id)}
              onCheckedChange={(checked) => {
                const newShiftIds = checked
                  ? [...shiftIds, shift.id]
                  : shiftIds.filter((id) => id !== shift.id);
                onChange(newShiftIds);
              }}
            >
              <span className="flex gap-2">{shift.name}</span>
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
