import { lazy, Suspense } from "react";

import { Card } from "@/view/components";
import { LoadingStatus } from "@/view/components/loading-status";
import { paths } from "@/view/routes";

import { LineMainLayout } from "../line-id/line-main-layout";
import { LineSelect } from "../line-id/line-select";
import {
  VideoLibraryFilters,
  VideoLibraryFiltersProvider,
} from "./video-library-filters-provider";
import { VideosAndTagsErrorBoundary } from "./videos-and-tags-error-boundary";

const VideosAndTagsProvider = lazy(() =>
  import("./videos-and-tags-provider").then((module) => ({
    default: module.VideosAndTagsProvider,
  }))
);
const VideoComparisonProvider = lazy(() =>
  import("./video-comparison-provider").then((module) => ({
    default: module.VideoComparisonProvider,
  }))
);
const SelectedVideoProvider = lazy(() =>
  import("./selected-video-provider").then((module) => ({
    default: module.SelectedVideoProvider,
  }))
);
const VideoLibrary = lazy(() =>
  import("./video-library").then((module) => ({
    default: module.VideoLibrary,
  }))
);

export function LineVideoLibraryPage() {
  return (
    <VideoLibraryFiltersProvider>
      <LineMainLayout
        filters={
          <>
            <div className="grow">
              <LineSelect path={paths.lineVideoLibraryPath} />
            </div>
            <VideoLibraryFilters />
          </>
        }
      >
        <Card className="relative border shadow-sm px-6 py-12 min-h-[640px]">
          <Suspense fallback={<LoadingStatus />}>
            <VideosAndTagsErrorBoundary>
              <VideosAndTagsProvider>
                <VideoComparisonProvider>
                  <SelectedVideoProvider>
                    <VideoLibrary />
                  </SelectedVideoProvider>
                </VideoComparisonProvider>
              </VideosAndTagsProvider>
            </VideosAndTagsErrorBoundary>
          </Suspense>
        </Card>
      </LineMainLayout>
    </VideoLibraryFiltersProvider>
  );
}
