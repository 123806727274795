export abstract class BaseError extends Error {}

export class UnauthorizedError extends BaseError {}

export class BadRequestError extends BaseError {}

export class ApiError extends BaseError {}

export class ResponseParsingError extends BaseError {}

export class NetworkError extends BaseError {}

export class NotFoundError extends BaseError {}
