import { cn } from "@/view/utils";

import { SpinnerIcon } from "./icons";

export function LoadingStatus({ className }: { className?: string }) {
  return (
    <div
      role="status"
      className={cn(
        "absolute inset-0 z-10",
        "flex items-center justify-center",
        className
      )}
    >
      <SpinnerIcon className="h-10 w-10" />
    </div>
  );
}
